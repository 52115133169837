import {
  SAVE_PROFILE_DATA,
  SAVE_IP_ADDRESS,
  UPDATE_PROGRESS_POSTS,
  UPDATE_PROGRESS_VIEWS,
  UPDATE_CHAT_ENABLED,
} from "./actionTypes";

export const saveProfileData = (data) => ({
  type: SAVE_PROFILE_DATA,
  payload: data,
});

export const saveIpAddress = (data) => ({
  type: SAVE_IP_ADDRESS,
  payload: data,
});

export const updateProgressPosts = (data) => ({
  type: UPDATE_PROGRESS_POSTS,
  payload: data,
});

export const updateProgressViews = (data) => ({
  type: UPDATE_PROGRESS_VIEWS,
  payload: data,
});

export const updateChatEnabled = (data) => ({
  type: UPDATE_CHAT_ENABLED,
  payload: data,
});
