import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/SplitText";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import useFontFaceObserver from "use-font-face-observer";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimary from "components/buttons/ButtonPrimary.js";
import RollingBox from "./other/RollingBox";
import MockupDisplay from "./other/landingPage/MockupDisplay";
import Testimonial from "./other/landingPage/Testimonial";
import HidingMenuPublic from "./menu/HidingMenuPublic";

import heroBackground from "assets/img/landingPage/hero-bg.svg";
import desktopDollarIcon from "assets/img/icons/desktop-dollar-icon.svg";
import barChartIncreaseIcon from "assets/img/icons/bar-chart-increase.svg";
import webGlobeIcon from "assets/img/icons/web-globe-icon.svg";
import featureBoxOneBg from "assets/img/landingPage/unlimited-potential-box-bg.jpg";
import featureBoxTwoBg from "assets/img/landingPage/seamless-growth-box-bg.jpg";
import featureBoxThreeBg from "assets/img/landingPage/global-audience-box-bg.jpg";
import plusIcon from "assets/img/icons/plus-sign.svg";
import plusWhiteIcon from "assets/img/icons/plus-sign-white.svg";
import minusIcon from "assets/img/icons/icons8-minus.svg";
import circlePointGreenIcon from "assets/img/icons/icons8-circle-point-green.svg";
import phoneMockup1 from "assets/img/landingPage/phone-mockup-1.png";
import raquelProfileImage from "assets/img/landingPage/testimonials/raquel.jpg";
import andyProfileImage from "assets/img/landingPage/testimonials/andy.jpg";
import olwamProfileImage from "assets/img/landingPage/testimonials/olwam.jpg";
import olwamPostImage from "assets/img/landingPage/testimonials/olwam-post.jpg";
import chrisPostImage from "assets/img/landingPage/testimonials/chris-post.jpg";
import chrisProfileImage from "assets/img/landingPage/testimonials/chris.jpg";
import joanieProfileImage from "assets/img/landingPage/testimonials/joanie.jpg";
import joaniePostImage from "assets/img/landingPage/testimonials/joanie-post.jpg";
import nancyProfileImage from "assets/img/landingPage/testimonials/nancy.jpg";
import nancyPostImage from "assets/img/landingPage/testimonials/nancy-post.jpg";
import scalelupLogo from "assets/img/logo/scalelup.svg";
import videoCover from "assets/img/cover-1.jpg";

// Actions
import { openModal } from "store/actions";

const LandingPage = styled.div`
  position: relative;
  width: 100%;

  font-family: "Montserrat", sans-serif;

  background-image: url(${heroBackground});
  background-repeat: no-repeat;
  background-color: #0a151f;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;

  background-size: 100% 100%;
  background-position: 0% 0%, 0% 0%;
  background-image: radial-gradient(
      45% 56% at 35% 21%,
      #5d56dd33 0%,
      #ff000000 99%
    ),
    radial-gradient(25% 39% at 19% 53%, #6c66d91a 0%, #ff000000 99%);
`;

const NarrowContent = styled.div`
  position: relative;
  width: 100%;

  max-width: 1300px;
  margin: 0 auto;
  padding: 0 40px;

  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 0 20px;
  }
`;

const Hero = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    align-items: flex-start;
  }
`;

const ShortHeading = styled.h4`
  margin-top: ${(props) => (props.margintop ? props.margintop : 0)};
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : 0)};
  padding: 0 60px;
  height: 32px;

  font-family: "Barlow";

  background: ${css.BRAND_INDIGO};
  border-radius: 16px;

  font-weight: 500;
  line-height: 1;

  display: flex;
  align-items: center;
`;

const MainHeading = styled.h1`
  margin-top: 15px;
  max-width: 960px;

  visibility: hidden;

  font-size: 60px;
  font-weight: 600;
  line-height: 110%;
  text-align: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 40px;
    text-align: left;
  }
`;

const Colorful = styled.span`
  background: linear-gradient(
    270deg,
    #4891ff 13.89%,
    #8783d1 36.39%,
    #5dd39e 59.2%,
    #ffe70d 88.89%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeroParagraph = styled.div`
  margin-top: 0px;
  max-width: 800px;

  font-size: 20px;
  line-height: 135%;
  color: ${css.TEXT_GREY};
  text-align: center;

  visibility: hidden;

  @media (max-width: ${globalConstants.switchMobile}) {
    text-align: left;
  }
`;

const HeroButtonWrap = styled.div`
  margin-top: 40px;

  font-family: "Barlow";
`;

const FeatureBoxes = styled.div`
  width: 100%;
  margin-top: 140px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px 40px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 110px;
  }
`;

const FeatureBox = styled.div`
  position: relative;
  width: 380px;
  max-width: 380px;
  max-height: 275px;
  padding: 35px;
  box-sizing: border-box;

  overflow: hidden;
  visibility: hidden;

  border-radius: ${css.BOX_RADIUS_PRIMARY};

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
    padding: 25px;
  }
`;

const FeatureBoxBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-image: ${(props) => `url(${props.bgimage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);

  z-index: 0;

  pointer-events: none;
`;

const FeatureBoxIconWrap = styled.div`
  //margin-bottom: ${css.VERTICAL_BOX_MARGIN_LARGE};
  margin-bottom: 20px;
  position: relative;
  width: 70px;
  height: 70px;

  pointer-events: none;
`;

const FeatureBoxIconCircle = styled.div`
  width: 100%;
  aspect-ratio: 1;
  box-sizing: border-box;

  border: 2px solid #fff;
  border-radius: 50%;
`;

const FeatureBoxIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  min-width: 28px;
  height: 28px;

  transform: translateX(-50%) translateY(-50%);
`;

const FeatureBoxContentWrap = styled.div`
  position: relative;
  height: 145px;

  overflow: hidden;

  z-index: 1;
  pointer-events: none;
`;

const FeatureBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  //gap: ${css.VERTICAL_BOX_MARGIN};
`;

const FeatureBoxTitle = styled.div`
  font-size: 30px;
  font-weight: 500;

  white-space: nowrap;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 28px;
  }
`;

const FeatureBoxText = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
`;

const FeatureBoxTextLong = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_XLARGE};

  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
`;

const Essentials = styled.div`
  margin-top: 120px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 110px;
  }
`;

const EssentialsColumns = styled.div`
  display: flex;
  gap: 80px;
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const QuestionButtonWrap = styled.div`
  margin-top: 4px;
`;

const QuestionButtonIcon = styled.div`
  width: 12px;
  height: 12px;

  transition: 0.2s;

  /* background-image: url(${(props) => props.icon}); */
  background-image: ${(props) => `url(${props.icon})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const QuestionButtonCircle = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  box-sizing: border-box;

  border-radius: 50%;
  background: ${(props) => (props.disabled ? css.TEXT_GREY : props.background)};
  border: 0px;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    border: 0px;
  }
`;

const Question = styled.div`
  font-size: 30px;
  font-weight: 500;

  transition: 0.2s;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 26px;
  }
`;

const QuestionLine = styled.div`
  display: flex;
  gap: 16px;
  align-self: flex-start;

  &:hover {
    cursor: pointer;
  }

  &:hover ${Question} {
    color: ${(props) => props.hovertext};
  }

  &:hover ${QuestionButtonCircle} {
    background: ${(props) =>
      props.disabled ? css.TEXT_GREY : props.hoverbackground};
  }

  &:hover ${QuestionButtonIcon} {
    background-image: ${(props) =>
      `url(${props.disabled ? props.icon : props.hovericon})`};
  }
`;

const HidingBox = styled.div`
  height: ${(props) => props.height};

  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const Answer = styled.div`
  padding-left: 44px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const AnswerText = styled.div`
  line-height: 130%;
`;

const AnswerList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const AnswerListItem = styled.div`
  display: flex;
  gap: 6px;
`;

const AnswerListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const AnswerListIcon = styled.div`
  padding-top: 1px;

  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const AnswerListTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

const AnswerListText = styled.div`
  line-height: 130%;
`;

const AnswerSpacer = styled.div`
  height: 20px;
`;

const MockupColumn = styled.div`
  min-width: 400px;
`;

const RainbowBox = styled.div`
  position: relative;
  width: 100%;
  height: 500px;

  border-radius: ${css.BOX_RADIUS_PRIMARY};
  overflow: hidden;
`;

const RainbowBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;

  background: linear-gradient(
    132deg,
    #8783d1 0.49%,
    #ffe70d 22.08%,
    #5dd39e 43.66%,
    #8783d1 65.24%,
    #5dd39e 86.82%,
    #ffe70d 108.4%
  );
`;

const PhoneBox = styled.div`
  position: absolute;
  top: 55px;
  left: 50%;
  width: 260px;

  transform: translateX(-50%);
`;

const TabletRainbowBoxWrap = styled.div`
  width: 100%;
  margin: -20px 0 ${css.VERTICAL_BOX_MARGIN_XLARGE} 0;

  display: flex;
  justify-content: center;
  /* margin-top: -10px;
  margin-bottom: ${css.VERTICAL_BOX_MARGIN_XLARGE}; */
`;

const Testimonials = styled.div`
  margin-top: 140px;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 90px;
    align-items: flex-start;
  }
`;

const SubtitleParagraph = styled.div`
  margin-top: 0px;
  margin-bottom: ${(props) => (props.marginbottom ? props.marginbottom : "0")};
  max-width: 800px;

  font-size: 20px;
  line-height: 135%;
  color: ${css.TEXT_GREY};
  text-align: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    text-align: left;
  }
`;

const TestimonalsRow = styled.div`
  width: 100%;
  margin-top: 50px;

  overflow: hidden;
`;

const TestimonialsMarquue = styled.div`
  display: flex;
  gap: 30px;
`;

const TestimonialsGroup = styled.div`
  display: flex;
  gap: 30px;
`;

const Finale = styled.div`
  margin-top: 140px;
  margin-bottom: 40px;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 110px;
    margin-bottom: 80px;

    align-items: flex-start;
  }
`;

const FinaleTitle = styled.div`
  font-size: 45px;
  font-weight: 500;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 35px;
  }
`;

const FinaleBox = styled.div`
  width: 100%;
  max-width: 640px;
  padding: ${css.BOX_PADDING_LARGE};
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 30px;

    flex-direction: column;
    align-items: flex-start;
  }
`;

const FinaleBoxLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const FinaleLogoWrap = styled.div`
  width: 170px;
`;

const FinaleSlogan = styled.div`
  max-width: 280px;

  font-size: 24px;
  font-weight: 400;
  line-height: 130%;
`;

const FinaleBoxRight = styled.div`
  max-width: 200px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const FinaleRightText = styled.div`
  font-size: 18px;
  line-height: 130%;
`;

const FinaleButtonWrap = styled.div`
  font-family: "Barlow";
`;

const VideoSection = styled.div`
  width: 100%;
  margin-top: 140px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VideoBox = styled.div`
  width: 100%;
  max-width: 640px;
  padding: 25px;
  box-sizing: border-box;
  aspect-ratio: 1.65;

  background: ${css.BRAND_BLUSH};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 15px;

    flex-direction: column;
    align-items: flex-start;
  }
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Scalelup | Drive Website Traffic, Build Awareness";
  }, []);

  const navigate = useNavigate();

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  const openSignup = () => {
    if (!profileData) dispatch(openModal("signup"));
    else navigate("/home");
  };

  const container = useRef();
  const dispatch = useDispatch();

  gsap.registerPlugin(useGSAP, SplitText);
  const { contextSafe } = useGSAP({ scope: container });

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  const [answerOneOpened, setAnswerOneOpened] = useState(true);
  const [answerTwoOpened, setAnswerTwoOpened] = useState(false);
  const [answerThreeOpened, setAnswerThreeOpened] = useState(false);
  const [mockup, setMockup] = useState("one");

  const duration = 0.5;

  const playHoverFeatureBox = contextSafe((e) => {
    const boxId = e.target.id;
    const box = document.getElementById(boxId);

    if (!box) return;

    const bg = box.getElementsByClassName("feature-bg");
    const contentWrap = box.getElementsByClassName("feature-content-wrap");
    const content = box.getElementsByClassName("feature-content");
    const iconWrap = box.getElementsByClassName("feature-icon-wrap");

    const boxTl = gsap.timeline({ paused: true });
    boxTl.to(bg[0], { scale: 1.1, duration });
    boxTl.to(contentWrap[0], { y: -20, duration }, 0);
    boxTl.to(content[0], { y: -147, duration }, 0);
    boxTl.to(iconWrap[0], { scaleX: 0.8, scaleY: 0.8, y: -14, duration }, 0);

    boxTl.play();
  });

  const revertHoverFeatureBox = contextSafe((e) => {
    const boxId = e.target.id;
    const box = document.getElementById(boxId);

    if (!box) return;

    const bg = box.getElementsByClassName("feature-bg");
    const contentWrap = box.getElementsByClassName("feature-content-wrap");
    const content = box.getElementsByClassName("feature-content");
    const iconWrap = box.getElementsByClassName("feature-icon-wrap");

    const boxTl = gsap.timeline({ paused: true });
    boxTl.to(bg[0], { scale: 1, duration });
    boxTl.to(contentWrap[0], { y: 0, duration }, 0);
    boxTl.to(content[0], { y: 0, duration }, 0);
    boxTl.to(iconWrap[0], { scaleX: 1, scaleY: 1, y: 0, duration }, 0);

    boxTl.play();
  });

  // handle Question and Mockup change
  const onQuestionClick = (question) => {
    if (!tabletScreen) {
      setAnswerOneOpened(false);
      setAnswerTwoOpened(false);
      setAnswerThreeOpened(false);
    }

    if (question === "one") setAnswerOneOpened((prevState) => !prevState);
    if (question === "two") setAnswerTwoOpened((prevState) => !prevState);
    if (question === "three") setAnswerThreeOpened((prevState) => !prevState);

    const updateMockup = contextSafe(() => {
      const phoneBox = document.getElementById("desktopPhoneBox");
      const rainbowBg = document.getElementById("desktopRainbowBg");

      if (!phoneBox || !rainbowBg) return;

      setTimeout(() => setMockup(question), 200);
    });

    updateMockup();
  };

  const testimonials = (
    <TestimonialsGroup>
      <Testimonial
        name="Raquel"
        date="Dec 18, 2024"
        text="Discovering Scalelup has been a game-changer for my beauty business! As
        a home-based business owner, I struggled to expand my reach and find new
        reps. With Scalelup's free platform, I've seen a significant boost in
        sales and a growing team of passionate sellers."
        avatar={raquelProfileImage}
        username="Raquel Smith"
        postPublished="13w"
        postText={`💄 Unleash Your Inner Beauty Boss with Jeunesse! 💋

        Dreaming of a flexible career in the beauty industry? Join Jeunesse and transform your passion into a thriving business! 🌟
      
      ✨ Exclusive product line ✨ Comprehensive training ✨ Supportive community`}
      />
      <Testimonial
        white={true}
        name="Chris"
        date="Nov 3, 2024"
        text="Scalelup has been a phenomenal partner in growing our VPN affiliate program! After creating a post on their platform, we saw a significant influx of converting traffic to our website. Highly recommend it to anyone looking to boost their online presence!"
        avatar={chrisProfileImage}
        username="Chris Benson"
        postPublished="10w"
        postText={`Promote a top-rated VPN service trusted by millions for online privacy and security. Earn big 🤑🤑🤑`}
        postImage={chrisPostImage}
      />
      <Testimonial
        name="Olwam"
        date="Sep 5, 2024"
        text="Using Scalelup has taken my precious metals business to the next level! After posting about my opportunity on their platform, I received an overwhelming number of responses from interested prospects. This surge in engagement boosted my sales quickly. "
        avatar={olwamProfileImage}
        username="Olwam Shabangu"
        postPublished="16w"
        postText={`💲Join our precious metals club and transform your financial future! 👇`}
        postImage={olwamPostImage}
      />
      <Testimonial
        white={true}
        name="Andy"
        date="Nov 21, 2024"
        text="Partnering with Scalelup has transformed the way I operate my business! As someone eager to expand my network, this website has been invaluable. It has greatly increased my visibility, the difference in my business growth since joining is remarkable."
        avatar={andyProfileImage}
        username="Andy Raught"
        postPublished="11w"
        postText={`Join a leading financial services company with over 40 years of success, as a licensed Insurance Agent. Whether you're looking to earn extra income or build a full-time career, this entrepreneurial opportunity is perfect for you.

        ✅ Work remotely across the U.S.
        ✅ No quotas or income caps
        ✅ State & Federal licenses paid by us`}
      />
      <Testimonial
        name="Joanie"
        date="Dec 17, 2024"
        text="After using Scalelup to promote my offering, I saw a significant increase in enrollments and boosted my profits. The exposure provided was invaluable, connecting me to eager learners ready to invest in their future. The time spent on Scalelup has definitely been worthwhile."
        avatar={joanieProfileImage}
        username="Joanie Webb"
        postPublished="16w"
        postText={`💡 Unlock Money-Making Skills with Our Online Business & Marketing Course! Discover Powerful Strategies to Boost Revenue 💰`}
        postImage={joaniePostImage}
      />
      <Testimonial
        white={true}
        name="Nancy"
        date="Dec 5, 2024"
        text="I was initially skeptical about using Scalelup to promote my travel business, but it turned out to be one of the best decisions I've made! 🌟 After creating my first post, I was amazed by the influx of incoming messages. Scalelup gave me the exposure I needed to grow."
        avatar={nancyProfileImage}
        username="Nancy Winkler"
        postPublished="25d"
        postText={`Want to Turn Your Love for Travel into Steady Income? 🌍✈️🌍✈️🌍✈️
          
          Explore the world while earning money💵💵💵
           `}
        postImage={nancyPostImage}
      />
    </TestimonialsGroup>
  );

  const runTestimonials = contextSafe(() => {
    const testimonialsMarquee = document.getElementsByClassName(
      "testimonials-marquee"
    );

    const group = testimonialsMarquee[0].firstElementChild;

    const groupWidth =
      parseInt(getComputedStyle(group).getPropertyValue("width"), 10) + 30;

    let tl = gsap.timeline({ paused: true, repeat: -1 });

    tl.fromTo(
      testimonialsMarquee[0],
      { x: 0 },
      {
        x: -groupWidth,
        duration: 20,
        ease: "none",
      }
    );

    tl.play();

    if (!mobileScreen) {
      testimonialsMarquee[0].addEventListener("mouseenter", () => {
        gsap.to(tl, { timeScale: 0 });
      });
    } else {
      testimonialsMarquee[0].addEventListener("click", () => {
        gsap.to(tl, { timeScale: 0 });
      });
    }

    testimonialsMarquee[0].addEventListener("mouseleave", () => {
      gsap.to(tl, { timeScale: 1 });
    });

    // show whole box on hover
    const testimonials = document.querySelectorAll(".testimonial");

    const testimonialsElements = Array.from(testimonials);

    testimonials.forEach((testimonial) => {
      testimonial.addEventListener("mouseenter", (e) => {
        const pos = testimonial.getBoundingClientRect();

        if (pos.left < 0) {
          //scroll back to view the whole image
          const currentProgress = tl.progress();

          const toScrollBack = (1 / groupWidth) * pos.left * -1;

          const scrollToProgress = currentProgress - toScrollBack;

          gsap.to(tl, { progress: scrollToProgress });
        }

        const positionRight = window.innerWidth - pos.right;

        if (positionRight < 0) {
          //scroll forward to view the whole image
          const currentProgress = tl.progress();

          const toScrollForward = (1 / groupWidth) * positionRight * -1;

          const scrollToProgress = currentProgress + toScrollForward;

          gsap.to(tl, { progress: scrollToProgress });
        }

        const otherTestimonials = testimonialsElements.filter(
          (t) => t !== e.target
        );

        gsap.to(otherTestimonials, { opacity: 0.5 });
      });

      testimonial.addEventListener("mouseleave", () => {
        gsap.to(testimonialsElements, { opacity: 1 });
      });
    });
  });

  useEffect(() => {
    runTestimonials();
  }, []);

  // entry animation
  const isFontLoaded = useFontFaceObserver([
    {
      family: `Montserrat`,
    },
  ]);

  useGSAP(
    () => {
      if (isFontLoaded) {
        const heading = document.getElementById("hero-heading");
        const shortHeading = document.getElementById("hero-short-heading");
        const heroText = document.getElementById("hero-text");
        const heroButton = document.getElementById("hero-button-wrap");
        const featureBoxes = document.getElementsByClassName("featureBox");

        new SplitText(heading, { type: "lines", linesClass: "heading-line" });
        new SplitText(heading, {
          type: "lines",
          linesClass: "line-wrapper",
        });

        gsap
          .timeline()
          .set(heading, { autoAlpha: 1 })
          .from(
            ".heading-line",
            { yPercent: 100, stagger: 0.1, duration: 0.4 },
            0
          )
          .to(shortHeading, { autoAlpha: 1, duration: 0.2 }, 0.5)
          .to(heroText, { autoAlpha: 1, duration: 0.2 }, 0.55)
          .to(heroButton, { autoAlpha: 1, duration: 0.2 }, 0.6)
          .to(featureBoxes, { autoAlpha: 1, stagger: 0.1 }, 0.65)
          .from(featureBoxes, { y: 50, stagger: 0.1 }, 0.65);
      }
    },
    { dependencies: [isFontLoaded], scope: container }
  );

  return (
    <LandingPage ref={container}>
      <GradientOverlay />
      <NarrowContent>
        <Hero>
          <ShortHeading
            id="hero-short-heading"
            margintop="20vh"
            marginbottom="20px"
            style={{ visibility: "hidden" }}
          >
            Boost Your Reach
          </ShortHeading>
          <MainHeading id="hero-heading">
            Gain <Colorful>Thousands</Colorful> of Eyes on Your Business in
            Minutes
          </MainHeading>
          <HeroParagraph id="hero-text">
            Unlock the full potential of your business with our powerful
            promotion platform. Scalelup offers business owners an effortless
            and effective way to showcase their products and services to a vast
            audience without any cost.
          </HeroParagraph>
          <HeroButtonWrap
            id="hero-button-wrap"
            style={{ visibility: "hidden" }}
          >
            <ButtonPrimary onClick={openSignup}>
              Get Started for Free
            </ButtonPrimary>
          </HeroButtonWrap>
        </Hero>
        <FeatureBoxes>
          <FeatureBox
            id="feature-box-one"
            className="featureBox"
            onMouseEnter={playHoverFeatureBox}
            onMouseLeave={revertHoverFeatureBox}
          >
            <FeatureBoxBg className="feature-bg" bgimage={featureBoxOneBg} />
            <FeatureBoxIconWrap className="feature-icon-wrap">
              <FeatureBoxIconCircle />
              <FeatureBoxIcon>
                <img src={desktopDollarIcon} style={{ width: "100%" }} />
              </FeatureBoxIcon>
            </FeatureBoxIconWrap>
            <FeatureBoxContentWrap className="feature-content-wrap">
              <FeatureBoxContent className="feature-content">
                <FeatureBoxTitle>Unlimited Potential</FeatureBoxTitle>
                <FeatureBoxText>
                  Scalelup offers endless opportunities to grow your business
                  without costly barriers.
                </FeatureBoxText>
                <FeatureBoxTextLong>
                  Promote your business in a way that attracts new customers and
                  clients. Whether you're introducing a new product, expanding
                  your services, or building a team, Scalelup offers the
                  resources and flexibility you need to achieve success.
                </FeatureBoxTextLong>
              </FeatureBoxContent>
            </FeatureBoxContentWrap>
          </FeatureBox>
          <FeatureBox
            id="featureBoxTwo"
            className="featureBox"
            onMouseEnter={playHoverFeatureBox}
            onMouseLeave={revertHoverFeatureBox}
          >
            <FeatureBoxBg className="feature-bg" bgimage={featureBoxTwoBg} />
            <FeatureBoxIconWrap className="feature-icon-wrap">
              <FeatureBoxIconCircle />
              <FeatureBoxIcon>
                <img src={barChartIncreaseIcon} style={{ width: "100%" }} />
              </FeatureBoxIcon>
            </FeatureBoxIconWrap>
            <FeatureBoxContentWrap className="feature-content-wrap">
              <FeatureBoxContent className="feature-content">
                <FeatureBoxTitle>Seamless Growth</FeatureBoxTitle>
                <FeatureBoxText>
                  Simplify your business journey and boost customer interaction
                  with our intuitive platform.
                </FeatureBoxText>
                <FeatureBoxTextLong>
                  Scalelup makes it effortless to showcase your offerings to
                  potential clients. Once you capture interest, engage directly
                  with prospects through our direct messaging feature, fostering
                  strong relationships and driving business success.
                </FeatureBoxTextLong>
              </FeatureBoxContent>
            </FeatureBoxContentWrap>
          </FeatureBox>
          <FeatureBox
            id="featureBoxThree"
            className="featureBox"
            onMouseEnter={playHoverFeatureBox}
            onMouseLeave={revertHoverFeatureBox}
          >
            <FeatureBoxBg className="feature-bg" bgimage={featureBoxThreeBg} />
            <FeatureBoxIconWrap className="feature-icon-wrap">
              <FeatureBoxIconCircle />
              <FeatureBoxIcon>
                <img src={webGlobeIcon} style={{ width: "100%" }} />
              </FeatureBoxIcon>
            </FeatureBoxIconWrap>
            <FeatureBoxContentWrap className="feature-content-wrap">
              <FeatureBoxContent className="feature-content">
                <FeatureBoxTitle>Global Audience</FeatureBoxTitle>
                <FeatureBoxText>
                  Reach millions worldwide and showcase your products to a
                  diverse market.
                </FeatureBoxText>
                <FeatureBoxTextLong>
                  Scalelup opens the door to millions of potential customers
                  worldwide, allowing your brand to achieve international
                  visibility. Take advantage of our advanced targeting tools to
                  ensure your products are seen by the right audience.
                </FeatureBoxTextLong>
              </FeatureBoxContent>
            </FeatureBoxContentWrap>
          </FeatureBox>
        </FeatureBoxes>
        <VideoSection>
          <ShortHeading marginbottom="40px">Scalelup in Action</ShortHeading>
          <SubtitleParagraph marginbottom="40px">
            Watch and learn how you can expand your reach and earn rewards with
            ease.
          </SubtitleParagraph>
          <VideoBox>
            <ReactPlayer
              url="https://1733686985.rsc.cdn77.org/gw/video/Scalelup-V1-comp-3.mp4"
              controls={true}
              light={videoCover}
              playing={true}
              width="100%"
              height="100%"
            />
          </VideoBox>
        </VideoSection>
        <Essentials>
          <ShortHeading>Why Might You Need It</ShortHeading>
          <EssentialsColumns>
            <Questions>
              <RollingBox>
                <QuestionBlock>
                  <QuestionLine
                    onClick={() => onQuestionClick("one")}
                    hovertext={answerOneOpened ? "#fff" : css.TEXT_GREY}
                    hovericon={answerOneOpened ? minusIcon : plusWhiteIcon}
                    hoverbackground={
                      answerOneOpened ? css.BRAND_BLUSH : css.BRAND_GREEN
                    }
                  >
                    <QuestionButtonWrap>
                      <QuestionButtonCircle
                        background={
                          answerOneOpened ? css.BRAND_BLUSH : css.BRAND_YELLOW
                        }
                      >
                        <QuestionButtonIcon
                          icon={answerOneOpened ? minusIcon : plusIcon}
                        />
                      </QuestionButtonCircle>
                    </QuestionButtonWrap>
                    <Question>Who can benefit from using Scalelup?</Question>
                  </QuestionLine>
                  <HidingBox height={answerOneOpened ? "auto" : 0}>
                    <Answer>
                      <AnswerText>
                        Scalelup is designed for a diverse range of business
                        owners, from established companies to budding
                        entrepreneurs and innovative startups. Whether you're
                        looking to amplify your reach, promote your products and
                        services, or expand your business network, Scalelup
                        provides the ideal platform for growth.
                      </AnswerText>
                      <AnswerList>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>
                              Established Businesses
                            </AnswerListTitle>
                            <AnswerListText>
                              Enhance brand visibility and attract more
                              customers to your offerings.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>
                              Independent Business Owners
                            </AnswerListTitle>
                            <AnswerListText>
                              Gain the exposure necessary to turn your ideas
                              into reality.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>Startups</AnswerListTitle>
                            <AnswerListText>
                              Quickly introduce your innovations to a broad
                              audience without substantial financial investment.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                      </AnswerList>
                      <AnswerSpacer />
                    </Answer>
                    {tabletScreen && (
                      <TabletRainbowBoxWrap>
                        <RainbowBox>
                          <RainbowBg />
                          <PhoneBox>
                            <img src={phoneMockup1} style={{ width: "100%" }} />
                            <MockupDisplay display="one" />
                          </PhoneBox>
                        </RainbowBox>
                      </TabletRainbowBoxWrap>
                    )}
                  </HidingBox>
                </QuestionBlock>
              </RollingBox>
              <RollingBox>
                <QuestionBlock>
                  <QuestionLine
                    onClick={() => onQuestionClick("two")}
                    hovertext={answerTwoOpened ? "#fff" : css.TEXT_GREY}
                    hovericon={answerTwoOpened ? minusIcon : plusWhiteIcon}
                    hoverbackground={
                      answerTwoOpened ? css.BRAND_BLUSH : css.BRAND_GREEN
                    }
                  >
                    <QuestionButtonWrap>
                      <QuestionButtonCircle
                        background={
                          answerTwoOpened ? css.BRAND_BLUSH : css.BRAND_YELLOW
                        }
                      >
                        <QuestionButtonIcon
                          icon={answerTwoOpened ? minusIcon : plusIcon}
                        />
                      </QuestionButtonCircle>
                    </QuestionButtonWrap>
                    <Question>How does Scalelup help users grow?</Question>
                  </QuestionLine>
                  <HidingBox height={answerTwoOpened ? "auto" : 0}>
                    <Answer>
                      <AnswerText>
                        Scalelup empowers you to generate buzz and drive
                        engagement through easy-to-use features:
                      </AnswerText>
                      <AnswerList>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>
                              Create Engaging Posts
                            </AnswerListTitle>
                            <AnswerListText>
                              Share text, images, and links in customizable
                              posts that captivate and inform your audience.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>
                              Expand Your Network
                            </AnswerListTitle>
                            <AnswerListText>
                              Connect with like-minded individuals, potential
                              clients, and partners to grow your business.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>
                              Drive Traffic and Sales
                            </AnswerListTitle>
                            <AnswerListText>
                              Leverage the platform to increase website traffic,
                              boost sales, and expand your team.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                      </AnswerList>
                      <AnswerText>
                        By providing a free and effective toolset, Scalelup
                        helps you gain the attention of potential customers and
                        clients effortlessly.
                      </AnswerText>
                      <AnswerSpacer />
                    </Answer>
                    {tabletScreen && (
                      <TabletRainbowBoxWrap>
                        <RainbowBox>
                          <RainbowBg />
                          <PhoneBox>
                            <img src={phoneMockup1} style={{ width: "100%" }} />
                            <MockupDisplay display="two" />
                          </PhoneBox>
                        </RainbowBox>
                      </TabletRainbowBoxWrap>
                    )}
                  </HidingBox>
                </QuestionBlock>
              </RollingBox>
              <RollingBox>
                <QuestionBlock>
                  <QuestionLine
                    onClick={() => onQuestionClick("three")}
                    hovertext={answerThreeOpened ? "#fff" : css.TEXT_GREY}
                    hovericon={answerThreeOpened ? minusIcon : plusWhiteIcon}
                    hoverbackground={
                      answerThreeOpened ? css.BRAND_BLUSH : css.BRAND_GREEN
                    }
                  >
                    <QuestionButtonWrap>
                      <QuestionButtonCircle
                        background={
                          answerThreeOpened ? css.BRAND_BLUSH : css.BRAND_YELLOW
                        }
                      >
                        <QuestionButtonIcon
                          icon={answerThreeOpened ? minusIcon : plusIcon}
                        />
                      </QuestionButtonCircle>
                    </QuestionButtonWrap>
                    <Question>What else does Scalelup offer?</Question>
                  </QuestionLine>
                  <HidingBox height={answerThreeOpened ? "auto" : 0}>
                    <Answer>
                      <AnswerText>
                        Scalelup not only helps you grow your business but also
                        offers lucrative earning opportunities:
                      </AnswerText>
                      <AnswerList>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>
                              Earn with Referrals
                            </AnswerListTitle>
                            <AnswerListText>
                              By recommending new users to the Scalelup
                              platform, you can earn money with ease. Users
                              receive a monthly Cash Bonus based on the number
                              of referrals they make.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                        <AnswerListItem>
                          <AnswerListIcon>
                            <img
                              src={circlePointGreenIcon}
                              style={{ width: "100%" }}
                            />
                          </AnswerListIcon>
                          <AnswerListItemContent>
                            <AnswerListTitle>Scalelup Contest</AnswerListTitle>
                            <AnswerListText>
                              Every week, over $2,000 is distributed among users
                              who have brought in the most referrals. It's an
                              exciting way to earn additional rewards while
                              helping others discover the benefits of Scalelup.
                            </AnswerListText>
                          </AnswerListItemContent>
                        </AnswerListItem>
                      </AnswerList>
                      <AnswerText>
                        Scalelup makes it rewarding to not only grow your own
                        business but also to help others start their journey.
                      </AnswerText>
                      <AnswerSpacer />
                    </Answer>
                    {tabletScreen && (
                      <TabletRainbowBoxWrap>
                        <RainbowBox>
                          <RainbowBg />
                          <PhoneBox>
                            <img src={phoneMockup1} style={{ width: "100%" }} />
                            <MockupDisplay display="three" />
                          </PhoneBox>
                        </RainbowBox>
                      </TabletRainbowBoxWrap>
                    )}
                  </HidingBox>
                </QuestionBlock>
              </RollingBox>
            </Questions>
            {!tabletScreen && (
              <MockupColumn>
                <RainbowBox>
                  <RainbowBg id="desktopRainbowBg" />
                  <PhoneBox id="desktopPhoneBox">
                    <img src={phoneMockup1} style={{ width: "100%" }} />
                    <MockupDisplay display={mockup} />
                  </PhoneBox>
                </RainbowBox>
              </MockupColumn>
            )}
          </EssentialsColumns>
        </Essentials>
        <Testimonials className="testimonials-section">
          <ShortHeading>Success Stories</ShortHeading>
          <SubtitleParagraph>
            Hear from our users who have embraced Scalelup and learn how it has
            helped them achieve their business goals.
          </SubtitleParagraph>
        </Testimonials>
      </NarrowContent>
      <TestimonalsRow>
        <TestimonialsMarquue className="testimonials-marquee">
          {testimonials}
          {testimonials}
        </TestimonialsMarquue>
      </TestimonalsRow>
      <NarrowContent>
        <Finale>
          <ShortHeading>Get Started Today</ShortHeading>
          <FinaleTitle>Ready to Supercharge Your Sales?</FinaleTitle>
          <FinaleBox>
            <FinaleBoxLeft>
              <FinaleLogoWrap>
                <img src={scalelupLogo} style={{ width: "100%" }} />
              </FinaleLogoWrap>
              <FinaleSlogan>
                Drive website traffic, build awareness!
              </FinaleSlogan>
            </FinaleBoxLeft>
            <FinaleBoxRight>
              <FinaleRightText>
                It takes 2 minutes and costs you nothing
              </FinaleRightText>
              <FinaleButtonWrap>
                <ButtonPrimary onClick={openSignup}>
                  Create Free Account
                </ButtonPrimary>
              </FinaleButtonWrap>
            </FinaleBoxRight>
          </FinaleBox>
        </Finale>
      </NarrowContent>
      <HidingMenuPublic />
    </LandingPage>
  );
};
