import styled from "styled-components";
import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { timeSince } from "modules/functions";
import { AWS_PROFILE_IMAGES_URL } from "constants";
import Layout from "components/Layout";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import PageTitle from "components/text/PageTitle";
import LoadingBox from "components/other/LoadingBox";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import RoundActionIcon from "components/buttons/RoundActionIcon";
import ContextMenu from "components/menu/ContextMenu";

import dotYellowIcon from "assets/img/icons/icons8-dot-yellow.svg";
import doubleTickGreyIcon from "assets/img/icons/icons8-double-tick-grey.svg";
import doubleTickBlueIcon from "assets/img/icons/icons8-double-tick-blue.svg";
import dotIndigoIcon from "assets/img/icons/icons8-dot-indigo.svg";
import profilePictureIcon from "assets/img/icons/icons8-profile-picture.svg";
import blockIcon from "assets/img/icons/icons8-unavailable.svg";
import archivedIcon from "assets/img/icons/icons8-box.svg";
import dotsEmptyIcon from "assets/img/icons/icons8-dots-empty.svg";
import dotsIcon from "assets/img/icons/icons8-dots.svg";

const Messages = styled.div`
  position: relative;
  box-sizing: border-box;
  min-width: 0;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  flex: 1;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  gap: ${css.BUTTONS_GAP_SMALL};
`;

const MenuWrap = styled.div`
  position: relative;
`;

const MenuIconBox = styled.div``;

const Chats = styled.div`
  width: 100%;
  box-sizing: border-box;

  margin: 0;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const Chat = styled(Link)`
  width: 100%;
  max-width: 100%;
  padding: ${css.BOX_PADDING_SECONDARY};
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};
  color: #fff;
  text-decoration: none;

  transition: 0.2s;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    background: ${css.FLAT_BOX_HOVER_BACKGROUND};
  }
`;

const ChatLeft = styled.div`
  min-width: 0;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProfileImageBox = styled.div``;

const ProfileImage = styled.div`
  width: 44px;
  min-width: 44px;
  height: 44px;
`;

const Content = styled.div`
  min-width: 0;

  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Name = styled.div`
  font-weight: 500;
`;

const DotIcon = styled.div`
  width: 8px;
  min-width: 8px;
  height: 8px;
  padding-top: 4px;

  display: flex;
`;

const Time = styled.div`
  font-size: 15px;
  color: ${css.TEXT_GREY};
`;

const MessageBox = styled.div`
  display: flex;
  align-items: center;
`;

const ReadIconWrap = styled.div`
  padding-right: 5px;
  padding-top: 3px;
`;

const ReadIcon = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
`;

const Message = styled.div`
  font-weight: ${(props) => (props.bold ? "500" : "400")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RightBox = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const UnreadDotIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const NoResultsBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const NoResults = styled.div`
  width: 100%;
  height: 210px;
  margin-top: 75px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const NoResultsIcon = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const NoResultsText = styled.div`
  max-width: 350px;

  line-height: 125%;
  text-align: center;
`;

const NoResultButtonWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  text-decoration: none;
`;

const BlockedIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const ChatMenu = styled.div`
  position: absolute;
  top: 42px;
  right: 0;

  transition: 0.2s;

  opacity: ${(props) => (props.opened ? 100 : 0)};
  pointer-events: ${(props) => (props.opened ? "auto" : "none")};
`;

export default ({
  data,
  messagesMenuOpened,
  onMessagesMenuClick,
  showArchived,
  onShowArchivedToggle,
  closeMessagesMenu,
}) => {
  const navigate = useNavigate();

  // set page Title
  useEffect(() => {
    document.title = "Messages | Scalelup";
  }, []);

  let list = [];

  const menuIconRef = useRef();

  if (data) {
    list = data.chats.map((chat) => {
      const unread = !chat.lastMessageRead && !chat.lastSenderMe;

      const profilePicture = chat.profileImage
        ? AWS_PROFILE_IMAGES_URL + chat.profileImage
        : profilePictureIcon;

      return (
        <Chat to={`/chat?id=${chat.id}`} key={chat.id}>
          <ChatLeft>
            <ProfileImageBox>
              <ProfileImage>
                <img
                  src={profilePicture}
                  alt="Profile image"
                  style={{ width: "100%", borderRadius: "50%" }}
                />
              </ProfileImage>
            </ProfileImageBox>
            <Content>
              <UserInfo>
                <Name>{chat.username}</Name>
                <DotIcon>
                  <img src={dotYellowIcon} style={{ width: "100%" }} />
                </DotIcon>
                <Time>{timeSince(new Date(chat.lastMessageCreatedAt))}</Time>
              </UserInfo>
              <MessageBox>
                {chat.lastSenderMe && (
                  <ReadIconWrap>
                    <ReadIcon>
                      <img
                        src={
                          chat.lastMessageRead
                            ? doubleTickBlueIcon
                            : doubleTickGreyIcon
                        }
                        alt="Read"
                        style={{ width: "100%" }}
                      />
                    </ReadIcon>
                  </ReadIconWrap>
                )}
                <Message bold={unread ? 1 : 0}>{chat.lastMessage}</Message>
              </MessageBox>
            </Content>
          </ChatLeft>
          <RightBox>
            {unread && (
              <UnreadDotIcon>
                <img src={dotIndigoIcon} style={{ width: "100%" }} />
              </UnreadDotIcon>
            )}
            {chat.blocking && (
              <BlockedIcon>
                <img src={blockIcon} style={{ width: "100%" }} />
              </BlockedIcon>
            )}
            {chat.hidden && (
              <BlockedIcon>
                <img src={archivedIcon} style={{ width: "100%" }} />
              </BlockedIcon>
            )}
          </RightBox>
        </Chat>
      );
    });
  }

  return (
    <Layout>
      <Messages>
        <Header>
          <PageTitle>Messages</PageTitle>
          <HeaderIcons>
            <MenuWrap>
              <MenuIconBox ref={menuIconRef}>
                <RoundActionIcon
                  icon={messagesMenuOpened ? dotsIcon : dotsEmptyIcon}
                  iconAlt="Chat menu"
                  onClick={onMessagesMenuClick}
                />
              </MenuIconBox>
              <ChatMenu opened={messagesMenuOpened ? 1 : 0}>
                <ContextMenu
                  items={[
                    {
                      icon: showArchived ? archivedIcon : archivedIcon,
                      label: showArchived
                        ? "Hide archived chats"
                        : "Show archived chats",
                      onClick: onShowArchivedToggle,
                    },
                  ]}
                  menuIconRef={menuIconRef}
                  closeMenu={closeMessagesMenu}
                />
              </ChatMenu>
            </MenuWrap>
          </HeaderIcons>
        </Header>
        {data && list.length > 0 && <Chats>{list}</Chats>}
        {data && list.length === 0 && (
          <NoResultsBox>
            <NoResults>
              <NoResultsIcon>
                <img src={blockIcon} style={{ width: "100%" }} />
              </NoResultsIcon>
              <NoResultsText>
                You don't have any conversations yet. Discover new posts and
                reach out to users who interest you.
              </NoResultsText>
              <NoResultButtonWrap>
                <ButtonPrimary onClick={() => navigate("/discover")}>
                  Discover
                </ButtonPrimary>
              </NoResultButtonWrap>
            </NoResults>
          </NoResultsBox>
        )}
        {!data && (
          <LoadingBox>
            <BoxLoadingSpinner height="218px" />
          </LoadingBox>
        )}
      </Messages>
    </Layout>
  );
};
