import { useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import { useSearchParams, useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";

import Chat from "./Chat";

export default () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id") ? searchParams.get("id") : null;
  const shardNumber = searchParams.get("sn") ? searchParams.get("sn") : null;
  const isReferral = searchParams.get("referral") ? true : false;

  useEffect(() => {
    if (!userId || !shardNumber) {
      // missing User ID or shard number
      navigate("/referrals");
    }
  }, []);

  // Fetch team user data
  const GET_TEAM_USER_DATA = gql`
    query GetTeamUserData($userId: ID!, $shardNumber: Int!) {
      teamUserData(userId: $userId, shardNumber: $shardNumber) {
        id
        username
        profile_image
      }
    }
  `;

  const { data, error } = useQuery(GET_TEAM_USER_DATA, {
    variables: {
      userId,
      shardNumber: Number(shardNumber),
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error fetching team user data", error);
    captureException(error);
    navigate("/referrals");
  }

  //console.log("data", data);

  let chatData;

  if (data) {
    if (!data.teamUserData) {
      navigate("/referrals");
      return;
    }

    chatData = {
      chat: {
        profileImage: data.teamUserData.profile_image,
        username: data.teamUserData.username,
        messages: [],
      },
    };
  }

  return (
    <Chat
      data={chatData}
      partnerShardNumber={shardNumber}
      userId={data?.teamUserData.id}
      isReferral={isReferral}
    />
  );
};
