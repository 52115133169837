import styled from "styled-components";

import PulseLoader from "react-spinners/PulseLoader";

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default () => {
  return (
    <Wrap>
      <PulseLoader
        color="#fff"
        loading={true}
        size={12}
        // speedMultiplier={3}
        aria-label="Loading"
        data-testid="loader"
      />
    </Wrap>
  );
};
