import React from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

export default function Component() {
  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  //console.log({ profileData });

  Intercom({
    app_id: "bow3o04s",
    user_id: profileData.id,
    name: profileData.username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: profileData.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: profileData.created, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
  });

  return <></>;
}
