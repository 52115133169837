import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const Nob = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  left: ${(props) => (props.on ? "20px" : "4px")};

  border: 0px;
  border-radius: 7px;
  background: ${(props) =>
    props.loading || props.on === null
      ? "#999999"
      : props.on
      ? css.BRAND_GREEN
      : css.BRAND_BLUSH};

  transition: 0.2s ease;
`;

const Switch = styled.div`
  position: relative;
  width: 38px;
  min-width: 38px;
  height: 20px;

  border: 0px;
  border-radius: 10px;
  background: ${css.DARK_BOX_BACKGROUND};

  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    filter: ${(props) =>
      props.loading ? "brightness(100%)" : "brightness(110%)"};
  }
`;
export default (props) => {
  const { on, onClick, loading } = props;

  return (
    <Switch
      on={on ? 1 : 0}
      onClick={() => onClick(!on)}
      loading={loading ? 1 : 0}
    >
      <Nob on={on ? 1 : 0} loading={loading ? 1 : 0} />
    </Switch>
  );
};
