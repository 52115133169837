import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const Circle = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;

  border-radius: 18px;
  background: ${css.ROUND_ICON_BACKGROUND};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: brightness(120%);
    cursor: pointer;
  }
`;

const Icon = styled.div`
  width: ${(props) => (props.iconsize ? props.iconsize : "20px")};
  height: ${(props) => (props.iconsize ? props.iconsize : "20px")};
`;

export default (props) => {
  const { icon, iconAlt, onClick, parameters, iconSize } = props;

  return (
    <Circle onClick={() => onClick(parameters)}>
      <Icon iconsize={iconSize}>
        <img src={icon} alt={iconAlt} style={{ width: "100%" }} />
      </Icon>
    </Circle>
  );
};
