import styled from "styled-components";
import { useState, useEffect } from "react";

import * as css from "styles/CssVariables.js";
import { COUNTRY_ICONS } from "constants/countries";
import globalConstants from "constants/globalConstants";

import InputText from "components/forms/InputText";

import arrowRightIcon from "assets/img/icons/icons8-arrow-right.svg";
import arrowRightWhiteIcon from "assets/img/icons/icons8-arrow-right-white.svg";
import arrowLeftIcon from "assets/img/icons/icons8-arrow-left.svg";
import arrowLeftWhiteIcon from "assets/img/icons/icons8-arrow-left-white.svg";
import searchIcon from "assets/img/icons/icons8-search.svg";
import crossIcon from "assets/img/icons/icons8-cross.svg";
import circleGreyIcon from "assets/img/icons/icons8-circle-grey.svg";
import coinsIcon from "assets/img/icons/coins-icon.svg";
import ButtonPrimary from "components/buttons/ButtonPrimary";

const TargetingForm = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const InfoText = styled.div`
  width: 100%;
  margin-top: 45px;
`;

const Columns = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};

  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: ${globalConstants.switchMobile}) {
    gap: 8px;
  }
`;

const Column = styled.div`
  width: calc(50% - 8px);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: calc(50% - 4px);
  }
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  flex-direction: ${(props) =>
    props.column === "right" ? "row-reverse" : "row"};
  justify-content: space-between;
  align-items: flex-end;
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.column === "right" ? "flex-end" : "flex-start"};
`;

const HeaderTitle = styled.div`
  font-weight: 600;
`;

const HeaderSubText = styled.div`
  padding-top: 2px;

  font-size: 13px;
`;

const ButtonIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;

  transition: 0.2s;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Button = styled.div`
  box-sizing: border-box;
  height: 28px;
  padding: 0 11px;

  background: ${css.BRAND_YELLOW};
  border-radius: 14px;
  border: 0;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: ${css.DARK_BLUE};
  white-space: nowrap;

  transition: 0.2s;

  display: flex;
  flex-direction: ${(props) =>
    props.column === "right" ? "row" : "row-reverse"};
  align-items: center;
  justify-content: center;
  gap: 2px;

  &:hover {
    cursor: pointer;

    color: #fff;

    background: ${css.BRAND_GREEN};
  }

  &:hover ${ButtonIcon} {
    background-image: url(${(props) => props.iconhover});
  }
`;

const SelectionBox = styled.div`
  width: 100%;
  height: 300px;
  min-height: 300px;
  max-height: 300px;
  margin-top: 10px;
  padding: 15px 15px;
  box-sizing: border-box;

  background: ${css.DARK_BOX_BACKGROUND};
  border: 0px;
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
`;

const CountryList = styled.div`
  margin-top: 12px;
  padding-right: 3px;

  overflow-y: scroll;
`;

const CountryData = styled.div`
  width: 100%;
  padding: 0px 8px;
  box-sizing: border-box;
  min-height: 28px;

  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;

  border-radius: 5px;

  display: flex;
  align-items: center;

  transition: 0.2s;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const CountryIcon = styled.div`
  padding-right: 8px;

  width: 18px;
  min-width: 18px;
  height: 18px;
`;

const Country = styled.div`
  max-width: 120px;

  font-size: 15px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CpiSection = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};

  display: flex;
  flex-direction: column;
`;

const CpiTitle = styled.div`
  font-weight: 500;
`;

const CpiData = styled.div`
  margin-top: 8px;

  display: flex;
  align-items: center;
`;

const CpiIcon = styled.div`
  width: 20px;
  height: 20px;
`;

const Cpi = styled.div`
  padding-left: 8px;

  font-weight: 500;
`;

const CloseButtonWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};

  display: flex;
`;

export default ({
  allCountries,
  selectedCountries,
  updateCountries,
  closeTargeting,
  cpi,
}) => {
  const initialExcluded = allCountries.filter((country) => {
    return !selectedCountries.includes(country);
  });

  const [excluded, setExcluded] = useState(initialExcluded);
  const [included, setIncluded] = useState(selectedCountries);
  const [searchExcluded, setSearchExcluded] = useState("");
  const [searchIncluded, setSearchIncluded] = useState("");

  const handleIncludeClick = (clickedSymbol) => {
    let newExcluded = [...excluded];

    newExcluded = newExcluded.filter((symbol) => {
      return symbol !== clickedSymbol;
    });

    setExcluded(newExcluded);

    let newIncluded = [...included];

    newIncluded.unshift(clickedSymbol);

    setIncluded(newIncluded);
  };

  const handleExcludeClick = (clickedCountry) => {
    let newIncluded = [...included];

    newIncluded = newIncluded.filter((country) => {
      return country !== clickedCountry;
    });

    setIncluded(newIncluded);

    let newExcluded = [...excluded];

    newExcluded.unshift(clickedCountry);

    setExcluded(newExcluded);
  };

  const includeAll = () => {
    setExcluded([]);
    setIncluded(allCountries);
  };

  const excludeAll = () => {
    setExcluded(allCountries);
    setIncluded([]);
  };

  const excludedList = excluded.reduce((result, country) => {
    if (country.toLowerCase().startsWith(searchExcluded.toLowerCase())) {
      const icon = COUNTRY_ICONS[country]
        ? COUNTRY_ICONS[country].icon
        : circleGreyIcon;

      result.push(
        <CountryData onClick={() => handleIncludeClick(country)} key={country}>
          <CountryIcon>
            <img src={icon} width="100%" alt={country} />
          </CountryIcon>
          <Country>{country}</Country>
        </CountryData>
      );
    }

    return result;
  }, []);

  const includedList = included.reduce((result, country) => {
    if (country.toLowerCase().startsWith(searchIncluded.toLowerCase())) {
      const icon = COUNTRY_ICONS[country]
        ? COUNTRY_ICONS[country].icon
        : circleGreyIcon;

      result.push(
        <CountryData onClick={() => handleExcludeClick(country)} key={country}>
          <CountryIcon>
            <img src={icon} width="100%" alt={country} />
          </CountryIcon>
          <Country>{country}</Country>
        </CountryData>
      );
    }

    return result;
  }, []);

  const onSearchExcludedChange = (e) => {
    setSearchExcluded(e.target.value);
  };

  const cancelExcludedSearch = () => {
    setSearchExcluded("");
  };

  const onSearchIncludedChange = (e) => {
    setSearchIncluded(e.target.value);
  };

  const cancelIncludedSearch = () => {
    setSearchIncluded("");
  };

  // update form on every change
  useEffect(() => {
    updateCountries(included);
  }, [included]);

  return (
    <TargetingForm>
      <InfoText>
        Select the countries where you want to display your post. The cost per
        view may increase.
      </InfoText>
      <Columns>
        <Column>
          <Header>
            <HeaderText>
              <HeaderTitle>Excluded</HeaderTitle>
              <HeaderSubText>Click to include</HeaderSubText>
            </HeaderText>

            <Button
              onClick={() => includeAll()}
              iconhover={arrowRightWhiteIcon}
            >
              <ButtonIcon icon={arrowRightIcon} />
              All
            </Button>
          </Header>
          <SelectionBox>
            <InputText
              width="100%"
              type="text"
              placeholder="Search"
              icon={searchExcluded.length === 0 ? searchIcon : crossIcon}
              onIconClick={cancelExcludedSearch}
              value={searchExcluded}
              onChange={onSearchExcludedChange}
              background="rgba(255,255,255,0.2)"
              height="36px"
              radius="10px"
            />
            <CountryList className="countrySelection">
              {excludedList}
            </CountryList>
          </SelectionBox>
        </Column>
        <Column>
          <Header column="right">
            <HeaderText column="right">
              <HeaderTitle>Included</HeaderTitle>
              <HeaderSubText>Click to exclude</HeaderSubText>
            </HeaderText>

            <Button
              onClick={() => excludeAll()}
              iconhover={arrowLeftWhiteIcon}
              column="right"
            >
              <ButtonIcon icon={arrowLeftIcon} />
              All
            </Button>
          </Header>
          <SelectionBox>
            <InputText
              width="100%"
              type="text"
              placeholder="Search"
              icon={searchIncluded.length === 0 ? searchIcon : crossIcon}
              onIconClick={cancelIncludedSearch}
              value={searchIncluded}
              onChange={onSearchIncludedChange}
              background="rgba(255,255,255,0.2)"
              height="36px"
              radius="10px"
            />
            <CountryList className="countrySelection">
              {includedList}
            </CountryList>
          </SelectionBox>
        </Column>
      </Columns>
      <CpiSection>
        <CpiTitle>CPI Estimate</CpiTitle>
        <CpiData>
          <CpiIcon>
            <img src={coinsIcon} style={{ width: "100%" }} />
          </CpiIcon>
          <Cpi>
            {cpi.min === cpi.max ? cpi.min : `${cpi.min} - ${cpi.max}`} Coins
          </Cpi>
        </CpiData>
      </CpiSection>
      <CloseButtonWrap>
        <ButtonPrimary onClick={closeTargeting}>Close</ButtonPrimary>
      </CloseButtonWrap>
    </TargetingForm>
  );
};
