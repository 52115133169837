import { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { captureException } from "@sentry/react";

import ReferralList from "./ReferralList";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [requestChatLoading, setRequestChatLoading] = useState(false);

  // Fetch data
  const GET_REFERRAL_LIST = gql`
    query GetReferralList($page: Int!) {
      referralList(page: $page) {
        referrals {
          id
          created_at
          data
          qualified_at
          referral_reward
          referral_id
          referral_shard_number
        }
        numOfResults
      }
    }
  `;

  const { data, error } = useQuery(GET_REFERRAL_LIST, {
    variables: {
      page,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching referral list error", error);
    captureException(error);
  }

  const limit = 10;

  if (data) {
    if (data.referralList.numOfResults > 0)
      if (page > Math.ceil(data.referralList.numOfResults / limit)) setPage(1);
  }

  const paginate = (where) => {
    if (where === "previous") setPage((prevState) => prevState - 1);
    if (where === "next") setPage((prevState) => prevState + 1);
  };

  // hande New Message click
  const REQUEST_CHAT = gql`
    mutation RequestChat($partnerId: ID!) {
      requestChat(partnerId: $partnerId) {
        id
      }
    }
  `;

  const [requestChat] = useMutation(REQUEST_CHAT);

  const onNewMessageClick = async (referralId, referralShardNumber) => {
    try {
      if (requestChatLoading) return;

      setRequestChatLoading(true);

      const result = await requestChat({
        variables: {
          partnerId: referralId,
        },
      });

      if (!result.data.requestChat) {
        navigate(
          `/create-team-chat?id=${referralId}&sn=${referralShardNumber}&referral=true`
        );
      } else {
        navigate(`/chat?id=${result.data.requestChat.id}`);
      }

      setRequestChatLoading(false);
    } catch (error) {
      captureException(error);
      setRequestChatLoading(false);
      console.log("requestChat error", error);
    }
  };

  //console.log({ data });

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="300px" />
        </LoadingBox>
      )}
      {data && (
        <ReferralList
          data={data}
          page={page}
          limit={limit}
          paginate={paginate}
          onNewMessageClick={onNewMessageClick}
        />
      )}
    </>
  );
};
