import styled from "styled-components";
import { useEffect } from "react";

import globalConstants from "constants/globalConstants";
import HidingMenuPublic from "./menu/HidingMenuPublic";

const PrivacyPolicy = styled.div`
  position: relative;
  width: 100%;
  padding: 90px 15vw 30px;
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
  line-height: 140%;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 90px 6vw 30px;
  }
`;

const H2Title = styled.h2`
  font-size: 30px;
  font-weight: 500;
  line-height: 115%;
`;

const UpdatedInfo = styled.div`
  margin-bottom: 20px;

  font-style: italic;
`;

const H3Title = styled.h3`
  margin: 30px 0 12px;

  font-size: 24px;
  font-weight: 400;
  line-height: 115%;
`;

const H4Title = styled.h3`
  margin: 20px 0 12px;

  font-size: 20px;
  font-weight: 400;
`;

const Paragraph = styled.div`
  margin-top: ${(props) => (props.margintop ? props.margintop : "0")};
`;

const List = styled.ul``;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Bold = styled.span`
  font-weight: 500;
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Terms and Conditions | Scalelup";
  }, []);

  return (
    <PrivacyPolicy>
      <H2Title>Terms and Conditions</H2Title>
      <UpdatedInfo>Last Updated: November 15th, 2024</UpdatedInfo>
      <H3Title>1. Introduction</H3Title>
      <H4Title>Overview of Scalelup Platform</H4Title>
      <Paragraph>
        Scalelup is an innovative platform designed to empower business owners,
        entrepreneurs, startups, and independent business operators by providing
        a space to promote their services, products, and business opportunities.
      </Paragraph>
      <H4Title>Acceptance of Terms</H4Title>
      <Paragraph>
        By accessing and using the Scalelup platform, you, the user, acknowledge
        that you have read, understood, and agree to be bound by these Terms and
        Conditions. This agreement is between you and Scalelup, the operator of
        the website, which operates under Gateway Solutions s.r.o., located at
        Rybná 24, 110 00 Praha, Czech Republic. These terms govern your use of
        the platform and constitute a legally binding agreement between you and
        Scalelup. If you do not agree to these terms, you must not use or access
        the platform.
      </Paragraph>
      <H4Title>Modifications to Terms</H4Title>
      <Paragraph>
        Scalelup reserves the right to modify or update these Terms and
        Conditions at any time, at its sole discretion. Users will be notified
        of any upcoming changes via email at least 30 days before they come into
        effect. Your continued use of the platform following the implementation
        of changes signifies your acknowledgement and acceptance of the new
        terms.
      </Paragraph>
      <H3Title>2. Eligibility</H3Title>
      <H4Title>Age Requirements</H4Title>
      <Paragraph>
        To access and use the Scalelup platform, you must be at least 18 years
        old. By creating an account and using our services, you confirm that you
        meet this age requirement. If you are under 18, you are not permitted to
        use the platform.
      </Paragraph>
      <H4Title>Business Promotion Guidelines</H4Title>
      <Paragraph>
        Scalelup provides users with the flexibility to promote a wide range of
        legitimate businesses and opportunities. You can showcase products,
        services, or business ventures across various industries, including:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>E-commerce Products:</Bold> Promote goods from online stores,
          including fashion, electronics, and more.
        </ListItem>
        <ListItem>
          <Bold>Network Marketing Opportunities:</Bold> Recruit participants for
          multi-level marketing initiatives.
        </ListItem>
        <ListItem>
          <Bold>Consulting Services:</Bold> Advertise expertise in areas such as
          business strategy, marketing, and IT.
        </ListItem>
        <ListItem>
          <Bold>Online Courses:</Bold> Feature educational programs and
          workshops.
        </ListItem>
        <ListItem>
          <Bold>Health and Wellness Products:</Bold> Introduce supplements,
          fitness programs, and wellness retreats.
        </ListItem>
        <ListItem>
          <Bold>Real Estate Listings:</Bold> Showcase properties available for
          sale or rent.
        </ListItem>
        <ListItem>
          <Bold>Freelance Services:</Bold> Market writing, design, programming,
          and other freelance offerings.
        </ListItem>
        <ListItem>
          <Bold>Local Services:</Bold> Promote services like home repair,
          cleaning, and landscaping.
        </ListItem>
        <ListItem>
          <Bold>Event Planning:</Bold> Advertise event services for weddings,
          corporate events, and parties.
        </ListItem>
        <ListItem>
          <Bold>Software Solutions:</Bold> Highlight SaaS products and
          technological innovations.
        </ListItem>
      </List>
      <Paragraph>
        While Scalelup offers a broad platform for promotion, it is crucial to
        adhere to our content guidelines to maintain platform integrity and
        comply with legal standards. Prohibited content includes anything
        illegal, offensive, misleading, or fraudulent. Examples of prohibited
        activities, products, or services include, but are not limited to:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Illegal Products or Services:</Bold> Drugs, weapons, or any
          items/services that violate the law.
        </ListItem>
        <ListItem>
          <Bold>Fraudulent Schemes:</Bold> Ponzi schemes, pyramid schemes, or
          any deceptive financial activities.
        </ListItem>
        <ListItem>
          <Bold>Offensive Content:</Bold> Hate speech, discriminatory practices,
          or anything inciting violence.
        </ListItem>
        <ListItem>
          <Bold>Adult Content:</Bold> Pornography or sexually explicit material.
        </ListItem>
        <ListItem>
          <Bold>Counterfeit Goods:</Bold> Fake or replica products infringing on
          intellectual property rights.
        </ListItem>
        <ListItem>
          <Bold>Unlicensed Medical Products:</Bold> Unauthorized health products
          or treatments.
        </ListItem>
      </List>
      <Paragraph>
        Compliance with these guidelines ensures that your posts remain active
        and compliant on the platform.
      </Paragraph>
      <H3Title>3. Account Registration</H3Title>
      <H4Title>Account Creation </H4Title>
      <Paragraph>
        To utilize the features of the Scalelup platform, you must first create
        an account using a valid email address. A functional email address is
        essential for account verification, communication regarding your
        account, and receiving important updates about the platform. While you
        have the option to provide your real name, it is not mandatory. You may
        choose to register using your company's name or a nickname instead.
      </Paragraph>
      <H4Title>Account Security</H4Title>
      <Paragraph>
        Maintaining the security of your account is your responsibility. You
        must keep your password confidential and not disclose it to any third
        party. You agree to notify Scalelup immediately of any unauthorized use
        of your account or any other breach of security. Scalelup will not be
        liable for any loss or damage arising from your failure to comply with
        these security obligations.
      </Paragraph>
      <H3Title>4. Platform Usage</H3Title>
      <H4Title>Account Access and Use</H4Title>
      <Paragraph>
        Upon registering on Scalelup, users gain access to various features for
        promoting their businesses. Users can create and manage posts, engage
        with the community, and track the performance of their content through
        available analytics tools.
      </Paragraph>
      <H4Title>Content Creation</H4Title>
      <Paragraph>
        Users are responsible for ensuring that their posts, including text,
        images, and links, comply with Scalelup's content guidelines. Posts
        should accurately represent the user's business and adhere to legal
        standards. Scalelup reserves the right to review, approve, or remove any
        content that violates these guidelines.
      </Paragraph>
      <H4Title>Coins and Cost Per Impression (CPI)</H4Title>
      <Paragraph>
        Scalelup Coins are the platform's internal currency used for promoting
        posts. Users earn Coins through platform activities or referrals. The
        Cost Per Impression (CPI) reflects the number of Coins deducted for
        displaying a post to other users. Users can manage their Coin
        expenditure by setting a maximum CPI.
      </Paragraph>
      <H4Title>Networking and Communication</H4Title>
      <Paragraph>
        Users can engage with others through private messaging. All
        communications should be professional and relevant to business
        opportunities. Scalelup reserves the right to monitor and regulate
        messaging to prevent misuse or harassment.
      </Paragraph>
      <H4Title>Compliance and Restrictions</H4Title>
      <Paragraph>
        Users must comply with all applicable laws and platform policies while
        using Scalelup. The platform prohibits any content or activity that is
        illegal, offensive, misleading, or fraudulent. Scalelup maintains the
        authority to suspend or terminate accounts that violate these terms.
      </Paragraph>
      <H3Title>5. User Conduct</H3Title>
      <H4Title>General Responsibilities</H4Title>
      <Paragraph>
        Users of Scalelup are expected to conduct themselves professionally and
        respectfully while interacting with the platform and its community. All
        activities must align with the platform's guidelines and legal
        requirements.
      </Paragraph>
      <H4Title>Prohibited Activities</H4Title>
      <Paragraph>
        Users must not engage in any activities that are illegal, harmful, or
        disruptive to the platform or its users. This includes but is not
        limited to:
      </Paragraph>
      <List>
        <ListItem>
          Posting content that is offensive, defamatory, or discriminatory.
        </ListItem>
        <ListItem>Engaging in fraudulent or deceptive practices.</ListItem>
        <ListItem>Distributing spam or unsolicited messages.</ListItem>
        <ListItem>
          Attempting to gain unauthorized access to other users' accounts or
          Scalelup's systems.
        </ListItem>
        <ListItem>
          Using the platform to promote illegal goods or services.
        </ListItem>
      </List>
      <H4Title>Content Compliance</H4Title>
      <Paragraph>
        All user-generated content must comply with Scalelup's content
        standards. Users are responsible for ensuring that their posts do not
        infringe on the rights of others or violate any applicable laws.
      </Paragraph>
      <H4Title>Reporting Violations</H4Title>
      <Paragraph>
        Scalelup encourages users to report any conduct or content that violates
        these terms. Users can report issues through the platform's designated
        channels. Scalelup will investigate reported violations and take
        appropriate action, which may include content removal, account
        suspension, or termination.
      </Paragraph>
      <H4Title>Consequences of Misconduct</H4Title>
      <Paragraph>
        Scalelup reserves the right to take corrective measures against users
        who violate these conduct guidelines. Such measures may include
        warnings, suspension of account privileges, or permanent account
        termination. Scalelup's decisions regarding enforcement of these terms
        are final.
      </Paragraph>
      <Paragraph margintop="12px">
        By using Scalelup, users agree to adhere to these conduct standards and
        acknowledge that violations may result in penalties or termination of
        access to the platform.
      </Paragraph>
      <H3Title>6. Scalelup Coins</H3Title>
      <Paragraph>
        Scalelup Coins are the internal currency used within the platform to
        promote posts and enhance business visibility. Coins are
        non-transferable and cannot be exchanged for cash or any other form of
        monetary compensation.
      </Paragraph>
      <H4Title>Refund Policy</H4Title>
      <Paragraph>
        Users may choose to purchase additional Coins to increase their
        promotional activities on the platform. All purchases of Coins are
        final, and Scalelup does not offer refunds. By purchasing Coins, users
        agree to this no-refund policy, acknowledging that requests for refunds
        will not be entertained.
      </Paragraph>
      <H4Title>Expiration of Coins</H4Title>
      <Paragraph>
        Scalelup Coins never expire and will remain in the user's account
        indefinitely unless the user decides to terminate their account.
      </Paragraph>
      <H3Title>7. Referral Program</H3Title>
      <Paragraph>
        The Scalelup Referral Program allows users to earn rewards by referring
        new members to the platform. Users are provided with a unique referral
        link to share with potential new users. Upon successful registration and
        qualification of these referrals, users can earn Scalelup Coins and may
        qualify for cash bonuses.
      </Paragraph>
      <H4Title>Qualified Referrals</H4Title>
      <Paragraph>
        A referral is considered "qualified" once the referred user registers
        using the unique referral link and views at least 40 posts in the
        Discover section. Only qualified referrals contribute to earning
        rewards, including Coins and eligibility for cash bonuses.
      </Paragraph>
      <H4Title>Rewards and Bonuses</H4Title>
      <List>
        <ListItem>
          <Bold>Coins:</Bold> Users earn 300 Scalelup Coins for each qualified
          referral.
        </ListItem>
        <ListItem>
          <Bold>Cash Bonuses:</Bold> Users may earn cash bonuses based on the
          number of qualified referrals within a calendar month.
        </ListItem>
      </List>
      <H4Title>Prohibited Conduct</H4Title>
      <Paragraph>
        Users must not engage in fraudulent activities to manipulate referral
        numbers, such as creating fake accounts. Any form of cheating within the
        referral program is strictly prohibited. Users found violating these
        terms will face immediate removal from the program, forfeiture of all
        rewards, and possible legal action.
      </Paragraph>
      <H4Title>Scalelup Contest</H4Title>
      <Paragraph>
        Participation in the referral program also contributes to the Scalelup
        Contest, where users can earn additional rewards based on their activity
        and engagement. Contest points are awarded for Coins earned through
        qualified referrals, enhancing users' standings in weekly competitions.
      </Paragraph>
      <H3Title>8. Communication</H3Title>
      <Paragraph>
        Users are encouraged to engage with others on the Scalelup platform in a
        respectful and professional manner. All communications should be
        relevant to business opportunities and conducted with integrity. Users
        must refrain from sending unsolicited, spam, or inappropriate messages.
        Scalelup reserves the right to monitor communications and may take
        action against users who violate these guidelines, including restricting
        or terminating messaging privileges.
      </Paragraph>
      <H4Title>Responsibility for Content</H4Title>
      <Paragraph>
        Scalelup acts as a platform for users to connect and exchange
        information, but it does not endorse or verify the accuracy of any
        information, business offers, or links shared between users. Users are
        solely responsible for evaluating the legitimacy and suitability of any
        content received from others. Scalelup is not liable for any loss or
        damage arising from communications or transactions between users.
      </Paragraph>
      <H4Title>Limitations on Liability</H4Title>
      <Paragraph>
        By using the communication features of Scalelup, users acknowledge and
        accept that they engage in interactions and transactions with other
        users at their own risk. It is the responsibility of each user to
        conduct due diligence before proceeding with any business dealings or
        agreements.
      </Paragraph>
      <H3Title>9. Intellectual Property</H3Title>
      <H4Title>Ownership of Content</H4Title>
      <Paragraph>
        Scalelup and its licensors own all rights, titles, and interests in and
        to the platform, including all related software, logos, trademarks,
        service marks, and proprietary content. These elements are protected by
        international copyright, trademark, and other intellectual property
        laws.
      </Paragraph>
      <H4Title>User-Generated Content</H4Title>
      <Paragraph>
        By submitting content to Scalelup, including but not limited to text,
        images, and links, you grant Scalelup a non-exclusive, royalty-free,
        worldwide, perpetual, and irrevocable license to use, reproduce, modify,
        publish, and distribute such content on the platform. You represent and
        warrant that you own or have the necessary rights and permissions to
        grant this license for any content you submit.
      </Paragraph>
      <H4Title>Prohibited Use</H4Title>
      <Paragraph>
        Users may not use, reproduce, modify, or distribute any Scalelup content
        or intellectual property without explicit permission from Scalelup or
        the appropriate rights holder. Unauthorized use of Scalelup's
        intellectual property may result in legal action.
      </Paragraph>
      <H4Title>Infringement Claims</H4Title>
      <Paragraph>
        If you believe that your intellectual property rights have been
        infringed upon by content on Scalelup, please notify us with a detailed
        description of the alleged infringement. Scalelup will investigate all
        claims and take appropriate action.
      </Paragraph>
      <H3Title>10. Termination and Suspension</H3Title>
      <H4Title>Account Termination</H4Title>
      <Paragraph>
        Scalelup reserves the right to terminate or suspend your account at its
        sole discretion, with or without notice, for any conduct that Scalelup,
        in its judgment, believes violates these Terms and Conditions or is
        harmful to the interests of Scalelup, its users, or third parties. This
        includes, but is not limited to, fraudulent activities, breaches of
        intellectual property, or any illegal actions.
      </Paragraph>
      <H4Title>User-Initiated Termination</H4Title>
      <Paragraph>
        You may terminate your account at any time by following the account
        termination process provided in your account settings. Upon termination,
        you will lose access to all features of the platform, and any remaining
        Scalelup Coins in your account will be forfeited.
      </Paragraph>
      <H3Title>11. Limitation of Liability</H3Title>
      <H4Title>General Limitation</H4Title>
      <Paragraph>
        To the fullest extent permitted by applicable law, Scalelup, its
        affiliates, and their respective directors, officers, employees, agents,
        and licensors, shall not be liable for any indirect, incidental,
        special, consequential, or punitive damages, or any loss of profits or
        revenues, whether incurred directly or indirectly, or any loss of data,
        use, goodwill, or other intangible losses, resulting from:
      </Paragraph>
      <List>
        <ListItem>
          Your access to or use of, or inability to access or use, the Scalelup
          platform.
        </ListItem>
        <ListItem>
          Any conduct or content of any third party on the platform, including
          any defamatory, offensive, or illegal conduct of other users or third
          parties.
        </ListItem>
        <ListItem>Any content obtained from the platform.</ListItem>
        <ListItem>
          Unauthorized access, use, or alteration of your transmissions or
          content.
        </ListItem>
      </List>
      <H4Title>Maximum Liability</H4Title>
      <Paragraph>
        In no event shall Scalelup's total liability to you for all claims
        arising out of or relating to these terms or your use of the platform
        exceed the amount you have paid Scalelup, if any, during the
        twelve-month period preceding the date of the event giving rise to the
        liability.
      </Paragraph>
      <H4Title>Acknowledgment of Risk</H4Title>
      <Paragraph>
        By using the Scalelup platform, you acknowledge that there are risks
        inherent in using an online platform, including risks associated with
        interactions with other users and third parties, and you agree to assume
        these risks.
      </Paragraph>
      <H4Title>Jurisdictional Limitations</H4Title>
      <Paragraph>
        Some jurisdictions do not allow the exclusion or limitation of liability
        for consequential or incidental damages, so the above limitation may not
        apply to you. In such cases, Scalelup's liability will be limited to the
        fullest extent permitted by applicable law.
      </Paragraph>
      <H3Title>14. Contact Information</H3Title>
      <Paragraph>
        For any questions, concerns, or inquiries regarding these Terms and
        Conditions or the Scalelup platform, please contact us at:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Email:</Bold> support@scalelup.com
        </ListItem>
        <ListItem>
          <Bold>Mailing Address:</Bold> Gateway Solutions s.r.o., Rybná 24,
          Praha, 110 00, Czech Republic
        </ListItem>
      </List>
      <HidingMenuPublic />
    </PrivacyPolicy>
  );
};
