import styled from "styled-components";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";

import crossIcon from "assets/img/icons/icons8-cross-black.svg";
import bulbIcon from "assets/img/icons/tip-bulb-black.svg";
import ButtonTertiary from "components/buttons/ButtonTertiary";

const TutorialBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BRAND_YELLOW};
  border-radius: ${css.BOX_RADIUS_PRIMARY};
  color: ${css.DARK_BLUE};

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const TipWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  display: flex;
`;

const TipIcon = styled.div`
  margin-top: 4px;

  width: 24px;
  min-width: 24px;
  height: 24px;
`;

const TipText = styled.div`
  padding-left: 12px;

  line-height: 1.3;
  font-weight: 400;

  flex: 1;
`;

const ButtonWrap = styled(Link)`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  text-decoration: none;

  display: flex;
`;

export default ({ title, text, closeBox, buttonTo, buttonText }) => {
  return (
    <TutorialBox>
      <Header>
        <Title>{title}</Title>
        <CloseIcon onClick={closeBox}>
          <img src={crossIcon} alt="Close" style={{ width: "100%" }} />
        </CloseIcon>
      </Header>
      <TipWrap>
        <TipIcon>
          <img src={bulbIcon} alt="Tip" style={{ width: "100%" }} />
        </TipIcon>
        <TipText>{text}</TipText>
      </TipWrap>
      {buttonTo && (
        <ButtonWrap to={buttonTo}>
          <ButtonTertiary>{buttonText}</ButtonTertiary>
        </ButtonWrap>
      )}
    </TutorialBox>
  );
};
