import { useEffect, useRef } from "react";
import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import ContextMenuItem from "./ContextMenuItem";

const ContextMenu = styled.div`
  padding: 5px 10px;

  border-radius: ${css.BOX_RADIUS_SECONDARY};
  background: ${css.FLAT_BOX_BACKGROUND};
  box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export default ({ items, closeMenu, menuIconRef }) => {
  const menuRef = useRef();

  function useMenuOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !menuIconRef.current.contains(event.target)
        ) {
          closeMenu();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useMenuOutsideClick(menuRef);

  const list = items.map((item) => {
    const { label, icon, onClick } = item;

    return (
      <ContextMenuItem icon={icon} onClick={onClick} key={label}>
        {label}
      </ContextMenuItem>
    );
  });

  return <ContextMenu ref={menuRef}>{list}</ContextMenu>;
};
