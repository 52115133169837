import styled from "styled-components";
import { LineChart } from "@mui/x-charts/LineChart";

import * as css from "styles/CssVariables.js";
import { dateFormatted, numberWithCommas } from "modules/functions";

import referralsQualifiedIcon from "assets/img/icons/referral-qualified-icon.svg";
import referralsIcon from "assets/img/icons/referrals-icon.svg";
import coinsWhiteIcon from "assets/img/icons/coins-white-icon.svg";
import dotBlushIcon from "assets/img/icons/icons8-dot-blush.svg";
import dotIndigoIcon from "assets/img/icons/icons8-dot-indigo.svg";
import dotYellowIcon from "assets/img/icons/icons8-dot-yellow.svg";

const ReferralStats = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;

  gap: 20px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Stats = styled.div`
  max-width: 282px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 0.5;
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const Trends = styled.div`
  max-width: 282px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 0.5;
`;

const StatsBox = styled.div`
  padding: 15px 20px;
  width: 100%;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  flex: 0.5;

  display: grid;
  grid-template-columns: 37px 73px 73px 1fr;
  grid-template-rows: 25px 1fr 1fr 1fr;
`;

const StatsLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
`;

const LabelIconCell = styled.div`
  display: flex;
  align-items: center;
`;

const LabelIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
`;

const ValueCell = styled.div`
  height: 24px;

  font-size: 13px;

  display: flex;
  align-items: center;
`;

const ChangeBadge = styled.div`
  height: 16px;
  padding: 0 4px 1px;
  margin-left: 5px;

  font-size: 11px;

  border-radius: 8px;
  background: ${(props) =>
    props.green ? css.POSITIVE_GREEN : css.NEGATIVE_RED};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TrendsBox = styled.div`
  padding: ${css.BOX_PADDING_TERTIARY};
  width: 100%;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  flex: 0.5;
`;

const TrendsHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TrendsLegend = styled.div`
  padding-top: 3px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const TrendsLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const TrendsLegendItemIcon = styled.div`
  width: 10px;
  min-width: 10px;
  height: 10px;

  display: flex;
`;

const TrendsLegendItemName = styled.div`
  font-size: 13px;
`;

export default ({ data }) => {
  const { referrals, qualifiedReferrals, userStats } = data.referralStats;

  const statsTo = userStats[0] ? userStats[0].to : null;
  const statsTotalReferrals = userStats[0]
    ? userStats[0].total_referrals
    : null;
  const statsTotalQualified = userStats[0]
    ? userStats[0].total_qualified_referrals
    : null;
  const statsTotalRewards = userStats[0]
    ? userStats[0].total_referral_rewards
    : null;

  // Calculate Referrals, Qualified referrals and Referral rewards stats
  const dayLimit = new Date();
  dayLimit.setDate(dayLimit.getDate() - 1);

  const twoDaysLimit = new Date();
  twoDaysLimit.setDate(twoDaysLimit.getDate() - 2);

  const weekLimit = new Date();
  weekLimit.setDate(weekLimit.getDate() - 7);

  const last24hoursReferrals = referrals.filter(
    (referral) => referral.created_at >= dayLimit.toISOString()
  );

  const previous24hoursReferrals = referrals.filter(
    (referral) =>
      referral.created_at < dayLimit.toISOString() &&
      referral.created_at >= twoDaysLimit.toISOString()
  );

  const last24hoursReferralsChange =
    previous24hoursReferrals.length > 0
      ? (100 / previous24hoursReferrals.length) * last24hoursReferrals.length -
        100
      : "-";

  const last24hoursQualified = qualifiedReferrals.filter(
    (referral) => referral.qualified_at >= dayLimit.toISOString()
  );

  const previous24hoursQualified = qualifiedReferrals.filter(
    (referral) =>
      referral.qualified_at < dayLimit.toISOString() &&
      referral.qualified_at >= twoDaysLimit.toISOString()
  );

  const last24hoursQualifiedChange =
    previous24hoursQualified.length > 0
      ? (100 / previous24hoursQualified.length) * last24hoursQualified.length -
        100
      : "-";

  const last24hoursRewards = last24hoursQualified.reduce((sum, referral) => {
    return sum + referral.referral_reward;
  }, 0);

  const previous24hoursRewards = previous24hoursQualified.reduce(
    (sum, referral) => {
      return sum + referral.referral_reward;
    },
    0
  );

  const last24hoursRewardsChange =
    previous24hoursRewards > 0
      ? (100 / previous24hoursRewards) * last24hoursRewards - 100
      : "-";

  const lastWeekReferrals = referrals.filter(
    (referral) => referral.created_at >= weekLimit.toISOString()
  );

  const previousWeekReferrals = referrals.filter(
    (referral) => referral.created_at < weekLimit.toISOString()
  );

  const lastWeekReferralsChange =
    previousWeekReferrals.length > 0
      ? (100 / previousWeekReferrals.length) * lastWeekReferrals.length - 100
      : "-";

  const lastWeekQualified = qualifiedReferrals.filter(
    (referral) => referral.qualified_at >= weekLimit.toISOString()
  );

  const previousWeekQualified = qualifiedReferrals.filter(
    (referral) => referral.qualified_at < weekLimit.toISOString()
  );

  const lastWeekQualifiedChange =
    previousWeekQualified.length > 0
      ? (100 / previousWeekQualified.length) * lastWeekQualified.length - 100
      : "-";

  const lastWeekRewards = lastWeekQualified.reduce((sum, referral) => {
    return sum + referral.referral_reward;
  }, 0);

  const previousWeekRewards = previousWeekQualified.reduce((sum, referral) => {
    return sum + referral.referral_reward;
  }, 0);

  const lastWeekRewardsChange =
    previousWeekRewards > 0
      ? (100 / previousWeekRewards) * lastWeekRewards - 100
      : "-";

  const referralsFromLastTotalUpdate = statsTo
    ? referrals.filter((referral) => referral.created_at > statsTo)
    : referrals;

  const totalReferrals =
    statsTotalReferrals + referralsFromLastTotalUpdate.length;

  const qualifiedFromLastTotalUpdate = statsTo
    ? qualifiedReferrals.filter((referral) => referral.qualified_at > statsTo)
    : qualifiedReferrals;

  const totalQualified =
    statsTotalQualified + qualifiedFromLastTotalUpdate.length;

  const rewardsFromLastTotalUpdate = qualifiedFromLastTotalUpdate.reduce(
    (sum, referral) => {
      return sum + referral.referral_reward;
    },
    0
  );

  const totalRewards = statsTotalRewards + rewardsFromLastTotalUpdate;

  // prepare Trends charts
  let chartsData = [];

  let sumReferrals = 0;
  let sumQualified = 0;
  let sumRewards = 0;

  const rangeDays = 7;

  let yOneValues = [];
  let yTwoValues = [];

  for (let i = 1; i <= 70; i++) {
    if (userStats[i - 1]) {
      const { referrals, qualified_referrals, referral_rewards } =
        userStats[i - 1];

      sumReferrals += referrals;
      sumQualified += qualified_referrals;
      sumRewards += referral_rewards;
    }

    if (i % rangeDays === 0) {
      const dateRangeStart = new Date();
      dateRangeStart.setDate(dateRangeStart.getDate() - i);

      const dateRangeEnd = new Date();
      dateRangeEnd.setDate(dateRangeEnd.getDate() - (i - 6));

      const dateRange =
        dateFormatted(dateRangeStart) + " - " + dateFormatted(dateRangeEnd);

      chartsData.push({
        name: dateRange,
        referrals: sumReferrals,
        qualified: sumQualified,
        rewards: sumRewards,
      });

      yOneValues.push(sumReferrals);
      yOneValues.push(sumQualified);
      yTwoValues.push(sumRewards);

      sumReferrals = 0;
      sumQualified = 0;
      sumRewards = 0;
    }
  }

  const yOneMin = Math.min(...yOneValues);
  const yOneMax = Math.max(...yOneValues);
  const yTwoMin = Math.min(...yTwoValues);
  const yTwoMax = Math.max(...yTwoValues);

  const reversedChartsData = chartsData.reverse();

  const reduceValue = (value) => {
    if (value < 10000) return numberWithCommas(value);
    return Math.round(value / 1000, 0) + "k";
  };

  const changeBadge = (change) => {
    if (typeof change !== "number") return <></>;

    if (change > 100) return <ChangeBadge green={true}>&gt;100%</ChangeBadge>;

    const roundCoef = change >= 10 || change <= -10 ? 1 : 10;
    let changeRounded = Math.round(change * roundCoef) / roundCoef;

    if (changeRounded >= 0)
      return <ChangeBadge green={true}>+{changeRounded}%</ChangeBadge>;
    else return <ChangeBadge green={false}>{changeRounded}%</ChangeBadge>;
  };

  return (
    <ReferralStats>
      <Stats>
        <BoxTitle>Referrals and Rewards</BoxTitle>
        <StatsBox>
          <StatsLabel></StatsLabel>
          <StatsLabel>24 Hours</StatsLabel>
          <StatsLabel>7 Days</StatsLabel>
          <StatsLabel>Total</StatsLabel>
          <LabelIconCell>
            <LabelIcon>
              <img src={referralsIcon} style={{ width: "100%" }} />
            </LabelIcon>
          </LabelIconCell>
          <ValueCell>
            {last24hoursReferrals.length}
            {changeBadge(last24hoursReferralsChange)}
          </ValueCell>
          <ValueCell>
            {lastWeekReferrals.length}
            {changeBadge(lastWeekReferralsChange)}
          </ValueCell>
          <ValueCell>{totalReferrals}</ValueCell>
          <LabelIconCell>
            <LabelIcon>
              <img src={referralsQualifiedIcon} style={{ width: "100%" }} />
            </LabelIcon>
          </LabelIconCell>
          <ValueCell>
            {last24hoursQualified.length}
            {changeBadge(last24hoursQualifiedChange)}
          </ValueCell>
          <ValueCell>
            {lastWeekQualified.length}
            {changeBadge(lastWeekQualifiedChange)}
          </ValueCell>
          <ValueCell>{totalQualified}</ValueCell>
          <LabelIconCell>
            <LabelIcon>
              <img src={coinsWhiteIcon} style={{ width: "100%" }} />
            </LabelIcon>
          </LabelIconCell>
          <ValueCell>
            {reduceValue(last24hoursRewards)}
            {changeBadge(last24hoursRewardsChange)}
          </ValueCell>
          <ValueCell>
            {reduceValue(lastWeekRewards)} {changeBadge(lastWeekRewardsChange)}
          </ValueCell>
          <ValueCell>{reduceValue(totalRewards)}</ValueCell>
        </StatsBox>
      </Stats>
      <Trends>
        <TrendsHeader>
          <BoxTitle>Trends</BoxTitle>
          <TrendsLegend>
            <TrendsLegendItem>
              <TrendsLegendItemIcon>
                <img src={dotBlushIcon} style={{ width: "100%" }} />
              </TrendsLegendItemIcon>
              <TrendsLegendItemName>Referrals</TrendsLegendItemName>
            </TrendsLegendItem>
            <TrendsLegendItem>
              <TrendsLegendItemIcon>
                <img src={dotIndigoIcon} style={{ width: "100%" }} />
              </TrendsLegendItemIcon>
              <TrendsLegendItemName>Qualified</TrendsLegendItemName>
            </TrendsLegendItem>
            <TrendsLegendItem>
              <TrendsLegendItemIcon>
                <img src={dotYellowIcon} style={{ width: "100%" }} />
              </TrendsLegendItemIcon>
              <TrendsLegendItemName>Rewards</TrendsLegendItemName>
            </TrendsLegendItem>
          </TrendsLegend>
        </TrendsHeader>
        <TrendsBox>
          <LineChart
            width={238}
            height={96}
            margin={{ top: 5, bottom: 5, left: 5, right: 0 }}
            xAxis={[
              {
                scaleType: "point",
                dataKey: "name",
              },
            ]}
            yAxis={[
              { id: "referrals", min: yOneMin, max: yOneMax * 1.1 },
              { id: "rewards", min: yTwoMin, max: yTwoMax },
            ]}
            grid={{ vertical: true }}
            series={[
              {
                id: "referrals",
                dataKey: "referrals",
                label: "Referrals",
                color: "#EEE5E9",
                yAxisId: "referrals",
              },
              {
                id: "qualified",
                dataKey: "qualified",
                label: "Qualified Referrals",
                color: "#8783D1",
                yAxisId: "referrals",
              },
              {
                id: "rewards",
                dataKey: "rewards",
                label: "Referral Rewards",
                color: "#FFE70D",
                yAxisId: "rewards",
              },
            ]}
            dataset={reversedChartsData}
            slotProps={{
              legend: { hidden: true },
              popper: {
                sx: {
                  boxShadow: 3,
                  border: "0px",
                  borderRadius: "15px",
                  backgroundColor: "#131F2A",
                  "& .MuiChartsTooltip-table": {
                    backgroundColor: "#131F2A",
                  },
                  "& .MuiTypography-root": {
                    color: "#fff",
                    fontSize: "13px",
                  },
                },
              },
            }}
            sx={{
              "& .MuiChartsAxis-line": {
                opacity: 0,
              },
              "& .MuiChartsAxis-tickContainer": {
                opacity: 0,
              },
              "& .MuiChartsAxisHighlight-root": {
                stroke: "#ffffff",
              },
              "& .MuiLineElement-series-qualified": {
                strokeWidth: 2,
              },
              "& .MuiMarkElement-series-qualified": {
                scale: "0",
                strokeWidth: 0,
              },
              "& .MuiLineElement-series-referrals": {
                strokeWidth: 2,
              },
              "& .MuiMarkElement-series-referrals": {
                scale: "0",
                strokeWidth: 0,
              },
              "& .MuiLineElement-series-rewards": {
                strokeWidth: 2,
              },
              "& .MuiMarkElement-series-rewards": {
                scale: "0",
                strokeWidth: 0,
              },
              "& .MuiChartsGrid-verticalLine": {
                stroke: "#757575",
                strokeDasharray: "2 4",
              },
            }}
          />
        </TrendsBox>
      </Trends>
    </ReferralStats>
  );
};
