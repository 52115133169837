import styled from "styled-components";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import ToolboxItem from "./ToolboxItem";

import homeIcon from "assets/img/icons/menu-home.svg";
import discoverIcon from "assets/img/icons/menu-discover-white.svg";
import messagesIcon from "assets/img/icons/menu-messages.svg";
import postsIcon from "assets/img/icons/menu-posts.svg";
import homeCurrentIcon from "assets/img/icons/menu-home-current.svg";
import discoverCurrentIcon from "assets/img/icons/menu-discover-current-white.svg";
import messagesCurrentIcon from "assets/img/icons/menu-messages-current.svg";
import postsCurrentIcon from "assets/img/icons/menu-posts-current.svg";
import menuIcon from "assets/img/icons/toolbox-menu.svg";
import crossIcon from "assets/img/icons/icons8-cross-big.svg";

// Actions
import { openHidingMenu, closeHidingMenu } from "store/actions";

const Toolbox = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 64px;
  box-sizing: border-box;

  background: #000;

  z-index: 300;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToolboxItems = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 0 10vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default ({ unreadMessages }) => {
  const dispatch = useDispatch();

  const LayoutProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      hidingMenu: layout.hidingMenu,
    })
  );

  const { hidingMenu } = useSelector(LayoutProperties);

  const openMenu = () => {
    dispatch(openHidingMenu());
  };

  const closeMenu = () => {
    dispatch(closeHidingMenu());
  };

  return (
    <Toolbox>
      <ToolboxItems>
        <ToolboxItem to="/home" icon={homeIcon} iconCurrent={homeCurrentIcon} />
        <ToolboxItem
          to="/discover"
          icon={discoverIcon}
          iconCurrent={discoverCurrentIcon}
          onClick={closeMenu}
        />
        <ToolboxItem
          to="/messages"
          icon={messagesIcon}
          iconCurrent={messagesCurrentIcon}
          onClick={closeMenu}
        />
        <ToolboxItem
          to="/posts"
          icon={postsIcon}
          iconCurrent={postsCurrentIcon}
          onClick={closeMenu}
        />
        {hidingMenu ? (
          <ToolboxItem
            to="#"
            icon={crossIcon}
            alt="Close Menu"
            padding="1px"
            onClick={closeMenu}
          />
        ) : (
          <ToolboxItem
            to="#"
            icon={menuIcon}
            alt="Open Menu"
            onClick={openMenu}
            dot={unreadMessages}
          />
        )}
      </ToolboxItems>
    </Toolbox>
  );
};
