import { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";

import Messages from "./Messages";

export default () => {
  const [showArchived, setShowArchived] = useState(() => {
    const showArchivedStored = JSON.parse(
      localStorage.getItem("show-archived-chats")
    );
    if (showArchivedStored) return showArchivedStored;
    else return false;
  });
  const [messagesMenuOpened, setMessagesMenuOpened] = useState(false);

  // Fetch data
  const GET_CHATS_DATA = gql`
    query GetChatsData($showArchived: Boolean!) {
      chats(showArchived: $showArchived) {
        id
        lastMessageCreatedAt
        lastMessage
        lastMessageRead
        lastSenderMe
        username
        profileImage
        blocking
        hidden
      }
    }
  `;

  const { data, error } = useQuery(GET_CHATS_DATA, {
    variables: {
      showArchived,
    },
    fetchPolicy: "network-only",
    pollInterval: 60000,
  });

  if (error) {
    console.log("error fetching chats", error);
    captureException(error);
  }

  //console.log("data", data);

  const onShowArchivedToggle = () => {
    setMessagesMenuOpened(false);
    setShowArchived((prevState) => !prevState);
  };

  useEffect(() => {
    localStorage.setItem("show-archived-chats", JSON.stringify(showArchived));
  }, [showArchived]);

  const onMessagesMenuClick = () => {
    setMessagesMenuOpened((prevState) => !prevState);
  };

  const closeMessagesMenu = () => {
    setMessagesMenuOpened(false);
  };

  return (
    <Messages
      data={data}
      messagesMenuOpened={messagesMenuOpened}
      onMessagesMenuClick={onMessagesMenuClick}
      onShowArchivedToggle={onShowArchivedToggle}
      showArchived={showArchived}
      closeMessagesMenu={closeMessagesMenu}
    />
  );
};
