import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import globalConstants from "constants/globalConstants";
import * as css from "styles/CssVariables.js";
import DesktopMenu from "./menu/DesktopMenu";
import HidingMenu from "./menu/HidingMenu";
import Toolbox from "./menu/Toolbox";
import SideContentBox from "./side/SideContentBox";
import SideContestBox from "./side/SideContestBox";

const Layout = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;

const MobileLayout = styled.div`
  width: 100%;
  padding-bottom: 90px;

  display: flex;
  flex-direction: column;
`;

const LeftColumn = styled.div``;

const RightColumn = styled.div``;

const SideColumns = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

export default ({ children, sideContent, hideContest, noColumns }) => {
  if (noColumns) return <>{children}</>;

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  const LayoutProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      unreadMessages: layout.unreadMessages,
    })
  );

  const { unreadMessages } = useSelector(LayoutProperties);

  return (
    <>
      {!tabletScreen && (
        <Layout>
          <LeftColumn>
            <DesktopMenu unreadMessages={unreadMessages} />
          </LeftColumn>
          {children}
          <RightColumn>
            {sideContent ? (
              sideContent
            ) : (
              <SideColumns>
                <SideContentBox />
                {!hideContest && <SideContestBox />}
              </SideColumns>
            )}
          </RightColumn>
        </Layout>
      )}
      {tabletScreen && (
        <MobileLayout>
          {children}
          {sideContent && sideContent}
          <HidingMenu unreadMessages={unreadMessages} />
          <Toolbox unreadMessages={unreadMessages} />
        </MobileLayout>
      )}
    </>
  );
};
