import { useEffect, useState } from "react";
import {
  gql,
  useQuery,
  useMutation,
  useSubscription,
  useLazyQuery,
} from "@apollo/client";
import { useSearchParams, useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { updateChatEnabled, setDisabledModal } from "store/actions";

import Chat from "./Chat";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const chatId = searchParams.get("id") ? searchParams.get("id") : null;

  const shardNumber = localStorage.getItem("sn");

  const [unreadMessages, setUnreadMessages] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [chatMenuOpened, setChatMenuOpened] = useState(false);
  const [blockingUser, setBlockingUser] = useState(false);
  const [archived, setArchived] = useState(false);
  // const [disabledModal, setDisabledModal] = useState(false);
  const [coinsEarned, setCoinsEarned] = useState(0);
  const [isTeamChat, setIsTeamChat] = useState(false);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      chatEnabled: user.chatEnabled,
    })
  );

  const { chatEnabled } = useSelector(UserProperties);

  // Fetch data
  const GET_CHAT_DATA = gql`
    query GetChatData($id: ID!) {
      chat(id: $id) {
        id
        username
        profileImage
        partnerShardNumber
        partnerChatId
        partnerReaction
        blocking
        messages {
          id
          created_at
          chat_id
          message
          read
          post_snapshot_id
          image
        }
        hidden
        referral_chat
        sponsor_chat
      }
    }
  `;

  const { subscribeToMore, data, error, refetch } = useQuery(GET_CHAT_DATA, {
    variables: {
      id: chatId,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error fetching chat", error);
    captureException(error);
    navigate("/messages");
  }

  //console.log("data", data);

  useEffect(() => {
    if (!chatId) {
      // missing Chat ID
      navigate("/messages");
    }

    if (data) {
      // receved empty Chat data
      if (!data.chat.username) {
        navigate("/messages");
      }

      // update blocking user state
      setBlockingUser(data.chat.blocking);

      // update archived chat state
      setArchived(data.chat.hidden);

      // set isTeamChat
      if (data.chat.referral_chat || data.chat.sponsor_chat) {
        setIsTeamChat(true);
      }
    }
  }, [data]);

  // Check if chat is enabled
  const CHECK_CHAT_ENABLED = gql`
    mutation CheckChatEnabled {
      checkChatEnabled {
        enabled
        coinsEarned
      }
    }
  `;

  const [checkChatEnabled] = useMutation(CHECK_CHAT_ENABLED);

  const checkEnabled = async () => {
    // skip if it's a Team chat
    if (isTeamChat) return;

    try {
      const checkResult = await checkChatEnabled();

      if (checkResult.data.checkChatEnabled.enabled) {
        dispatch(updateChatEnabled(true));
      } else {
        dispatch(setDisabledModal(true));
        setCoinsEarned(checkResult.data.checkChatEnabled.coinsEarned);
      }
    } catch (error) {
      captureException(error);
      console.log("Check chat enabled error", error);
    }
  };

  // mark Messages as read on input focus
  const MARK_AS_READ = gql`
    mutation MarkAsRead($partnerChatId: ID!, $shardNumber: Int!, $chatId: ID!) {
      markAsRead(
        partnerChatId: $partnerChatId
        shardNumber: $shardNumber
        chatId: $chatId
      ) {
        resultCode
      }
    }
  `;

  const [markAsRead] = useMutation(MARK_AS_READ);

  const onInputFocus = async () => {
    if (!chatEnabled) {
      checkEnabled();
    }

    if (!data || !unreadMessages) return;

    try {
      await markAsRead({
        variables: {
          partnerChatId: data.chat.partnerChatId,
          shardNumber: data.chat.partnerShardNumber,
          chatId,
        },
      });

      setUnreadMessages(false);
    } catch (error) {
      captureException(error);
      console.log("Marking as read error", error);
    }
  };

  // manage Subscription to new/updated messages
  const partnerDbShard = "s" + data?.chat.partnerShardNumber;

  const MESSAGES_SUBSCRIPTION = gql`
    subscription onNewMessage($partnerChatId: uuid!, $timeLimit: timestamptz!) {
      ${partnerDbShard} {
        messages(
          where: {
            chat_id: { _eq: $partnerChatId }
            created_at: { _gt: $timeLimit }
            blocked: { _eq: false }
          }
        ) {
          id
          created_at
          message
          post_snapshot_id
          image
        }
      }
    }
  `;

  const dbShard = "s" + shardNumber;
  const timeLimit = new Date();

  const READ_SUBSCRIPTION = gql`
    subscription onMessageUpdate($chatId: uuid!) {
      ${dbShard} {
        messages(
          where: { chat_id: { _eq: $chatId }, read: { _eq: true } }
          order_by: {created_at: desc}
          limit: 1
        ) {
          id
          created_at
          read
        }
      }
    }
  `;

  const readTimeLimit = new Date();
  readTimeLimit.setDate(readTimeLimit.getDate() - 2);

  const { data: readData } = useSubscription(READ_SUBSCRIPTION, {
    variables: { chatId },
  });

  useEffect(() => {
    if (readData && sentMessage) {
      refetch();
    }
  }, [readData]);

  // update sentMessage state to start refetching on readData change
  const onMessageSent = () => {
    setSentMessage(true);
  };

  // handle chat menu
  const onChatMenuClick = () => {
    setChatMenuOpened((prevState) => !prevState);
  };

  const closeChatMenu = () => {
    setChatMenuOpened(false);
  };

  // handle blocking/unblocking User
  const BLOCK_USER = gql`
    mutation BlockUser($chatId: ID!, $block: Boolean!) {
      blockUser(chatId: $chatId, block: $block) {
        blocking
      }
    }
  `;

  const [blockUser] = useMutation(BLOCK_USER);

  const onBlockUser = async () => {
    const block = !blockingUser;

    try {
      closeChatMenu();

      const blockResult = await blockUser({
        variables: {
          chatId,
          block,
        },
      });

      if (blockResult.data.blockUser) {
        refetch();
      }
    } catch (error) {
      captureException(error);
      console.log("Blocking user error", error);
    }
  };

  // handle archive chat
  const ARCHIVE_CHAT = gql`
    mutation ArchiveChat($chatId: ID!, $archive: Boolean!) {
      archiveChat(chatId: $chatId, archive: $archive) {
        hidden
      }
    }
  `;

  const [archiveChat] = useMutation(ARCHIVE_CHAT);

  const onArchiveChat = async () => {
    const archive = !archived;

    try {
      closeChatMenu();

      const archiveResult = await archiveChat({
        variables: {
          chatId,
          archive,
        },
      });

      if (archiveResult.data.archiveChat) {
        setArchived(archiveResult.data.archiveChat.hidden);

        if (archiveResult.data.archiveChat.hidden) {
          navigate("/messages");
        }
      }
    } catch (error) {
      captureException(error);
      console.log("Archive chat error", error);
    }
  };

  return (
    <Chat
      data={data}
      chatId={chatId}
      onInputFocus={onInputFocus}
      onMessageSent={onMessageSent}
      partnerShardNumber={data?.chat.partnerShardNumber}
      chatMenuOpened={chatMenuOpened}
      onChatMenuClick={onChatMenuClick}
      closeChatMenu={closeChatMenu}
      onBlockUser={onBlockUser}
      blockingUser={blockingUser}
      onArchiveChat={onArchiveChat}
      archived={archived}
      isReferral={data?.chat.referral_chat}
      isSponsor={data?.chat.sponsor_chat}
      setDisabledModal={setDisabledModal}
      checkEnabled={checkEnabled}
      coinsEarned={coinsEarned}
      isTeamChat={isTeamChat}
      subscribeToNewMessages={() =>
        subscribeToMore({
          document: MESSAGES_SUBSCRIPTION,
          variables: {
            partnerChatId: data.chat.partnerChatId,
            timeLimit,
          },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;

            if (subscriptionData.data[partnerDbShard].messages.length === 0) {
              return prev;
            }

            const newMessages = [...prev.chat.messages];

            for (const newMessage of subscriptionData.data[partnerDbShard]
              .messages) {
              const existingMessage = prev.chat.messages.find((message) => {
                return message.id === newMessage.id;
              });

              if (existingMessage) continue;

              const { created_at, id, message, post_snapshot_id, image } =
                newMessage;

              newMessages.push({
                chat_id: data.chat.partnerChatId,
                created_at,
                id,
                message,
                post_snapshot_id,
                image,
                read: false,
                __typename: "Message",
              });
            }

            setUnreadMessages(true);

            let toReturn = {
              chat: {
                id: prev.chat.id,
                messages: newMessages,
                partnerChatId: prev.chat.partnerChatId,
                partnerReaction: prev.chat.partnerReaction,
                partnerShardNumber: prev.chat.partnerShardNumber,
                profileImage: prev.chat.profileImage,
                username: prev.chat.username,
                blocking: prev.chat.blocking,
                __typename: "ChatPayload",
              },
            };

            return toReturn;
          },
        })
      }
    />
  );
};
