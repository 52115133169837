import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import axios from "axios";
import { isURL } from "validator";
import { useNavigate, useBlocker } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { captureException } from "@sentry/react";

import globalConstants from "constants/globalConstants";
import { BACKEND_SERVER_URL } from "constants";
import { POST_TEMPLATES } from "constants/postTemplates";

import { getRandomArrayItem, removeExtraEmptyLines } from "modules/functions";

// Actions
import { closeHidingMenu, updateProgressPosts } from "store/actions";

import CreatePostTutorial from "./CreatePostTutorial";

const CreatePostTutorialContainer = styled.div`
  margin: 0px auto 0;
  width: 100%;
  max-width: 700px;

  padding: 0 10px;
  box-sizing: border-box;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin: 30px auto 0;
  }
`;

export default () => {
  const pickerRef = useRef();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [allCountries, setAllCountries] = useState([]);
  const [step, setStep] = useState(1);
  const [text, setText] = useState("");
  const [image, setImage] = useState(null);
  const [imageWarning, setImageWarning] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [pickerOpened, setPickerOpened] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [textEdited, setTextEdited] = useState(false);
  const [confirmTemplate, setConfirmTemplate] = useState(false);
  const [formEdited, setFormEdited] = useState(false);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      hasPost: user.hasPost,
    })
  );

  const { hasPost } = useSelector(UserProperties);

  const onTextInputChange = (e) => {
    setText(e.target.value);
    if (!textEdited) setTextEdited(true);
    if (!formEdited) setFormEdited(true);
  };

  const onSmileyClick = () => {
    setPickerOpened(!pickerOpened);
  };

  const onEmojiClick = (emoji) => {
    setText(text + emoji.emoji);

    if (!textEdited) setTextEdited(true);
    if (!formEdited) setFormEdited(true);
  };

  function usePickerOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPickerOpened(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onFirstContinueClick = () => {
    setWarnings([]);

    if (text.length < 20) {
      setWarnings(["text"]);
      return;
    }

    setStep(2);
  };

  const onSecondContinueClick = () => {
    setWarnings([]);

    if (image) setStep(3);
    else setStep(4);
  };

  const onThirdContinueClick = () => {
    setWarnings([]);

    if (
      imageLink.length > 0 &&
      !isURL(imageLink, {
        protocols: ["http", "https"],
        require_valid_protocol: true,
        require_protocol: true,
      })
    ) {
      setWarnings(["imageLink"]);
      return;
    }
    setStep(4);
  };

  const onClickBack = () => {
    if (step === 4 && !image) {
      setStep(2);
      return;
    }

    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [step]);

  // handle post image cloud upload
  const axiosApi = axios.create({
    baseURL: BACKEND_SERVER_URL,
  });

  async function postFile(url, data, config = {}) {
    return axiosApi
      .post(url, data, { ...config })
      .then((response) => response.data);
  }

  const onImageInputChange = async (e) => {
    try {
      if (e.target.files[0]) {
        const maxFileSize = 6000;

        if (e.target.files[0].size > maxFileSize * 1000) {
          setImageWarning("tooLargeFile");
          return;
        }

        setImage(null);
        setImageWarning(null);

        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        const result = await postFile("/upload/post-image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        });

        if (result.error) {
          console.log("File upload failed!");
          return;
        }

        if (result.fileName) {
          setImage(result.fileName);
          if (!formEdited) setFormEdited(true);
        }
      }
    } catch (error) {
      captureException(error);
      console.log("onImageInputChange error", error);
    }
  };

  const removeImage = () => {
    setImage(null);
    if (!formEdited) setFormEdited(true);
  };

  // handle Image link input
  const onImageLinkInputChange = (e) => {
    setImageLink(e.target.value);
    if (!formEdited) setFormEdited(true);
  };

  // get User profile data
  const GET_USER_PROFILE_DATA = gql`
    query UserProfileData {
      userProfileData {
        id
        username
        profile_image
      }
    }
  `;

  const { loading: loadingUserProfile, data: userProfileData } = useQuery(
    GET_USER_PROFILE_DATA
  );

  // load Vountries data
  const GET_COUNTRIES = gql`
    query CountriesData {
      countriesData {
        id
        country
        cpi
      }
    }
  `;

  const { loading: loadingCountries, data: countriesData } = useQuery(
    GET_COUNTRIES,
    {
      fetchPolicy: "network-only",
    }
  );

  // save all countries to state
  useEffect(() => {
    if (!loadingCountries && countriesData) {
      const countries = countriesData.countriesData.map(
        (country) => country.country
      );

      setAllCountries(countries);
    }
  }, [loadingCountries]);

  // handle post submit
  const SUBMIT_POST = gql`
    mutation SubmitPost(
      $postId: ID
      $text: String!
      $image: String
      $imageLink: String
      $countries: [String!]!
      $cpi: Int
      $name: String!
      $run: Boolean!
      $limit: Int
    ) {
      submitPost(
        postId: $postId
        text: $text
        image: $image
        imageLink: $imageLink
        countries: $countries
        cpi: $cpi
        name: $name
        run: $run
        limit: $limit
      ) {
        resultCode
      }
    }
  `;

  const [submitPost] = useMutation(SUBMIT_POST);

  const onPostSubmit = async () => {
    try {
      setSubmitError(null);

      if (submitLoading) return;

      let newWarnings = [];

      if (text.length < 20) newWarnings.push("text");
      if (
        imageLink.length > 0 &&
        !isURL(imageLink, {
          protocols: ["http", "https"],
          require_valid_protocol: true,
          require_protocol: true,
        })
      )
        newWarnings.push("imageLink");
      if (imageLink.length > 0 && !image) newWarnings.push("imageLinkNoImage");

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }

      setWarnings([]);
      setSubmitLoading(true);
      setFormEdited(false);

      const result = await submitPost({
        variables: {
          postId: null,
          text: removeExtraEmptyLines(text.trim()),
          image,
          imageLink,
          countries: allCountries,
          cpi: null,
          name: "",
          run: true,
          limit: 0,
        },
      });

      setSubmitLoading(false);

      if (result.data.submitPost.resultCode !== 0) {
        const errorText =
          "Failed to create the post. Please try again later or contact support.";

        setSubmitError(errorText);

        return;
      }

      if (!hasPost) dispatch(updateProgressPosts(true));

      navigate("/home");
    } catch (error) {
      captureException(error);
      console.log("Submit post error", error);
      setSubmitLoading(false);
      setSubmitError(
        "Failed to create or edit the post. Please try again later or contact support."
      );
    }
  };

  // handle Post template generation
  const addTemplateText = () => {
    const randomTemplate = getRandomArrayItem(POST_TEMPLATES);

    setText(randomTemplate);
    setTextEdited(false);
    if (!formEdited) setFormEdited(true);

    if (confirmTemplate) setConfirmTemplate(false);
  };

  const getPostTemplate = () => {
    if (textEdited && text.length !== 0) setConfirmTemplate(true);
    else addTemplateText();
  };

  const closeConfirmModal = () => {
    setConfirmTemplate(false);
  };

  // handle Form leave blocker
  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      formEdited && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      dispatch(closeHidingMenu());
    }
  }, [blocker.state]);

  return (
    <CreatePostTutorialContainer>
      <CreatePostTutorial
        step={step}
        text={text}
        onTextInputChange={onTextInputChange}
        onSmileyClick={onSmileyClick}
        pickerOpened={pickerOpened}
        onEmojiClick={onEmojiClick}
        pickerRef={pickerRef}
        usePickerOutsideClick={usePickerOutsideClick}
        onImageInputChange={onImageInputChange}
        image={image}
        onImageLinkInputChange={onImageLinkInputChange}
        imageLink={imageLink}
        warnings={warnings}
        loading={loadingUserProfile}
        removeImage={removeImage}
        imageWarning={imageWarning}
        onPostSubmit={onPostSubmit}
        submitLoading={submitLoading}
        submitError={submitError}
        userProfileData={userProfileData?.userProfileData}
        getPostTemplate={getPostTemplate}
        confirmTemplate={confirmTemplate}
        closeConfirmModal={closeConfirmModal}
        addTemplateText={addTemplateText}
        blockModal={blocker.state === "blocked"}
        closeBlockModal={() => blocker.reset()}
        confirmLeaveForm={() => blocker.proceed()}
        onFirstContinueClick={onFirstContinueClick}
        onClickBack={onClickBack}
        onSecondContinueClick={onSecondContinueClick}
        onThirdContinueClick={onThirdContinueClick}
      />
    </CreatePostTutorialContainer>
  );
};
