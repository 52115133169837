import { useEffect } from "react";
import styled from "styled-components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { dateFormattedUTC, numberWithCommas } from "modules/functions";
import Layout from "components/Layout";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import PageTitle from "components/text/PageTitle";
import SectionTitle from "components/text/SectionTitle";
import ButtonRoundIcon from "components/buttons/ButtonRoundIcon";
import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import Tip from "components/other/Tip";
import RankingBox from "./RankingBox";

import contestTipBoxIcon from "assets/img/icons/achievement.svg";
import pointsIcon from "assets/img/icons/points-icon.svg";
import dollarIcon from "assets/img/icons/dollar-icon.svg";
import calendarIcon from "assets/img/icons/calendar-icon.svg";
import bulbIcon from "assets/img/icons/tip-bulb.svg";
import discoverIcon from "assets/img/icons/menu-discover-white.svg";
import referralsIcon from "assets/img/icons/menu-referrals.svg";
import rightUpArrowIcon from "assets/img/icons/icons8-right-up-arrow.svg";
import rightUpArrowWhiteIcon from "assets/img/icons/icons8-right-up-arrow-white.svg";
import circledOneYellowIcon from "assets/img/icons/icons8-circled-1-yellow.svg";
import circledTwoGrenIcon from "assets/img/icons/icons8-circled-2-green.svg";
import circledThreeIndigoIcon from "assets/img/icons/icons8-circled-3-indigo.svg";
import blockIcon from "assets/img/icons/icons8-unavailable.svg";

const Contest = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const ContestBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_XLARGE};
`;

const ContestBoxTop = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 35px 55px;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }
`;

const ContestBoxTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 1;
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  line-height: 125%;
`;

const ContestBoxTopRight = styled.div`
  padding-top: 20px;
  padding-right: 1vw;

  display: flex;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 0;

    align-self: center;
  }
`;

const ContestImage = styled.div`
  max-width: 90px;
`;

const CollectInfoBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_SECONDARY};
  margin-top: 8px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  gap: 8px;
`;

const CollectInfoBoxIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const CollectInfoBoxText = styled.div``;

const OngoingContest = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const OngoingColumns = styled.div`
  display: flex;
  gap: ${css.VERTICAL_BOX_MARGIN} ${css.VERTICAL_BOX_MARGIN_LARGE};
  flex-wrap: wrap;
`;

const OngoingColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DataTitle = styled.div`
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const DataIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataIcon = styled.div`
  width: ${(props) => (props.width ? props.width : "20px")};
  min-width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.width ? props.width : "20px")};
`;

const DataText = styled.div`
  white-space: nowrap;
`;

const ColumnContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RankHigherInfoBox = styled.div`
  width: 278px;
  min-width: 278px;
  padding: 19px 20px 18px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  flex: 1;
`;

const CollectTip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CollectTipIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const CollectTipText = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const RankHigherInfoRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const RankHigherInfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const RankHigherInfoIconBox = styled.div``;

const RankHigherInfoIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const RankHigherInfoText = styled.div`
  font-size: 15px;
`;

const RankHigherInfoButtonWrap = styled.div``;

const UpcomingContest = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const UpcomingSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const UpcomingDataColumns = styled.div`
  display: flex;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM} ${css.VERTICAL_BOX_MARGIN_LARGE};
  flex-wrap: wrap;
`;

const UpcomingStartingInfo = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_SECONDARY};
  margin-top: 8px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  gap: 8px;
`;

const UpcomingStartingInfoIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const UpcomingStartingInfoText = styled.div``;

const PayoutButtonWrap = styled.div`
  display: flex;
`;

const PrizesResultBoxes = styled.div`
  max-width: ${css.MIDDLE_COLUMN_STANDARD_WIDTH};

  display: flex;
  flex-direction: row;
  gap: 0 ${css.VERTICAL_BOX_MARGIN_LARGE};

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const ContestPrizes = styled.div``;

const PrizesBox = styled.div`
  width: 100%;
  max-width: 250px;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const PrizesGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 26px repeat(8, 1fr);
  row-gap: 10px;
  column-gap: 25px;
`;

const PrizesLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: ${(props) => (props.center ? "center" : "left")};
`;

const PrizesValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  gap: 5px;
`;

const PrizesValueIcon = styled.div`
  width: ${(props) => (props.width ? props.width : "20px")};
  min-width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.width ? props.width : "20px")};
`;

const PrizesValueText = styled.div`
  white-space: nowrap;
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ResultsBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
  flex: 1;
`;

const TableWrap = styled.div`
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: -5px;

  overflow-x: auto;
`;

const ResultsTable = styled.table`
  width: 100%;
  min-width: 350px;
`;

const ResultsTableHead = styled.thead``;

const ResultsLabel = styled.th`
  padding-bottom: 12px;

  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

const ResultsTableBody = styled.tbody``;

const ResultsRow = styled.tr``;

const ResultsCell = styled.td`
  padding: 4px 0;
`;

const ResultsCellValue = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ResultsCellValueIcon = styled.div`
  width: ${(props) => (props.width ? props.width : "20px")};
  min-width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.width ? props.width : "20px")};
`;

const ResultsCellValueText = styled.div`
  white-space: nowrap;
`;

const NoResults = styled.div`
  width: 100%;
  margin-top: 105px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN};

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 20px;
  }
`;

const NoResultsIcon = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const NoResultsText = styled.div``;

export default ({ data }) => {
  // set page Title
  useEffect(() => {
    document.title = "Contest | Scalelup";
  }, []);

  const navigate = useNavigate();

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  let ongoingPrizePool = 0;
  let ongoingStartsDate;
  let ongoingEndsDate;
  let ongoingPoints = 0;
  let rankingGroup = 4;
  let upcomingPrizePool = 0;
  let upcomingStartsDate;
  let upcomingEndsDate;
  let ongoingPrizesObject = {};
  let contestResults = [];

  if (data) {
    const {
      ongoingPrizes,
      ongoingStarts,
      ongoingEnds,
      upcomingPrizes,
      upcomingStarts,
      upcomingEnds,
    } = data.contestData;

    for (const prize in ongoingPrizes) {
      ongoingPrizePool += ongoingPrizes[prize];
    }

    ongoingStartsDate = dateFormattedUTC(new Date(ongoingStarts), false);
    ongoingEndsDate = dateFormattedUTC(new Date(ongoingEnds), false);

    ongoingPoints = data.contestData.ongoingPoints;
    rankingGroup = data.contestData.rankingGroup;

    for (const prize in upcomingPrizes) {
      upcomingPrizePool += upcomingPrizes[prize];
    }

    upcomingStartsDate = dateFormattedUTC(new Date(upcomingStarts), false);
    upcomingEndsDate = dateFormattedUTC(new Date(upcomingEnds), false);

    ongoingPrizesObject = ongoingPrizes;

    contestResults = data.contestData.contestResults;
  }

  const resultsList = contestResults.map((result) => {
    const contestTerm =
      dateFormattedUTC(new Date(result.started_at), false) +
      " - " +
      dateFormattedUTC(new Date(result.ended_at), false);

    return (
      <ResultsRow key={contestTerm}>
        <ResultsCell>
          <ResultsCellValue>
            <ResultsCellValueText>{contestTerm}</ResultsCellValueText>
          </ResultsCellValue>
        </ResultsCell>
        <ResultsCell>
          <ResultsCellValue>
            <ResultsCellValueIcon>
              <img src={pointsIcon} style={{ width: "100%" }} />
            </ResultsCellValueIcon>
            <ResultsCellValueText>{result.points}</ResultsCellValueText>
          </ResultsCellValue>
        </ResultsCell>
        <ResultsCell>
          <ResultsCellValue>
            <ResultsCellValueText>{result.rank}</ResultsCellValueText>
          </ResultsCellValue>
        </ResultsCell>
        <ResultsCell>
          <ResultsCellValue>
            <ResultsCellValueIcon>
              <img src={dollarIcon} style={{ width: "100%" }} />
            </ResultsCellValueIcon>
            <ResultsCellValueText>{result.prize}</ResultsCellValueText>
          </ResultsCellValue>
        </ResultsCell>
      </ResultsRow>
    );
  });

  return (
    <Layout hideContest={1}>
      <Contest>
        <PageTitle>Contest</PageTitle>
        {data && (
          <>
            <ContestBox>
              <ContestBoxTop>
                <ContestBoxTopLeft>
                  <BoxTitle>
                    Win a Cash Prize With the Scalelup Contest
                  </BoxTitle>
                  <Text>
                    Be an active user and grab a valuable cash prize. Every
                    week, we give away over $2000 USD to users who earn the most
                    coins by viewing posts and referring new users.
                  </Text>
                  <CollectInfoBox>
                    <CollectInfoBoxIcon>
                      <img src={pointsIcon} style={{ width: "100%" }} />
                    </CollectInfoBoxIcon>
                    <CollectInfoBoxText>
                      Collect one contest point for each coin you earn
                    </CollectInfoBoxText>
                  </CollectInfoBox>
                </ContestBoxTopLeft>
                <ContestBoxTopRight>
                  <ContestImage>
                    <img
                      src={contestTipBoxIcon}
                      alt="Referrals"
                      style={{ width: "100%" }}
                    />
                  </ContestImage>
                </ContestBoxTopRight>
              </ContestBoxTop>
              <OngoingContest>
                <BoxTitle>Ongoing Contest</BoxTitle>
                <OngoingColumns>
                  <OngoingColumn>
                    <Data>
                      <DataTitle>Prize Pool</DataTitle>
                      <DataRow>
                        <DataIconBox>
                          <DataIcon>
                            <img src={dollarIcon} style={{ width: "100%" }} />
                          </DataIcon>
                        </DataIconBox>
                        <DataText>
                          {numberWithCommas(ongoingPrizePool)} USD
                        </DataText>
                      </DataRow>
                    </Data>
                    <Data>
                      <DataTitle>Term</DataTitle>
                      <DataRow>
                        <DataIconBox>
                          <DataIcon width="18px">
                            <img src={calendarIcon} style={{ width: "100%" }} />
                          </DataIcon>
                        </DataIconBox>
                        <DataText>
                          {ongoingStartsDate} - {ongoingEndsDate}
                        </DataText>
                      </DataRow>
                    </Data>
                    <Data>
                      <DataTitle>
                        Points
                        <Tip tip="The number of points you have accumulated so far during this contest. Data is updated every hour." />
                      </DataTitle>
                      <DataRow>
                        <DataIconBox>
                          <DataIcon>
                            <img src={pointsIcon} style={{ width: "100%" }} />
                          </DataIcon>
                        </DataIconBox>
                        <DataText>{numberWithCommas(ongoingPoints)}</DataText>
                      </DataRow>
                    </Data>
                  </OngoingColumn>
                  <OngoingColumn>
                    <ColumnContentWrap>
                      <DataTitle>
                        Your Ranking
                        <Tip tip="Your current position in the contest. Data is updated every hour." />
                      </DataTitle>
                      <RankingBox
                        rankingGroup={rankingGroup}
                        profileImage={profileData.profileImage}
                      />
                    </ColumnContentWrap>
                  </OngoingColumn>
                  <OngoingColumn>
                    <ColumnContentWrap>
                      <DataTitle>How to Rank Higher</DataTitle>
                      <RankHigherInfoBox>
                        <CollectTip>
                          <CollectTipIcon>
                            <img src={bulbIcon} style={{ width: "100%" }} />
                          </CollectTipIcon>
                          <CollectTipText>
                            Collect points by earning coins
                          </CollectTipText>
                        </CollectTip>
                        <RankHigherInfoRows>
                          <RankHigherInfoRow>
                            <RankHigherInfoIconBox>
                              <RankHigherInfoIcon>
                                <img
                                  src={discoverIcon}
                                  style={{ width: "100%" }}
                                />
                              </RankHigherInfoIcon>
                            </RankHigherInfoIconBox>
                            <RankHigherInfoText>
                              Find coins by exploring opportunities
                            </RankHigherInfoText>
                            <RankHigherInfoButtonWrap>
                              <ButtonRoundIcon
                                icon={rightUpArrowIcon}
                                hoverIcon={rightUpArrowWhiteIcon}
                                background={css.BRAND_YELLOW}
                                hoverBackground={css.BRAND_GREEN}
                                onClick={() => navigate("/discover")}
                              />
                            </RankHigherInfoButtonWrap>
                          </RankHigherInfoRow>
                          <RankHigherInfoRow>
                            <RankHigherInfoIconBox>
                              <RankHigherInfoIcon>
                                <img
                                  src={referralsIcon}
                                  style={{ width: "100%" }}
                                />
                              </RankHigherInfoIcon>
                            </RankHigherInfoIconBox>
                            <RankHigherInfoText>
                              Earn a reward by referring new users
                            </RankHigherInfoText>
                            <RankHigherInfoButtonWrap>
                              <ButtonRoundIcon
                                icon={rightUpArrowIcon}
                                hoverIcon={rightUpArrowWhiteIcon}
                                background={css.BRAND_YELLOW}
                                hoverBackground={css.BRAND_GREEN}
                                onClick={() => navigate("/referrals")}
                              />
                            </RankHigherInfoButtonWrap>
                          </RankHigherInfoRow>
                        </RankHigherInfoRows>
                      </RankHigherInfoBox>
                    </ColumnContentWrap>
                  </OngoingColumn>
                </OngoingColumns>
              </OngoingContest>
              {data.contestData.upcomingPrizes && (
                <UpcomingContest>
                  <BoxTitle>Upcoming Contest</BoxTitle>
                  <UpcomingSections>
                    <UpcomingDataColumns>
                      <Data>
                        <DataTitle>Prize Pool</DataTitle>
                        <DataRow>
                          <DataIconBox>
                            <DataIcon>
                              <img src={dollarIcon} style={{ width: "100%" }} />
                            </DataIcon>
                          </DataIconBox>
                          <DataText>
                            {numberWithCommas(upcomingPrizePool)} USD
                          </DataText>
                        </DataRow>
                      </Data>
                      <Data>
                        <DataTitle>Term</DataTitle>
                        <DataRow>
                          <DataIconBox>
                            <DataIcon width="18px">
                              <img
                                src={calendarIcon}
                                style={{ width: "100%" }}
                              />
                            </DataIcon>
                          </DataIconBox>
                          <DataText>
                            {upcomingStartsDate} - {upcomingEndsDate}
                          </DataText>
                        </DataRow>
                      </Data>
                      <Data>
                        <DataTitle>
                          Starting Points
                          <Tip tip="The amount of points at the start of the contest always equals 10% of the points you earned in the previous contest." />
                        </DataTitle>
                        <DataRow>
                          <DataIconBox>
                            <DataIcon>
                              <img src={pointsIcon} style={{ width: "100%" }} />
                            </DataIcon>
                          </DataIconBox>
                          <DataText>
                            {numberWithCommas(Math.floor(ongoingPoints / 10))}
                          </DataText>
                        </DataRow>
                      </Data>
                    </UpcomingDataColumns>
                    <UpcomingStartingInfo>
                      <UpcomingStartingInfoIcon>
                        <img src={bulbIcon} style={{ width: "100%" }} />
                      </UpcomingStartingInfoIcon>
                      <UpcomingStartingInfoText>
                        The starting point balance for the contest is always
                        equal to 10% of the points collected in the previous
                        contest.
                      </UpcomingStartingInfoText>
                    </UpcomingStartingInfo>
                  </UpcomingSections>
                </UpcomingContest>
              )}
              <PayoutButtonWrap>
                <ButtonPrimarySmall onClick={() => navigate("/settings")}>
                  Payout Setup
                </ButtonPrimarySmall>
              </PayoutButtonWrap>
            </ContestBox>
            <PrizesResultBoxes>
              <ContestPrizes>
                <SectionTitle>Contest Prizes</SectionTitle>
                <PrizesBox>
                  <PrizesGrid>
                    <PrizesLabel center={1}>Rank</PrizesLabel>
                    <PrizesLabel>Prize</PrizesLabel>
                    <PrizesValue center={1}>
                      <PrizesValueIcon width="22px">
                        <img
                          src={circledOneYellowIcon}
                          style={{ width: "100%" }}
                        />
                      </PrizesValueIcon>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {numberWithCommas(ongoingPrizesObject[1])} USD
                      </PrizesValueText>
                    </PrizesValue>
                    <PrizesValue center={1}>
                      <PrizesValueIcon width="22px">
                        <img
                          src={circledTwoGrenIcon}
                          style={{ width: "100%" }}
                        />
                      </PrizesValueIcon>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {ongoingPrizesObject[2]} USD
                      </PrizesValueText>
                    </PrizesValue>
                    <PrizesValue center={1}>
                      <PrizesValueIcon width="22px">
                        <img
                          src={circledThreeIndigoIcon}
                          style={{ width: "100%" }}
                        />
                      </PrizesValueIcon>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {ongoingPrizesObject[3]} USD
                      </PrizesValueText>
                    </PrizesValue>
                    <PrizesValue center={1}>
                      <PrizesValueText>4</PrizesValueText>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {ongoingPrizesObject[4]} USD
                      </PrizesValueText>
                    </PrizesValue>
                    <PrizesValue center={1}>
                      <PrizesValueText>5</PrizesValueText>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {ongoingPrizesObject[5]} USD
                      </PrizesValueText>
                    </PrizesValue>
                    <PrizesValue center={1}>
                      <PrizesValueText>6 - 10</PrizesValueText>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {ongoingPrizesObject[6]} USD
                      </PrizesValueText>
                    </PrizesValue>
                    <PrizesValue center={1}>
                      <PrizesValueText>11 - 20</PrizesValueText>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {ongoingPrizesObject[11]} USD
                      </PrizesValueText>
                    </PrizesValue>
                    <PrizesValue center={1}>
                      <PrizesValueText>21 - 30</PrizesValueText>
                    </PrizesValue>
                    <PrizesValue>
                      <PrizesValueIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </PrizesValueIcon>
                      <PrizesValueText>
                        {ongoingPrizesObject[21]} USD
                      </PrizesValueText>
                    </PrizesValue>
                  </PrizesGrid>
                </PrizesBox>
              </ContestPrizes>
              <Results>
                <SectionTitle>Your Previous Results</SectionTitle>
                <ResultsBox>
                  {resultsList.length > 0 && (
                    <TableWrap className="table-wrap">
                      <ResultsTable>
                        <ResultsTableHead>
                          <tr>
                            <ResultsLabel>Contest</ResultsLabel>
                            <ResultsLabel>Points</ResultsLabel>
                            <ResultsLabel>Rank</ResultsLabel>
                            <ResultsLabel>Prize</ResultsLabel>
                          </tr>
                        </ResultsTableHead>
                        <ResultsTableBody>{resultsList}</ResultsTableBody>
                      </ResultsTable>
                    </TableWrap>
                  )}
                  {resultsList.length === 0 && (
                    <NoResults>
                      <NoResultsIcon>
                        <img src={blockIcon} style={{ width: "100%" }} />
                      </NoResultsIcon>
                      <NoResultsText>No results yet</NoResultsText>
                    </NoResults>
                  )}
                </ResultsBox>
              </Results>
            </PrizesResultBoxes>
          </>
        )}
        {!data && (
          <LoadingBox>
            <BoxLoadingSpinner height="218px" />
          </LoadingBox>
        )}
      </Contest>
    </Layout>
  );
};
