import styled from "styled-components";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";

import crossIcon from "assets/img/icons/icons8-cross-black.svg";

const MessageBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  margin: ${(props) => (props.margin ? props.margin : 0)};

  background: ${css.BRAND_BLUSH};
  border-radius: ${css.BOX_RADIUS_PRIMARY};
  color: ${css.DARK_BLUE};

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  line-height: 1.3;
  font-weight: 400;

  flex: 1;
`;

export default ({ title, text, closeBox, margin }) => {
  return (
    <MessageBox margin={margin}>
      <Header>
        <Title>{title}</Title>
        <CloseIcon onClick={closeBox}>
          <img src={crossIcon} alt="Close" style={{ width: "100%" }} />
        </CloseIcon>
      </Header>
      <Text>{text}</Text>
    </MessageBox>
  );
};
