import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import {
  dateFormatted,
  getTimeAMPM,
  numberWithCommas,
} from "modules/functions";
import { AWS_PROFILE_IMAGES_URL, CHAT_ENABLED_COINS_LIMIT } from "constants";
import Layout from "components/Layout";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import Bubble from "./Bubble";
import MessageFormContainer from "./MessageFormContainer";
import ModalWrap from "components/other/ModalWrap";
import Backdrop from "components/other/Backdrop";
import PostPreviewContainer from "./PostPreviewContainer";
import BadgeTitle from "components/other/BadgeTitle";
import RoundActionIcon from "components/buttons/RoundActionIcon";
import ContextMenu from "components/menu/ContextMenu";
import TextLink from "components/text/TextLink";
import ModalWindow from "components/other/ModalWindow";
import ButtonRoundIcon from "components/buttons/ButtonRoundIcon";

import dotsEmptyIcon from "assets/img/icons/icons8-dots-empty.svg";
import dotsIcon from "assets/img/icons/icons8-dots.svg";
import blockIcon from "assets/img/icons/icons8-unavailable.svg";
import checkmarkIcon from "assets/img/icons/icons8-checkmark.svg";
import returnIcon from "assets/img/icons/return-icon.svg";
import archiveIcon from "assets/img/icons/icons8-box.svg";
import archiveFilledIcon from "assets/img/icons/icons8-box-filled.svg";
import tipIcon from "assets/img/icons/icons8-info.svg";
import profilePictureIcon from "assets/img/icons/icons8-profile-picture.svg";
import lockMessageIcon from "assets/img/icons/Inbox-Lock--Streamline-Core.svg";
import coinsWhiteIcon from "assets/img/icons/coins-white-icon.svg";
import bulbIcon from "assets/img/icons/tip-bulb.svg";
import discoverIcon from "assets/img/icons/menu-discover-white.svg";
import referralsIcon from "assets/img/icons/menu-referrals.svg";
import rightUpArrowIcon from "assets/img/icons/icons8-right-up-arrow.svg";
import rightUpArrowWhiteIcon from "assets/img/icons/icons8-right-up-arrow-white.svg";
import creditCardIcon from "assets/img/icons/Credit-Card-1--Streamline-Core.svg";

// Actions
import { setDisabledModal } from "store/actions";

const Chat = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  gap: ${css.BUTTONS_GAP_SMALL};
`;

const MenuWrap = styled.div`
  position: relative;
`;

const MenuIconBox = styled.div``;

const ChatMenu = styled.div`
  position: absolute;
  top: 42px;
  right: 0;

  transition: 0.2s;

  opacity: ${(props) => (props.opened ? 100 : 0)};
  pointer-events: ${(props) => (props.opened ? "auto" : "none")};
`;

const BackIconWrap = styled.div``;

const ChatTitle = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  margin-bottom: 16px;

  font-size: 36px;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 26px;
  }
`;

const TitleProfileImage = styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 28px;
  }
`;

const TitleUsername = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: end;

  @media (max-width: ${globalConstants.switchMobile}) {
    gap: 5px;
  }
`;

const ChatBoxWrap = styled.div`
  border-radius: ${css.BOX_RADIUS_PRIMARY};
  overflow: hidden;

  display: flex;
  align-items: flex-start;
`;

const ChatBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  height: calc(100vh - 224px);
  max-height: calc(100vh - 224px);
  min-height: 300px;

  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }

  @media (max-width: ${globalConstants.switchTablet}) {
    height: calc(100vh - 290px);
    max-height: calc(100vh - 290px);
  }
`;

const Messages = styled.div`
  width: 100%;
  padding-right: 10px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
  flex: 1;
`;

const MessageWrap = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ChatDate = styled.div`
  width: 100%;
  margin-top: 5px;

  text-align: center;
  font-size: 13px;
  color: ${css.TEXT_GREY};
`;

const PartnerMessage = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

const OwnMessage = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const ProfileImageWrap = styled.div`
  min-width: 32px;
`;

const ProfileImage = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
`;

const MessageSpacer = styled.div`
  min-width: 20px;
`;

const MessageFormWrap = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const ChatInfo = styled.div`
  width: 100%;
  max-width: 420px;

  font-size: 13px;
  color: ${css.TEXT_GREY};
  text-align: center;
  line-height: 125%;
`;

const BadgeWrap = styled.div`
  margin: 2px 0;

  align-self: center;
`;

const TeamInfo = styled.div`
  margin-bottom: 3px;

  display: flex;
  align-items: center;
  gap: 3px;
`;

const TeamBadge = styled.div`
  box-sizing: border-box;
  height: 16px;
  padding: 0 10px;
  min-width: auto;

  background: ${css.BRAND_INDIGO};
  border-radius: 19px;
  border: 0;

  font-size: 11px;
  color: #fff;
  white-space: nowrap;
  line-height: 1;
  font-weight: 300;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const TipIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;

  display: flex;

  &:hover {
    cursor: help;
  }
`;

const DisabledModalWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 550px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 2000;
`;

const DisabledWindowContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DisabledHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const DisabledHeadingIcon = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
`;

const DisabledHeadingText = styled.div`
  font-size: 24px;
`;

const DisabledText = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};

  text-align: center;
  line-height: 130%;
`;

const DisabledBox = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const DisabledBoxTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const DisabledBoxBar = styled.div`
  position: relative;
  height: 28px;
  width: 100%;
  max-width: 300px;

  overflow: hidden;

  border-radius: 14px;
  background: ${css.BRAND_INDIGO};
`;

const DisabledBoxBarProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.progresspercent}%;
  box-sizing: border-box;

  background: ${css.BRAND_GREEN};
`;

const DisabledBoxCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: help;
  }
`;

const DisabledBoxCounterIcon = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
`;

const DisabledBoxCounterText = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const DisabledEarnInfoBox = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  width: 278px;
  min-width: 278px;
  padding: 19px 20px 18px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  flex: 1;
`;

const CollectTip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CollectTipIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const CollectTipText = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const DisabledEarnInfoRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const DisabledEarnInfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const DisabledEarnInfoIconBox = styled.div``;

const DisabledEarnInfoIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const DisabledEarnInfoText = styled.div`
  font-size: 15px;
`;

const DisabledEarnInfoButtonWrap = styled.div`
  flex: 1;

  display: flex;
  justify-content: end;
`;

export default ({
  data,
  chatId,
  subscribeToNewMessages,
  onInputFocus,
  onMessageSent,
  partnerShardNumber,
  postId,
  onChatMenuClick,
  closeChatMenu,
  chatMenuOpened,
  onBlockUser,
  blockingUser,
  onArchiveChat,
  archived,
  userId,
  isReferral,
  isSponsor,
  checkEnabled,
  coinsEarned,
  isTeamChat,
}) => {
  const dispatch = useDispatch();
  const chatMessagesRef = useRef();
  const navigate = useNavigate();

  const shardNumber = localStorage.getItem("sn");

  const [postPreviewModal, setPostPreviewModal] = useState(false);
  const [postSnapshotId, setPostSnapshotId] = useState(null);
  const [postSnapshotShardNumber, setPostSnapshotShardNumber] = useState(null);

  const LayoutProperties = createSelector(
    (state) => state.Layout,
    (user) => ({
      disabledModal: user.disabledModal,
    })
  );

  const { disabledModal } = useSelector(LayoutProperties);
  console.log({ disabledModal });

  useEffect(() => {
    let unsubscribe;

    if (data) {
      if (subscribeToNewMessages) unsubscribe = subscribeToNewMessages();

      if (chatMessagesRef.current) {
        chatMessagesRef.current.scrollTo({
          top: chatMessagesRef.current.scrollHeight,
        });
      }
    }

    return unsubscribe;
  }, [data]);

  //console.log("data", data);

  // handle Post message preview click
  const openPostPreview = (postSnapshotId, postSnapshotShardNumber) => {
    if (!postSnapshotId) return;

    setPostSnapshotId(postSnapshotId);
    setPostSnapshotShardNumber(postSnapshotShardNumber);
    setPostPreviewModal(true);
  };

  const onClosePreviewModal = () => {
    setPostPreviewModal(false);
  };

  const menuIconRef = useRef();

  const enableChatProgress = (100 / CHAT_ENABLED_COINS_LIMIT) * coinsEarned;

  // handle Disable modal
  const onCloseDisabledModal = () => {
    dispatch(setDisabledModal(false));
  };

  // prepare list of messages
  let list = [];

  let currentDate = dateFormatted(new Date(0));
  let showDate = false;

  let profilePicture = null;

  if (data) {
    profilePicture = data.chat.profileImage
      ? AWS_PROFILE_IMAGES_URL + data.chat.profileImage
      : profilePictureIcon;

    // create list of Post messages
    list = data.chat.messages.map((message, index) => {
      const ownMessage = message.chat_id === chatId;

      const date = new Date(message.created_at);
      const messageDate = dateFormatted(date);
      const messageTime = getTimeAMPM(date);

      if (currentDate !== messageDate) {
        showDate = true;
        currentDate = messageDate;
      } else {
        showDate = false;
      }

      if (ownMessage) {
        return (
          <MessageWrap key={message.id}>
            {showDate && <ChatDate>{messageDate}</ChatDate>}
            {index === 0 && data.chat.partnerReaction && (
              <BadgeWrap>
                <BadgeTitle background={css.BRAND_INDIGO}>
                  {data.chat.username} reacted to your post
                </BadgeTitle>
              </BadgeWrap>
            )}
            <OwnMessage>
              <MessageSpacer />
              <ProfileImageWrap />
              <Bubble
                time={messageTime}
                own={true}
                read={message.read}
                postPreviewId={postId ? postId : message.post_snapshot_id}
                postSnapshotShardNumber={shardNumber}
                image={message.image}
                onBubbleClick={openPostPreview}
                messageId={message.id}
              >
                {message.message}
              </Bubble>
            </OwnMessage>
          </MessageWrap>
        );
      } else {
        return (
          <MessageWrap key={message.id}>
            {showDate && <ChatDate>{messageDate}</ChatDate>}
            <PartnerMessage>
              <ProfileImageWrap>
                <ProfileImage>
                  <img
                    src={profilePicture}
                    alt="Profile image"
                    style={{ width: "100%", borderRadius: "50%" }}
                  />
                </ProfileImage>
              </ProfileImageWrap>
              <Bubble
                time={messageTime}
                postPreviewId={postId ? postId : message.post_snapshot_id}
                postSnapshotShardNumber={partnerShardNumber}
                image={message.image}
                onBubbleClick={openPostPreview}
                messageId={message.id}
              >
                {message.message}
              </Bubble>
              <MessageSpacer />
            </PartnerMessage>
          </MessageWrap>
        );
      }
    });
  }

  return (
    <>
      <Layout>
        <Chat>
          {data && (
            <Header>
              <ChatTitle>
                <TitleProfileImage>
                  <img
                    src={profilePicture}
                    alt="Profile image"
                    style={{ width: "100%", borderRadius: "50%" }}
                  />
                </TitleProfileImage>
                <TitleUsername>
                  {data.chat.username}
                  {isReferral && (
                    <TeamInfo>
                      <TeamBadge background={css.BRAND_INDIGO}>
                        Referral
                      </TeamBadge>
                      <>
                        <TipIcon
                          data-tooltip-id="linkTip"
                          data-tooltip-html="The user is your direct referral."
                          data-tooltip-place="bottom"
                          data-tooltip-delay-show={500}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img
                            src={tipIcon}
                            style={{ width: "100%", paddingTop: "1px" }}
                          />
                        </TipIcon>
                        <Tooltip id="linkTip" className="tooltip" />
                      </>
                    </TeamInfo>
                  )}
                  {isSponsor && (
                    <TeamInfo>
                      <TeamBadge background={css.BRAND_INDIGO}>
                        Sponsor
                      </TeamBadge>
                      <>
                        <TipIcon
                          data-tooltip-id="linkTip"
                          data-tooltip-html="The user is your referrer who brought you to Scalelup."
                          data-tooltip-place="bottom"
                          data-tooltip-delay-show={500}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <img
                            src={tipIcon}
                            style={{ width: "100%", paddingTop: "1px" }}
                          />
                        </TipIcon>
                        <Tooltip id="linkTip" className="tooltip" />
                      </>
                    </TeamInfo>
                  )}
                </TitleUsername>
              </ChatTitle>
              {!postId && !userId && (
                <HeaderIcons>
                  <MenuWrap>
                    <MenuIconBox ref={menuIconRef}>
                      <RoundActionIcon
                        icon={chatMenuOpened ? dotsIcon : dotsEmptyIcon}
                        iconAlt="Chat menu"
                        onClick={onChatMenuClick}
                      />
                    </MenuIconBox>
                    <ChatMenu opened={chatMenuOpened ? 1 : 0}>
                      <ContextMenu
                        items={[
                          {
                            icon: blockingUser ? checkmarkIcon : blockIcon,
                            label: blockingUser ? "Unblock" : "Block user",
                            onClick: onBlockUser,
                          },
                          {
                            icon: archived ? archiveFilledIcon : archiveIcon,
                            label: archived ? "Unarchive chat" : "Archive chat",
                            onClick: onArchiveChat,
                          },
                        ]}
                        menuIconRef={menuIconRef}
                        closeMenu={closeChatMenu}
                      />
                    </ChatMenu>
                  </MenuWrap>
                  <BackIconWrap>
                    <RoundActionIcon
                      icon={returnIcon}
                      iconSize="16px"
                      iconAlt="Return"
                      onClick={() => navigate("/messages")}
                    />
                  </BackIconWrap>
                </HeaderIcons>
              )}
            </Header>
          )}
          <ChatBoxWrap>
            <ChatBox id="chatBox">
              {!data && <BoxLoadingSpinner height="100%" />}
              {data && (
                <Messages id="chatMessages" ref={chatMessagesRef}>
                  {list}
                </Messages>
              )}
              {data && (
                <MessageFormWrap>
                  {!chatId && (
                    <ChatInfo>
                      You can send one message to a user you have never
                      communicated with before. If you receive a reply, you can
                      continue chatting without limitations.
                    </ChatInfo>
                  )}
                  {!blockingUser &&
                    data.chat.username !== "Deleted account" && (
                      <MessageFormContainer
                        chatId={chatId}
                        onInputFocus={onInputFocus}
                        onMessageSent={onMessageSent}
                        partnerShardNumber={partnerShardNumber}
                        postId={postId}
                        userId={userId}
                        checkEnabled={checkEnabled}
                        isTeamChat={isTeamChat}
                      />
                    )}
                  {blockingUser && (
                    <ChatInfo>
                      The user is blocked.{" "}
                      <TextLink onClick={onBlockUser}>Click here</TextLink> to
                      unblock them.
                    </ChatInfo>
                  )}
                </MessageFormWrap>
              )}
            </ChatBox>
          </ChatBoxWrap>
        </Chat>
      </Layout>
      {postPreviewModal && (
        <ModalWrap>
          <Backdrop
            onClick={onClosePreviewModal}
            background="rgba(0, 0, 0, 0.7)"
          />
          <PostPreviewContainer
            postId={postId}
            postSnapshotId={postSnapshotId}
            postShardNumber={
              postId ? partnerShardNumber : postSnapshotShardNumber
            }
            snapshot={postId ? false : true}
            handleModalClose={onClosePreviewModal}
          />
        </ModalWrap>
      )}
      {disabledModal && (
        <ModalWrap>
          <Backdrop
            onClick={onCloseDisabledModal}
            background="rgba(0, 0, 0, 0.7)"
          />
          <DisabledModalWrap>
            <ModalWindow
              modal="chatDisabled"
              handleModalClose={onCloseDisabledModal}
              fullScreenHeight={true}
            >
              <DisabledWindowContent>
                <DisabledHeading>
                  <DisabledHeadingIcon>
                    <img
                      src={lockMessageIcon}
                      style={{ width: "100%", paddingTop: "1px" }}
                    />
                  </DisabledHeadingIcon>
                  <DisabledHeadingText>Chat disabled</DisabledHeadingText>
                </DisabledHeading>
                <DisabledText>
                  As a protection against spam, new members must earn at least{" "}
                  {numberWithCommas(CHAT_ENABLED_COINS_LIMIT)} coins before they
                  can start chatting with other members.
                </DisabledText>
                <DisabledBox>
                  <DisabledBoxTitle>Enable Message Sending</DisabledBoxTitle>
                  <DisabledBoxBar>
                    <DisabledBoxBarProgress
                      progresspercent={enableChatProgress}
                    />
                  </DisabledBoxBar>
                  <DisabledBoxCounter>
                    <DisabledBoxCounterIcon>
                      <img src={coinsWhiteIcon} style={{ height: "100%" }} />
                    </DisabledBoxCounterIcon>
                    <DisabledBoxCounterText>
                      {coinsEarned}/{numberWithCommas(CHAT_ENABLED_COINS_LIMIT)}
                    </DisabledBoxCounterText>
                  </DisabledBoxCounter>
                </DisabledBox>
                <DisabledText>
                  Earn an additional{" "}
                  {numberWithCommas(CHAT_ENABLED_COINS_LIMIT - coinsEarned)}{" "}
                  coins to enable sending messages.
                </DisabledText>
                <DisabledEarnInfoBox>
                  <CollectTip>
                    <CollectTipIcon>
                      <img src={bulbIcon} style={{ width: "100%" }} />
                    </CollectTipIcon>
                    <CollectTipText>How to earn coins</CollectTipText>
                  </CollectTip>
                  <DisabledEarnInfoRows>
                    <DisabledEarnInfoRow>
                      <DisabledEarnInfoIconBox>
                        <DisabledEarnInfoIcon>
                          <img src={discoverIcon} style={{ width: "100%" }} />
                        </DisabledEarnInfoIcon>
                      </DisabledEarnInfoIconBox>
                      <DisabledEarnInfoText>
                        Find coins by exploring opportunities
                      </DisabledEarnInfoText>
                      <DisabledEarnInfoButtonWrap>
                        <ButtonRoundIcon
                          icon={rightUpArrowIcon}
                          hoverIcon={rightUpArrowWhiteIcon}
                          background={css.BRAND_YELLOW}
                          hoverBackground={css.BRAND_GREEN}
                          onClick={() => navigate("/discover")}
                        />
                      </DisabledEarnInfoButtonWrap>
                    </DisabledEarnInfoRow>
                    <DisabledEarnInfoRow>
                      <DisabledEarnInfoIconBox>
                        <DisabledEarnInfoIcon>
                          <img src={referralsIcon} style={{ width: "100%" }} />
                        </DisabledEarnInfoIcon>
                      </DisabledEarnInfoIconBox>
                      <DisabledEarnInfoText>
                        Earn a reward by referring new users
                      </DisabledEarnInfoText>
                      <DisabledEarnInfoButtonWrap>
                        <ButtonRoundIcon
                          icon={rightUpArrowIcon}
                          hoverIcon={rightUpArrowWhiteIcon}
                          background={css.BRAND_YELLOW}
                          hoverBackground={css.BRAND_GREEN}
                          onClick={() => navigate("/referrals")}
                        />
                      </DisabledEarnInfoButtonWrap>
                    </DisabledEarnInfoRow>
                    <DisabledEarnInfoRow>
                      <DisabledEarnInfoIconBox>
                        <DisabledEarnInfoIcon>
                          <img src={creditCardIcon} style={{ width: "100%" }} />
                        </DisabledEarnInfoIcon>
                      </DisabledEarnInfoIconBox>
                      <DisabledEarnInfoText>
                        Purchase coin package
                      </DisabledEarnInfoText>
                      <DisabledEarnInfoButtonWrap>
                        <ButtonRoundIcon
                          icon={rightUpArrowIcon}
                          hoverIcon={rightUpArrowWhiteIcon}
                          background={css.BRAND_YELLOW}
                          hoverBackground={css.BRAND_GREEN}
                          onClick={() => navigate("/purchase-coins")}
                        />
                      </DisabledEarnInfoButtonWrap>
                    </DisabledEarnInfoRow>
                  </DisabledEarnInfoRows>
                </DisabledEarnInfoBox>
              </DisabledWindowContent>
            </ModalWindow>
          </DisabledModalWrap>
        </ModalWrap>
      )}
    </>
  );
};
