export const AUTH_ID = "auth-id";
export const AUTH_TOKEN = "auth-token";
export const AUTH_WS_TOKEN = "auth-ws-token";
export const SHARD_NUMBER = "sn";
export const POST_VIEW_TIME = 10;
export const REF_CODE = "ref";
export const PROMO_CODE = "promo";
export const MAINTENANCE_MODE = false;
export const ADMIN_USER_ID = "50a5a756-73e8-414c-9869-160f95d74149";
export const CHAT_ENABLED_COINS_LIMIT = 3000;

// export const GRAPHQL_SERVER_URL = "http://localhost:4000";
//export const BACKEND_SERVER_URL = "http://localhost:4001";

export const GRAPHQL_SERVER_URL =
  process.env.NODE_ENV === "production"
    ? "https://gw-graphql-server-923e68a04224.herokuapp.com"
    : "http://localhost:4000";
export const BACKEND_SERVER_URL =
  "https://gw-backend-server-7f51918dd144.herokuapp.com";

export const AWS_USER_UPLOAD_URL =
  "https://gw-user-upload-images.s3.eu-west-1.amazonaws.com/";
export const AWS_USER_UPLOAD_RESIZED_URL =
  "https://gw-user-upload-images-resized.s3.eu-west-1.amazonaws.com/";
export const AWS_PROFILE_IMAGES_URL =
  "https://gw-profile-images.s3.eu-west-1.amazonaws.com/";
