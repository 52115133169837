import styled from "styled-components";
import { useEffect } from "react";

import globalConstants from "constants/globalConstants";
import HidingMenuPublic from "./menu/HidingMenuPublic";

const PrivacyPolicy = styled.div`
  position: relative;
  width: 100%;
  padding: 90px 15vw 30px;
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
  line-height: 140%;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 90px 6vw 30px;
  }
`;

const H2Title = styled.h2`
  font-size: 30px;
  font-weight: 500;
  line-height: 115%;
`;

const UpdatedInfo = styled.div`
  margin-bottom: 20px;

  font-style: italic;
`;

const H3Title = styled.h3`
  margin: 30px 0 12px;

  font-size: 24px;
  font-weight: 400;
  line-height: 115%;
`;

const Paragraph = styled.div``;

const List = styled.ul``;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const Bold = styled.span`
  font-weight: 500;
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Privacy Policy | Scalelup";
  }, []);

  return (
    <PrivacyPolicy>
      <H2Title>Privacy Policy</H2Title>
      <UpdatedInfo>Last Updated: November 25th, 2024</UpdatedInfo>
      <Paragraph>
        Protecting your privacy is of utmost importance to us. This Privacy
        Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website, scalelup.com. By using our site,
        you consent to the practices described in this policy.
      </Paragraph>
      <H3Title>1. Introduction and Scope</H3Title>
      <Paragraph>
        This Privacy Policy applies to all visitors and users of the Scalelup
        website. It outlines our approach to collecting and managing your
        personal data and our commitment to transparency and security. By
        accessing our services, you agree to the terms of this policy.
      </Paragraph>
      <H3Title>2. Information Collection</H3Title>
      <Paragraph>
        We collect a variety of information to enhance your experience and
        improve our services. This includes:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Personal Information:</Bold> Information that can be used to
          identify you, such as your name, email address, and profile picture.
        </ListItem>
        <ListItem>
          <Bold>Technical Information:</Bold> Information about your device and
          internet connection, including your IP address, browser type, and
          operating system.
        </ListItem>
        <ListItem>
          <Bold>Usage Data:</Bold> Information about your activity on our
          website, such as the pages you visit, the time and date of your visit,
          and the time spent on those pages.
        </ListItem>
        <ListItem>
          <Bold>Communication Data:</Bold> Information you provide when you
          communicate with us, including the content of your messages and
          contact information.
        </ListItem>
      </List>
      <H3Title>3. Methods of Collection</H3Title>
      <Paragraph>
        We collect information through various methods, including:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Direct Collection:</Bold> Information you voluntarily provide
          when you register for an account, fill out forms, or interact with us
          via email or other communication channels.
        </ListItem>
        <ListItem>
          <Bold>Automated Collection:</Bold> Information gathered automatically
          through cookies, web beacons, and other tracking technologies as you
          navigate the site.
        </ListItem>
        <ListItem>
          <Bold>Third-Party Sources:</Bold> Information we may obtain from third
          parties, such as analytics providers, to enhance our services.
        </ListItem>
      </List>
      <H3Title>4. Use of Information</H3Title>
      <Paragraph>
        We use the information we collect for various purposes, including:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Service Delivery:</Bold> To operate, maintain, and improve our
          website and services.
        </ListItem>
        <ListItem>
          <Bold>Personalization:</Bold> To tailor content and recommendations to
          your preferences and enhance your user experience.
        </ListItem>
        <ListItem>
          <Bold>Communication:</Bold> To send administrative information,
          updates, and promotional materials you have agreed to receive.
        </ListItem>
        <ListItem>
          <Bold>Analysis and Research:</Bold> To analyze trends and monitor
          usage patterns to improve our website and services.
        </ListItem>
        <ListItem>
          <Bold>Security and Compliance:</Bold> To protect against fraud,
          unauthorized access, and other illegal activities, and to comply with
          legal obligations.
        </ListItem>
      </List>
      <H3Title>5. Sharing of Information</H3Title>
      <Paragraph>
        We may share your information with third parties in the following
        circumstances:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Service Providers:</Bold> We may share your information with
          service providers who assist us in website operations, data analysis,
          marketing, and customer service. These providers are obligated to
          maintain the confidentiality of your information.
        </ListItem>
        <ListItem>
          <Bold>Legal Requirements:</Bold> We may disclose your information if
          required by law, court order, or governmental regulation.
        </ListItem>
        <ListItem>
          <Bold>Business Transfers:</Bold> In the event of a merger,
          acquisition, or sale of assets, your information may be transferred as
          part of that transaction, subject to the acquirer's privacy
          commitments.
        </ListItem>
      </List>
      <H3Title>6. Data Security</H3Title>
      <Paragraph>
        We prioritize the security of your personal information and implement a
        variety of security measures to maintain the safety of your data. These
        measures include encryption, firewalls, and secure server hosting.
        However, no method of transmission over the internet or electronic
        storage is 100% secure, and we cannot guarantee absolute security.
      </Paragraph>
      <H3Title>7. User Rights</H3Title>
      <Paragraph>
        You have various rights concerning your personal data, including:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Access:</Bold> Request access to the personal data we hold about
          you.
        </ListItem>
        <ListItem>
          <Bold>Correction:</Bold> Request correction of inaccurate or
          incomplete data.
        </ListItem>
        <ListItem>
          <Bold>Deletion:</Bold> Request deletion of your data, subject to
          certain legal exceptions.
        </ListItem>
        <ListItem>
          <Bold>Objection:</Bold> Object to the processing of your data in
          certain circumstances.
        </ListItem>
        <ListItem>
          <Bold>Restriction:</Bold> Request the restriction of processing of
          your personal data.
        </ListItem>
        <ListItem>
          <Bold>Data Portability:</Bold> Request the transfer of your data to
          another service provider.
        </ListItem>
      </List>
      <Paragraph>
        To exercise any of these rights, please contact us at
        support@scalelup.com. We will respond to your request in accordance with
        applicable data protection laws.
      </Paragraph>
      <H3Title>8. Cookies and Tracking Technologies</H3Title>
      <Paragraph>
        Our website uses cookies and similar technologies to enhance user
        experience and analyze website performance. Cookies are small data files
        stored on your device's browser. We use the following types of cookies:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Essential Cookies:</Bold> These cookies are necessary for the
          operation of our website. They enable you to navigate the site and use
          its features, such as accessing secure areas.
        </ListItem>
        <ListItem>
          <Bold>Performance Cookies:</Bold> These cookies collect information
          about how visitors use our website, such as which pages are visited
          most often and if users receive error messages from web pages. These
          cookies help us improve the functionality of our site.
        </ListItem>
        <ListItem>
          <Bold>Functional Cookies:</Bold> These cookies allow our website to
          remember choices you make (such as your username, language, or the
          region you are in) and provide enhanced, more personal features.
        </ListItem>
        <ListItem>
          <Bold>Targeting Cookies:</Bold> These cookies record your visit to our
          website, the pages you have visited, and the links you have followed.
          We use this information to make our website and the advertising
          displayed on it more relevant to your interests.
        </ListItem>
      </List>
      <Paragraph>
        You can control the use of cookies at the individual browser level. If
        you choose to disable cookies, it may limit your use of certain features
        or functions on our site.
      </Paragraph>
      <H3Title>9. Third-Party Links</H3Title>
      <Paragraph>
        Our website may contain links to third-party websites. These external
        sites are governed by their own privacy policies, and we do not accept
        any responsibility or liability for their policies or practices. We
        encourage you to review the privacy policies of these third-party sites
        before providing any personal information.
      </Paragraph>
      <H3Title>10. Changes to the Privacy Policy</H3Title>
      <Paragraph>
        We may update this Privacy Policy from time to time to reflect changes
        in our practices or legal requirements. We will notify you of any
        significant changes by posting the new policy on this page and updating
        the "Last Updated" date at the top. We encourage you to review this
        policy periodically to stay informed about how we are protecting your
        information.
      </Paragraph>
      <H3Title>11. Contact Information</H3Title>
      <Paragraph>
        If you have any questions, concerns, or requests regarding this Privacy
        Policy or our data practices, please contact us at support@scalelup.com.
        We are committed to resolving any issues promptly and efficiently.
      </Paragraph>
      <H3Title>12. Legal Basis for Processing</H3Title>
      <Paragraph>
        Our legal basis for collecting and using your personal information
        depends on the specific context in which we collect it. Generally, we
        process your data based on the following grounds:
      </Paragraph>
      <List>
        <ListItem>
          <Bold>Consent:</Bold> When you have given explicit consent for us to
          process your personal data for specified purposes.
        </ListItem>
        <ListItem>
          <Bold>Contractual Necessity:</Bold> When processing is necessary for
          the performance of a contract with you or to take pre-contractual
          steps at your request.
        </ListItem>
        <ListItem>
          <Bold>Legitimate Interests:</Bold> When processing is necessary for
          our legitimate interests, and those interests are not overridden by
          your rights or interests.
        </ListItem>
        <ListItem>
          <Bold>Legal Obligation:</Bold> When processing is necessary for
          compliance with a legal obligation to which we are subject.
        </ListItem>
      </List>
      <H3Title>13. International Data Transfers</H3Title>
      <Paragraph>
        Scalelup operates globally, and your information may be transferred to,
        stored, and processed in countries other than your own. We take
        appropriate measures to ensure that your personal data remains protected
        in accordance with this Privacy Policy and applicable data protection
        laws. This includes implementing standard contractual clauses or relying
        on other approved data transfer mechanisms.
      </Paragraph>
      <HidingMenuPublic />
    </PrivacyPolicy>
  );
};
