import { useEffect } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import Layout from "components/Layout";
import PageTitle from "components/text/PageTitle";
import SectionTitle from "components/text/SectionTitle";
import ReferralLinkBox from "./ReferralLinkBox";

import referralsTipBoxIcon from "assets/img/icons/employee-engagement.svg";
import referralsQualifiedIconIcon from "assets/img/icons/referral-qualified-icon.svg";
import coinsIcon from "assets/img/icons/coins-icon.svg";
import ReferralStatsContainer from "./ReferralStatsContainer";
import ReferralListContainer from "./ReferralListContainer";
import CashBonusContainer from "./CashBonusContainer";
import AffiliateProgramContainer from "./AffiliateProgramContainer";
import AffiliateRevenuesContainer from "./AffiliateRevenuesContainer";

const Referrals = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const OverviewBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const OverviewTop = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 4vw;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
  }
`;

const OverviewTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 1;
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  line-height: 125%;
`;

const OverviewTopRight = styled.div`
  padding-top: 20px;
  padding-right: 1vw;

  display: flex;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 2px 0 16px;

    align-self: center;
  }
`;

const ReferralsImage = styled.div`
  max-width: 90px;
`;

const OverviewMiddle = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const OTable = styled.div`
  display: flex;
  align-items: center;
  gap: 0;
`;

const OTableLeft = styled.div`
  padding: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const OTableIcon = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
`;

const OTableRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OTableRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const OTableBox = styled.div`
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const OTableBoxWidthOne = styled.div`
  width: 60px;

  text-align: right;
`;

const OTableCoinsIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const OTableText = styled.div``;

const OverviewSmallInfo = styled.div`
  font-size: 13px;
  color: ${css.TEXT_GREY};
  line-height: 125%;
`;

const OverviewBottom = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Referrals | Scalelup";
  }, []);

  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  const affiliateProgramActive = true;

  return (
    <Layout>
      <Referrals>
        <PageTitle>Referrals</PageTitle>
        <OverviewBox>
          <OverviewTop>
            <OverviewTopLeft>
              <BoxTitle>Earn More Coins by Making Referrals</BoxTitle>
              <Text>
                Recommend Scalelup to other users and earn 300 coins for each
                qualified referral. Coins allow you to show your posts to more
                people and gain more exposure for your business.
              </Text>
            </OverviewTopLeft>
            <OverviewTopRight>
              <ReferralsImage>
                <img
                  src={referralsTipBoxIcon}
                  alt="Referrals"
                  style={{ width: "100%" }}
                />
              </ReferralsImage>
            </OverviewTopRight>
          </OverviewTop>
          <OverviewMiddle>
            <BoxTitle>Refer New User</BoxTitle>
            <OTable>
              <OTableLeft>
                <OTableIcon>
                  <img
                    src={referralsQualifiedIconIcon}
                    style={{ width: "100%" }}
                  />
                </OTableIcon>
              </OTableLeft>
              <OTableRight>
                <OTableRow>
                  <OTableBox>
                    <OTableBoxWidthOne>They get</OTableBoxWidthOne>
                  </OTableBox>
                  <OTableBox>
                    <OTableCoinsIcon>
                      <img src={coinsIcon} style={{ width: "100%" }} />
                    </OTableCoinsIcon>
                    <OTableText>100 Coins</OTableText>
                  </OTableBox>
                  {!mobileScreen && (
                    <OTableBox>
                      <OTableText>+</OTableText>
                      <OTableCoinsIcon>
                        <img src={coinsIcon} style={{ width: "100%" }} />
                      </OTableCoinsIcon>
                      <OTableText>100 Coins Extra</OTableText>
                    </OTableBox>
                  )}
                </OTableRow>
                {mobileScreen && (
                  <OTableRow style={{ marginTop: "-3px", marginBottom: "6px" }}>
                    <OTableBox>
                      <OTableBoxWidthOne>+</OTableBoxWidthOne>
                    </OTableBox>
                    <OTableBox>
                      <OTableCoinsIcon>
                        <img src={coinsIcon} style={{ width: "100%" }} />
                      </OTableCoinsIcon>
                      <OTableText>100 Coins Extra</OTableText>
                    </OTableBox>
                  </OTableRow>
                )}
                <OTableRow>
                  <OTableBox>
                    <OTableBoxWidthOne>You get</OTableBoxWidthOne>
                  </OTableBox>
                  <OTableBox>
                    <OTableCoinsIcon>
                      <img src={coinsIcon} style={{ width: "100%" }} />
                    </OTableCoinsIcon>
                    <OTableText>300 Coins Reward</OTableText>
                  </OTableBox>
                </OTableRow>
              </OTableRight>
            </OTable>
          </OverviewMiddle>
          <OverviewBottom>
            <OverviewSmallInfo>
              * Only qualified referrals count towards the referral reward. The
              referred user must view at least 40 posts to become qualified and
              for you to receive the referral reward.
            </OverviewSmallInfo>
          </OverviewBottom>
        </OverviewBox>
        <SectionTitle>Referral Link</SectionTitle>
        <ReferralLinkBox />
        <SectionTitle>Statistics</SectionTitle>
        <ReferralStatsContainer />
        <SectionTitle>Cash Bonus</SectionTitle>
        <CashBonusContainer />
        {affiliateProgramActive && (
          <>
            <SectionTitle>Affiliate Program</SectionTitle>
            <AffiliateProgramContainer />
            <AffiliateRevenuesContainer />
          </>
        )}
        <SectionTitle>Referral List</SectionTitle>
        <ReferralListContainer />
      </Referrals>
    </Layout>
  );
};
