import styled from "styled-components";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { preloadImage } from "modules/functions";
import ButtonRoundIcon from "components/buttons/ButtonRoundIcon";

import plusIcon from "assets/img/icons/plus-sign.svg";
import plusWhiteIcon from "assets/img/icons/plus-sign-white.svg";

const CreateAnotherPostBox = styled.div`
  width: calc(50% - 9px);
  min-width: calc(50% - 9px);
  padding: 60px 35px;
  box-sizing: border-box;
  max-width: 450px;
  min-height: 300px;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${globalConstants.switchTablet}) {
    width: 100%;
  }
`;

const Text = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  max-width: 440px;

  text-align: center;
`;

const ButtonWrap = styled(Link)`
  text-decoration: none;
`;

export default () => {
  preloadImage(plusWhiteIcon);

  return (
    <CreateAnotherPostBox>
      <ButtonWrap to="/modify-post">
        <ButtonRoundIcon
          buttonSize="42px"
          iconSize="14px"
          icon={plusIcon}
          hoverIcon={plusWhiteIcon}
          background={css.BRAND_YELLOW}
          hoverBackground={css.BRAND_GREEN}
        />
      </ButtonWrap>
      <Text>Create another post to gain more interactions</Text>
    </CreateAnotherPostBox>
  );
};
