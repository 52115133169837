import styled from "styled-components";

import * as css from "styles/CssVariables.js";

const InputWrap = styled.div`
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  width: ${(props) => (props.width ? props.width : "100%")};

  display: flex;
  align-items: center;
`;

const Input = styled.input`
  padding: 0px 20px;
  background: ${(props) => {
    if (props.warning) {
      return "#FF6D88";
    }

    if (props.background) return props.background;
    else return css.DARK_BOX_BACKGROUND;
  }};

  width: 100%;
  height: ${(props) => (props.height ? props.height : "42px")};

  font-size: 15px;
  font-weight: 300;
  color: ${(props) => (props.disabled ? css.TEXT_GREY : "#ffffff")};

  border: 0px;
  border-radius: ${(props) => {
    const radius = props.radius ? props.radius : "15px";

    return props.icon ? radius + " 0 0 " + radius : radius;
  }};

  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  box-sizing: border-box;

  &:focus {
    outline-offset: -2px;
  }
`;

const InputIcon = styled.div`
  padding: 0px 8px 0px 8px;
  height: ${(props) => (props.height ? props.height : "42px")};
  box-sizing: border-box;

  background: ${(props) => {
    if (props.warning) {
      return "rgba(238, 135, 135, 0.74)";
    }

    if (props.background) {
      return props.background;
    }

    return "rgba(255, 255, 255, 0.14)";
  }};

  border: 0px;
  border-radius: 0 3px 3px 0;
  border-radius: ${(props) => {
    const radius = props.radius ? props.radius : "15px";

    return "0 " + radius + " " + radius + " 0";
  }};

  &:hover {
    cursor: pointer;
  }

  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 18px;
  height: 18px;
`;

export default (props) => {
  const {
    type,
    name,
    value,
    onChange,
    placeholder,
    margin,
    warning,
    maxLength,
    onKeyDown,
    textAlign,
    autoFocus,
    padding,
    readOnly,
    background,
    height,
    radius,
    icon,
    iconSize,
    onIconClick,
    width,
    disabled,
  } = props;

  return (
    <InputWrap margin={margin} padding={padding} width={width}>
      <Input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        warning={warning ? 1 : 0}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        textAlign={textAlign}
        autoFocus={autoFocus ? 1 : 0}
        readOnly={readOnly ? 1 : 0}
        background={background}
        height={height}
        radius={radius}
        icon={icon ? 1 : 0}
        disabled={disabled ? 1 : 0}
      />
      {icon && (
        <InputIcon
          onClick={() => onIconClick()}
          background={background}
          warning={warning ? 1 : 0}
          height={height}
          radius={radius}
        >
          <Icon>
            <img src={icon} width={iconSize ? iconSize : "100%"} />
          </Icon>
        </InputIcon>
      )}
    </InputWrap>
  );
};
