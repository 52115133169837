import styled from "styled-components";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";

const Item = styled(Link)`
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  color: ${css.TEXT_GREY};
  text-decoration: none;
  line-height: 1;
`;

const ItemText = styled.div`
  transition: 0.2s;

  &:hover {
    color: #e9e9e9;
  }
`;

export default ({ to, children, target, id }) => {
  return (
    <Item to={to} target={target} id={id}>
      <ItemText>{children}</ItemText>
    </Item>
  );
};
