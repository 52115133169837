import { useRef, useEffect, useState } from "react";
import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import crossIcon from "assets/img/icons/icons8-cross.svg";

import RoundActionIcon from "components/buttons/RoundActionIcon";

const ModalWindow = styled.div`
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: ${(props) => (props.scrollmodal ? "calc(100vh - 100px)" : "auto")};
  padding: ${css.BOX_PADDING_LARGE};
  box-sizing: border-box;
  max-height: calc(100vh - 120px);
  transform: translateY(-25px);

  overflow-y: auto;

  border-radius: ${css.BOX_RADIUS_PRIMARY};
  background: ${css.BLUE_BOX_GRADIENT};

  display: flex;
  flex-direction: column;

  @media (min-width: ${globalConstants.switchMobile}) {
    margin-top: 0px;
  }
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 30px !important;
  padding: ${css.BOX_PADDING_LARGE};
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${globalConstants.switchMobile}) {
    margin-top: 0px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const CloseBox = styled.div`
  align-self: flex-start;
`;

const ModalContent = styled.div`
  width: 100%;
  box-sizing: border-box;

  flex-grow: 1;
`;

export default (props) => {
  const { modal, handleModalClose, children, title } = props;

  const [modalHeight, setModalHeight] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [scrollModal, setScrollModal] = useState(false);

  const modalRef = useRef();

  useEffect(() => {
    setModalHeight(modalRef.current.scrollHeight);
  }, [children]);

  useEffect(() => {
    if (modalHeight > windowHeight) {
      setScrollModal(true);
    } else {
      setScrollModal(false);
    }
  }, [modalHeight, windowHeight]);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ModalWindow
      ref={modalRef}
      scrollmodal={scrollModal ? 1 : 0}
      className={modal === "postPreview" ? "post-preview-modal" : ""}
    >
      <Header>
        <Title>{title}</Title>
        <CloseBox>
          <RoundActionIcon
            icon={crossIcon}
            iconAlt="Close"
            onClick={handleModalClose}
            parameters={modal}
          />
        </CloseBox>
      </Header>
      <ModalContent>{children}</ModalContent>
    </ModalWindow>
  );
};
