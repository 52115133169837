import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import dotIndigoIcon from "assets/img/icons/icons8-dot-indigo.svg";

const Item = styled(Link)`
  text-decoration: none;
`;

const ItemBox = styled.div`
  position: relative;
`;

const ItemIcon = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  box-sizing: border-box;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;

  padding: ${(props) => (props.padding ? props.padding : "0")};
`;

const DotIcon = styled.div`
  position: absolute;
  top: -6px;
  right: -8px;

  width: 20px;
  min-width: 20px;
  height: 20px;
`;

export default ({ to, icon, iconCurrent, padding, onClick, dot }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      {to !== "#" && (
        <Item to={to} onClick={onClick}>
          <ItemIcon
            icon={pathname === to ? iconCurrent : icon}
            padding={padding}
          />
        </Item>
      )}
      {to === "#" && (
        <ItemBox onClick={onClick}>
          <ItemIcon
            icon={pathname === to ? iconCurrent : icon}
            padding={padding}
          />
          {dot && (
            <DotIcon>
              <img src={dotIndigoIcon} style={{ width: "100%" }} />
            </DotIcon>
          )}
        </ItemBox>
      )}
    </>
  );
};
