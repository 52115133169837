import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AUTH_TOKEN } from "constants";
import * as css from "styles/CssVariables.js";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import ButtonSecondary from "components/buttons/ButtonSecondary";

// Actions
import { closeHidingMenuPublic, openModal } from "store/actions";

const Menu = styled.div`
  margin-top: 30px;

  overflow-y: auto;

  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  padding-left: 40px;
  width: 200px;

  font-family: "Barlow";

  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

export default ({ closeMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openLogin = () => {
    dispatch(closeHidingMenuPublic());
    dispatch(openModal("login"));
  };

  const openSignup = () => {
    dispatch(closeHidingMenuPublic());
    dispatch(openModal("signup"));
  };

  const hasToken = localStorage.getItem(AUTH_TOKEN) ? true : false;

  return (
    <Menu>
      {!hasToken && (
        <Buttons>
          <ButtonSecondary onClick={openLogin}>Log In</ButtonSecondary>
          <ButtonPrimary onClick={openSignup}>Sign Up</ButtonPrimary>
        </Buttons>
      )}
      {hasToken && (
        <Buttons>
          <ButtonPrimary onClick={() => navigate("/home")}>
            Open Dashboard
          </ButtonPrimary>
        </Buttons>
      )}
    </Menu>
  );
};
