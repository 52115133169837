import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";

import { AUTH_ID } from "constants";
import WalletBalances from "./WalletBalances";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

export default () => {
  // Fetch data
  const GET_WALLET_BALANCES = gql`
    query GetWalletBalances {
      walletBalances {
        balance
        cash_balance
      }
    }
  `;

  const sn = "s" + localStorage.getItem("sn");

  const BALANCE_UPDATES_SUBSCRIPTION = gql`
    subscription onBalanceUpdate($userId: uuid!) {
      ${sn} {
        users_by_pk(id: $userId) {
          balance
        }
      }
    }
  `;

  const { subscribeToMore, data, error } = useQuery(GET_WALLET_BALANCES, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error fetching wallet balances", error);
    captureException(error);
  }

  const userId = localStorage.getItem(AUTH_ID);

  //console.log("data", data);

  return (
    <>
      {!data && (
        <LoadingBox>
          <BoxLoadingSpinner height="161px" />
        </LoadingBox>
      )}
      {data && (
        <WalletBalances
          data={data}
          subscribeToUpdates={() =>
            subscribeToMore({
              document: BALANCE_UPDATES_SUBSCRIPTION,
              variables: { userId },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;

                if (!subscriptionData.data[sn].users_by_pk) {
                  return prev;
                }

                return {
                  walletBalances: {
                    balance: subscriptionData.data[sn].users_by_pk.balance,
                    cash_balance: data.walletBalances.cash_balance,
                    __typename: "User",
                  },
                };
              },
            })
          }
        />
      )}
    </>
  );
};
