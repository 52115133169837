import styled from "styled-components";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { isEmail } from "validator";
import { captureException } from "@sentry/react";
import { useDispatch } from "react-redux";

import ForgotPassword from "./ForgotPassword";

// Actions
import { openModal } from "store/actions";

const ForgotPasswordContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;
`;

export default ({ handleModalClose, openErrorWindow, ipAddress }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState(null);
  const [showResendLink, setShowResendLink] = useState(false);
  const [loadingResendVerification, setLoadingResendVerification] =
    useState(false);
  const [emailToBeVerified, setEmailToBeVerified] = useState(null);

  const openLogin = () => {
    dispatch(openModal("login"));
  };

  const handleInputChange = (e) => {
    if (loading) {
      return;
    }

    setEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  // handle Form submit
  const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email: String!, $ipAddress: String) {
      forgotPassword(email: $email, ipAddress: $ipAddress) {
        resultCode
      }
    }
  `;

  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const onSubmit = async () => {
    try {
      if (loading) {
        return;
      }

      //validate email
      let newWarnings = [];

      if (
        email.trim().length < 5 ||
        email.trim().length > 100 ||
        !isEmail(email)
      ) {
        newWarnings.push("email");
      }

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }

      setLoading(true);
      setWarnings([]);
      setForgotPasswordMessage(null);

      const result = await forgotPassword({
        variables: { email, ipAddress },
      });

      const { resultCode } = result.data.forgotPassword;

      setLoading(false);

      if (resultCode === 4) {
        // email not found
        setForgotPasswordMessage(
          "We couldn't find this email address in our system."
        );
        setWarnings(["email"]);
        setLoading(false);
        return;
      }

      if (resultCode === 5) {
        // account not active yet
        setForgotPasswordMessage(
          "Your email is pending verification. Please follow the link in the activation email we sent you."
        );
        setEmailToBeVerified(email);
        setShowResendLink(true);
        setLoading(false);

        return;
      }

      if (resultCode !== 0 && resultCode !== 6) {
        // activation resend failed
        handleModalClose();
        openErrorWindow();

        return;
      }

      setForgotPasswordSuccess(true);

      return;
    } catch (error) {
      captureException(error);
      setLoading(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // resend Verification email
  const RESEND_ACCOUNT_VERIFICATION = gql`
    mutation ResendAccountVerification($email: String!) {
      resendAccountVerification(email: $email) {
        resultCode
      }
    }
  `;

  const [resendAccountVerification] = useMutation(RESEND_ACCOUNT_VERIFICATION);

  const onResendVerification = async () => {
    try {
      if (loadingResendVerification || !emailToBeVerified) {
        return;
      }

      setLoadingResendVerification(true);

      const result = await resendAccountVerification({
        variables: { email: emailToBeVerified },
      });
      const { resultCode } = result.data.resendAccountVerification;

      if (resultCode !== 0 && resultCode !== 5) {
        // activation resend failed
        setLoadingResendVerification(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      handleModalClose();
      setLoadingResendVerification(false);

      return;
    } catch (error) {
      captureException(error);
      setLoadingResendVerification(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  return (
    <ForgotPasswordContainer>
      <ForgotPassword
        handleModalClose={handleModalClose}
        handleInputChange={handleInputChange}
        email={email}
        loading={loading}
        warnings={warnings}
        forgotPasswordMessage={forgotPasswordMessage}
        forgotPasswordSuccess={forgotPasswordSuccess}
        onSubmit={onSubmit}
        openLogin={openLogin}
        handleKeyDown={handleKeyDown}
        showResendLink={showResendLink}
        onResendVerification={onResendVerification}
      />
    </ForgotPasswordContainer>
  );
};
