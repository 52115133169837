import styled from "styled-components";

import * as css from "styles/CssVariables.js";
import { AWS_PROFILE_IMAGES_URL } from "constants";
import { dateFormatted, getTimeAMPM } from "modules/functions";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import profilePictureIcon from "assets/img/icons/icons8-profile-picture.svg";

const AffiliateRevenuesBox = styled.div`
  width: 100%;
  min-height: 280px;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

const TableWrap = styled.div`
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: -5px;

  overflow-x: auto;
`;

const ListTable = styled.table`
  width: 100%;
  min-width: 485px;
  max-width: 700px;
`;

const TableHead = styled.thead``;

const Label = styled.th`
  padding-bottom: 12px;
  width: ${(props) => (props.width ? props.width : "auto")};

  font-size: 18px;
  font-weight: 400;
  text-align: left;
`;

const TableBody = styled.tbody``;

const Cell = styled.td``;

const AvatarNameBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AvatarIcon = styled.div`
  margin: 4px 0;

  width: 32px;
  min-width: 32px;
  height: 32px;

  border-radius: 16px;

  overflow: hidden;
`;

const Name = styled.div``;

const Pagination = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};

  display: flex;
  justify-content: center;
  gap: ${css.BUTTONS_GAP_SMALL};
`;

export default ({ data, page, limit, paginate }) => {
  const { revenues, referralProfiles, numOfResults } = data.affiliateRevenues;

  const list = revenues.map((revenue) => {
    let username = "Indirect referral";
    let profilePicture;

    if (revenue.level === "direct") {
      const profileData = referralProfiles.find(
        (profile) => profile.referral_id === revenue.referral_id
      );

      if (profileData) {
        profilePicture = profileData.data.profile_picture
          ? AWS_PROFILE_IMAGES_URL + profileData.data.profile_picture
          : profilePictureIcon;

        username = profileData.data.username;
      }
    }

    return (
      <tr key={revenue.id}>
        <Cell>
          {dateFormatted(new Date(revenue.created_at), false) +
            ", " +
            getTimeAMPM(new Date(revenue.created_at))}
        </Cell>
        <Cell>{revenue.level === "direct" ? "Direct" : "Indirect"}</Cell>
        <Cell>
          <AvatarNameBox>
            <AvatarIcon>
              <img
                src={
                  revenue.level === "direct"
                    ? profilePicture
                    : profilePictureIcon
                }
                style={{ width: "100%", borderRadius: "50%" }}
              />
            </AvatarIcon>
            <Name>{username}</Name>
          </AvatarNameBox>
        </Cell>
        <Cell>${revenue.revenue} USD</Cell>
      </tr>
    );
  });

  let showPagination = false;

  if (numOfResults > limit) showPagination = true;

  const maxPage = Math.ceil(numOfResults / limit);

  return (
    <AffiliateRevenuesBox>
      <TableWrap className="table-wrap">
        <ListTable>
          <TableHead>
            <tr>
              <Label width="150px">Date</Label>
              <Label width="80px">Level</Label>
              <Label>Referral</Label>
              <Label width="100px">Revenue</Label>
            </tr>
          </TableHead>
          <TableBody>{list}</TableBody>
        </ListTable>
      </TableWrap>
      {showPagination && (
        <Pagination>
          {page > 1 && (
            <ButtonPrimarySmall onClick={() => paginate("previous")}>
              Previous
            </ButtonPrimarySmall>
          )}
          {page < maxPage && (
            <ButtonPrimarySmall onClick={() => paginate("next")}>
              Next
            </ButtonPrimarySmall>
          )}
        </Pagination>
      )}
    </AffiliateRevenuesBox>
  );
};
