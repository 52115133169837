import { useEffect } from "react";
import styled from "styled-components";

import globalConstants from "constants/globalConstants";
import Layout from "components/Layout";
import PageTitle from "components/text/PageTitle";

import PurchaseCoinsContainer from "./PurchaseCoinsContainer";

const Purchase = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Purchase Coins | Scalelup";
  }, []);

  return (
    <Layout>
      <Purchase>
        <PageTitle>Purchase Coins</PageTitle>
        <PurchaseCoinsContainer />
      </Purchase>
    </Layout>
  );
};
