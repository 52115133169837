import styled from "styled-components";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import ButtonPrimary from "components/buttons/ButtonPrimary.js";
import MockupDisplay from "./other/landingPage/MockupDisplay";
import HidingMenuPublic from "./menu/HidingMenuPublic";

import circlePointBlueIcon from "assets/img/icons/icons8-circle-point-blue.svg";
import phoneMockup1 from "assets/img/landingPage/phone-mockup-1.png";
import videoCover from "assets/img/cover-1.jpg";

// Actions
import { openModal } from "store/actions";

const LandingPage = styled.div`
  position: relative;
  width: 100%;

  font-family: "Montserrat", sans-serif;
`;

const NarrowContent = styled.div`
  position: relative;
  width: 100%;

  max-width: 1300px;
  margin: 0 auto;
  padding: 0 40px;

  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 0 20px;
  }
`;

const Hero = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 80px;

  @media (max-width: ${globalConstants.switchTablet}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ShortHeading = styled.h4`
  margin-top: 16vh;
  margin-bottom: 20px;
  padding: 0 60px;
  height: 32px;

  font-family: "Barlow";

  background: ${css.BRAND_INDIGO};
  border-radius: 14px;

  font-weight: 500;
  line-height: 1;

  display: flex;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    margin-top: 12vh;
  }
`;

const MainHeading = styled.h1`
  margin-top: 15px;

  font-size: 52px;
  font-weight: 600;
  line-height: 115%;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 40px;
    text-align: left;
  }
`;

const SubHeading = styled.h1`
  margin-top: 0px;

  font-size: 26px;
  font-weight: 600;
  line-height: 130%;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 20px;
  }
`;

const List = styled.div`
  margin-top: 15px;

  font-size: 18px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const ListItem = styled.div`
  display: flex;
  gap: 6px;
`;

const ListIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const ListText = styled.div`
  line-height: 130%;
`;

const HeroParagraph = styled.div`
  margin-top: 30px;

  font-size: 20px;
  line-height: 135%;
  color: ${css.TEXT_GREY};

  @media (max-width: ${globalConstants.switchMobile}) {
    text-align: left;
    font-size: 18px;
  }
`;

const HeroButtonWrap = styled.div`
  margin-top: 40px;
  margin-bottom: 0px;

  display: flex;
  justify-content: flex-start;

  @media (max-width: ${globalConstants.switchTablet}) {
    margin-bottom: 50px;
  }
`;

const RightSide = styled.div``;

const MockupColumn = styled.div`
  padding-top: 160px;
  min-width: 400px;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding-top: 50px;
    width: 100%;
    min-width: auto;
  }
`;

const RainbowBox = styled.div`
  position: relative;
  width: 100%;
  height: 500px;

  border-radius: ${css.BOX_RADIUS_PRIMARY};
  overflow: hidden;
`;

const RainbowBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;

  background: linear-gradient(
    132deg,
    #8783d1 0.49%,
    #ffe70d 22.08%,
    #5dd39e 43.66%,
    #8783d1 65.24%,
    #5dd39e 86.82%,
    #ffe70d 108.4%
  );
`;

const PhoneBox = styled.div`
  position: absolute;
  top: 55px;
  left: 50%;
  width: 260px;

  transform: translateX(-50%);
`;

const VideoColumn = styled.div`
  padding-top: 160px;
  min-width: 500px;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding-top: 50px;
    width: 100%;
    min-width: auto;
  }
`;

const VideoBox = styled.div`
  width: 100%;
  max-width: 640px;
  padding: 20px;
  box-sizing: border-box;
  aspect-ratio: 1.65;

  background: ${css.BRAND_BLUSH};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${globalConstants.switchMobile}) {
    padding: 15px;

    flex-direction: column;
    align-items: flex-start;
  }
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Scalelup | Drive Website Traffic, Build Awareness";
  }, []);

  const navigate = useNavigate();

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  useEffect(() => {
    if (profileData) navigate("/");
  }, [profileData]);

  const container = useRef();
  const dispatch = useDispatch();

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  const openSignup = () => {
    if (!profileData) dispatch(openModal("signup"));
    else navigate("/home");
  };

  return (
    <LandingPage ref={container}>
      <NarrowContent>
        <Hero>
          <LeftContent>
            <ShortHeading id="hero-short-heading">
              Amplify Your Business
            </ShortHeading>
            <MainHeading>Get Leads, Drive Traffic, Earn Rewards</MainHeading>
            <SubHeading>
              Effortlessly Connect with Thousands of Potential Customers for
              Free
            </SubHeading>
            <List>
              <ListItem>
                <ListIcon>
                  <img src={circlePointBlueIcon} style={{ width: "100%" }} />
                </ListIcon>
                <ListText>
                  Unlimited growth opportunities without costly barriers
                </ListText>
              </ListItem>
              <ListItem>
                <ListIcon>
                  <img src={circlePointBlueIcon} style={{ width: "100%" }} />
                </ListIcon>
                <ListText>
                  Engage directly with prospects through intuitive features
                </ListText>
              </ListItem>
              <ListItem>
                <ListIcon>
                  <img src={circlePointBlueIcon} style={{ width: "100%" }} />
                </ListIcon>
                <ListText>
                  Earn cash rewards through referrals and contests
                </ListText>
              </ListItem>
            </List>
            <HeroParagraph>
              Scalelup is a free promotion platform that helps businesses of all
              sizes showcase their products and services to a global audience.
              Simplify your marketing efforts, expand your network, and drive
              sales while earning rewards .
            </HeroParagraph>
          </LeftContent>
          {!tabletScreen && (
            <RightSide>
              {/* <MockupColumn>
                <RainbowBox>
                  <RainbowBg />
                  <PhoneBox>
                    <img src={phoneMockup1} style={{ width: "100%" }} />
                    <MockupDisplay display="one" />
                  </PhoneBox>
                </RainbowBox>
              </MockupColumn> */}
              <VideoColumn>
                <VideoBox>
                  <ReactPlayer
                    url="https://1733686985.rsc.cdn77.org/gw/video/Scalelup-V1-comp-3.mp4"
                    controls={true}
                    light={videoCover}
                    playing={true}
                    width="100%"
                    height="100%"
                  />
                </VideoBox>
              </VideoColumn>
            </RightSide>
          )}
        </Hero>
        {tabletScreen && (
          // <MockupColumn>
          //   <RainbowBox>
          //     <RainbowBg />
          //     <PhoneBox>
          //       <img src={phoneMockup1} style={{ width: "100%" }} />
          //       <MockupDisplay display="one" />
          //     </PhoneBox>
          //   </RainbowBox>
          // </MockupColumn>
          <VideoColumn>
            <VideoBox>
              <ReactPlayer
                url="https://1733686985.rsc.cdn77.org/gw/video/Scalelup-V1-comp-3.mp4"
                controls={true}
                light={videoCover}
                playing={true}
                width="100%"
                height="100%"
              />
            </VideoBox>
          </VideoColumn>
        )}
        <HeroButtonWrap>
          <ButtonPrimary onClick={openSignup}>
            Create Free Account
          </ButtonPrimary>
        </HeroButtonWrap>
      </NarrowContent>
      <HidingMenuPublic />
    </LandingPage>
  );
};
