import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import dotIndigoIcon from "assets/img/icons/icons8-dot-indigo.svg";

const Item = styled(Link)`
  width: 100%;
  margin-top: 5px;

  color: #fff;
  text-decoration: none;
`;

const ItemBackground = styled.div`
  width: 100%;
  height: 42px;
  padding-left: ${(props) => (props.wide ? "40px" : "25px")};
  box-sizing: border-box;

  border-radius: ${(props) => (props.wide ? "0" : "21px")};
  background: ${(props) =>
    props.current ? css.FLAT_BOX_BACKGROUND : "transparent"};

  transition: 0.2s;

  &:hover {
    background: ${css.FLAT_BOX_BACKGROUND};
  }
`;

const ItemBox = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ItemIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  box-sizing: border-box;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const DotIcon = styled.div`
  margin-right: 10px;

  width: 20px;
  min-width: 20px;
  height: 20px;

  @media (max-width: ${globalConstants.switchTablet}) {
    margin-right: 30px;
  }
`;

const ItemText = styled.div`
  padding-left: 15px;

  font-size: 18px;
  font-weight: ${(props) =>
    props.current || props.emphasized ? "500" : "inherit"};
  color: ${(props) => (props.emphasized ? css.BRAND_GREEN : "inherit")};
  letter-spacing: 0.2px;
`;

export default ({
  to,
  icon,
  iconCurrent,
  children,
  emphasized,
  target = "_self",
  dot,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const tabletScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchTablet})`,
  });

  let currentPath = pathname === to;

  if (pathname === "/chat" && to === "/messages") currentPath = true;

  return (
    <Item to={to} target={target}>
      <ItemBackground current={currentPath ? 1 : 0} wide={tabletScreen ? 1 : 0}>
        <ItemBox>
          <ItemLeft>
            <ItemIcon icon={currentPath ? iconCurrent : icon} />
            <ItemText
              current={currentPath ? 1 : 0}
              emphasized={emphasized ? 1 : 0}
            >
              {children}
            </ItemText>
          </ItemLeft>
          {dot && (
            <DotIcon>
              <img src={dotIndigoIcon} style={{ width: "100%" }} />
            </DotIcon>
          )}
        </ItemBox>
      </ItemBackground>
    </Item>
  );
};
