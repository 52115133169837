import styled from "styled-components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import * as css from "styles/CssVariables.js";

import Backdrop from "components/other/Backdrop";
import Menu from "./Menu";
import MenuSmall from "./MenuSmall";

import scalelupLogo from "assets/img/logo/scalelup.svg";
import facebookIcon from "assets/img/icons/icons8-facebook.svg";

const HidingMenu = styled.div`
  position: fixed;
  bottom: ${(props) => (props.opened ? "0" : "-115%")};
  left: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  padding-top: 85px;
  box-sizing: border-box;

  overflow-y: auto;

  background: ${css.BLUE_BOX_GRADIENT};

  z-index: 200;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease 0s;
`;

const Header = styled.div`
  width: 100%;
  padding: 0px 20px 20px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoWrap = styled.div`
  width: 140px;

  &:hover {
    cursor: pointer;
  }
`;

const FooterCopyright = styled.div`
  margin-top: 14px;
  padding-left: 40px;

  color: ${css.TEXT_GREY};
  font-family: "Barlow", sans-serif;
  font-size: 16px;
`;

const SocialIcon = styled.div`
  margin: 12px 0 80px 38px;
  width: 34px;
  min-width: 34px;
  height: 34px;
`;

export default ({ unreadMessages }) => {
  const LayoutProperties = createSelector(
    (state) => state.Layout,
    (layout) => ({
      hidingMenu: layout.hidingMenu,
    })
  );

  const { hidingMenu } = useSelector(LayoutProperties);

  return (
    <>
      <HidingMenu opened={hidingMenu ? 1 : 0}>
        <Header>
          <LogoWrap style={{ color: "#fff", fontSize: "22px" }}>
            <img src={scalelupLogo} style={{ width: "100%" }} />
          </LogoWrap>
        </Header>
        <Menu unreadMessages={unreadMessages} />
        <MenuSmall />
        <FooterCopyright>© 2024 Scalelup</FooterCopyright>
        <SocialIcon>
          <a href="https://www.facebook.com/scalelup" target="_blank">
            <img src={facebookIcon} style={{ width: "100%" }} alt="Facebook" />
          </a>
        </SocialIcon>
      </HidingMenu>
      {hidingMenu && <Backdrop />}
    </>
  );
};
