import { useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useSearchParams, useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import Chat from "./Chat";

// Actions
import { updateChatEnabled, setDisabledModal } from "store/actions";

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const postId = searchParams.get("postid") ? searchParams.get("postid") : null;
  const postShardNumber = searchParams.get("sn")
    ? searchParams.get("sn")
    : null;

  const [coinsEarned, setCoinsEarned] = useState(0);

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      chatEnabled: user.chatEnabled,
    })
  );

  const { chatEnabled } = useSelector(UserProperties);

  useEffect(() => {
    if (!postId || !postShardNumber) {
      // missing Post ID or Post shard number
      navigate("/discover");
    }
  }, []);

  // Fetch post data
  const GET_POST_PREVIEW_DATA = gql`
    query GetPostPreviewData($postId: ID, $shardNumber: Int!) {
      postPreviewData(
        postId: $postId
        postSnapshotId: null
        shardNumber: $shardNumber
      ) {
        id
        text
        image
        image_link
        user {
          id
          username
          profile_image
        }
      }
    }
  `;

  const { data, error } = useQuery(GET_POST_PREVIEW_DATA, {
    variables: {
      postId,
      shardNumber: Number(postShardNumber),
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error fetching post preview data", error);
    captureException(error);
    navigate("/discover");
  }

  // Check if chat is enabled
  const CHECK_CHAT_ENABLED = gql`
    mutation CheckChatEnabled {
      checkChatEnabled {
        enabled
        coinsEarned
      }
    }
  `;

  const [checkChatEnabled] = useMutation(CHECK_CHAT_ENABLED);

  const checkEnabled = async () => {
    try {
      const checkResult = await checkChatEnabled();
      console.log("checkResult", checkResult);

      if (checkResult.data.checkChatEnabled.enabled) {
        dispatch(updateChatEnabled(true));
      } else {
        dispatch(setDisabledModal(true));
        setCoinsEarned(checkResult.data.checkChatEnabled.coinsEarned);
      }
    } catch (error) {
      captureException(error);
      console.log("Check chat enabled error", error);
    }
  };

  const onInputFocus = async () => {
    if (!chatEnabled) {
      checkEnabled();
    }
  };

  //console.log("data", data);

  let chatData;

  if (data) {
    chatData = {
      chat: {
        profileImage: data.postPreviewData.user.profile_image,
        username: data.postPreviewData.user.username,
        messages: [
          {
            id: "0",
            created_at: new Date(),
            chat_id: "0",
            message: data.postPreviewData.text,
            read: false,
            post_id: postId,
            image: data.postPreviewData.image,
          },
        ],
      },
    };
  }

  return (
    <Chat
      data={chatData}
      partnerShardNumber={postShardNumber}
      postId={postId}
      onInputFocus={onInputFocus}
      checkEnabled={checkEnabled}
      coinsEarned={coinsEarned}
    />
  );
};
