import styled from "styled-components";

import { AWS_PROFILE_IMAGES_URL } from "constants";

import ModalWindow from "components/other/ModalWindow";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import PostContent from "components/posts/PostContent";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

const PostPreview = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseButtonWrap = styled.div`
  margin-top: 35px;
`;

const LoadingBox = styled.div`
  width: 342px;
  height: 400px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ handleModalClose, data }) => {
  //console.log("data", data);

  return (
    <ModalWindow
      modal="postPreview"
      handleModalClose={handleModalClose}
      fullScreenHeight={true}
    >
      <PostPreview>
        {data?.postPreviewData && (
          <>
            <PostContent
              isPreview={true}
              isPostMessagePreview={true}
              avatar={
                AWS_PROFILE_IMAGES_URL + data.postPreviewData.user.profile_image
              }
              username={data.postPreviewData.user.username}
              createdAt={data.postPreviewData.created_at}
              text={data.postPreviewData.text}
              image={data.postPreviewData.image}
              imageLink={data.postPreviewData.image_link}
            />
            <CloseButtonWrap>
              <ButtonPrimary onClick={handleModalClose}>Close</ButtonPrimary>
            </CloseButtonWrap>
          </>
        )}
        {!data?.postPreviewData && (
          <LoadingBox>
            <BoxLoadingSpinner height="300px" />
          </LoadingBox>
        )}
      </PostPreview>
    </ModalWindow>
  );
};
