import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";

import store from "./store";
import client from "./client";
import "react-tooltip/dist/react-tooltip.css";

Sentry.init({
  dsn: "https://1180bae5f6f00bbc0550e91f9624c438@o4506354325848064.ingest.us.sentry.io/4508479559565312",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/scalelup\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HttpsRedirect>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <GoogleOAuthProvider clientId="860017276357-1lu4q3k2pjm6hsvoj93fl4hhn6oq0lq5.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </ApolloProvider>
    </Provider>
  </HttpsRedirect>
);

reportWebVitals();

if (module.hot) {
  module.hot.accept();
}
