import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import * as css from "styles/CssVariables.js";

const TextLink = styled(Link)`
  color: ${css.LIGHT_BLUE};
  line-height: 125%;
  text-decoration: none;

  &:hover {
    filter: brightness(120%);
    cursor: pointer;
  }
`;

export default ({ to, size, children, onClick, parameters }) => {
  const location = useLocation();

  const handleClick = () => {
    if (onClick) {
      onClick(parameters);
    }

    return;
  };

  let path = to;

  if (!path) path = location.pathname + location.search;

  return (
    <TextLink to={path} size={size} onClick={handleClick}>
      {children}
    </TextLink>
  );
};
