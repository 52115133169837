import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";

import * as css from "styles/CssVariables.js";
import { preloadImage } from "modules/functions";

import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";

import ButtonRoundIcon from "components/buttons/ButtonRoundIcon";

import plusIcon from "assets/img/icons/plus-sign.svg";
import plusWhiteIcon from "assets/img/icons/plus-sign-white.svg";

const SideStats = styled.div`
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
`;

const StatsBox = styled.div`
  padding: ${css.BOX_PADDING_TERTIARY};
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${css.VERTICAL_BOX_MARGIN};

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Value = styled.div`
  font-size: ${(props) => {
    if (!props.value) return "26px";

    const digits = props.value.toString().length;
    if (digits > 3) return "22px";
    if (digits > 2) return "24px";
    return "26px";
  }};
  line-height: 1;
  display: flex;
  align-items: flex-end;
`;

const ChangeBadge = styled.div`
  height: 16px;
  padding: 0 4px 1px;
  margin-left: 5px;
  margin-bottom: 2px;

  font-size: 11px;

  border-radius: 8px;
  background: ${(props) =>
    props.green ? css.POSITIVE_GREEN : css.NEGATIVE_RED};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChartNote = styled.div`
  font-size: 11px;
  color: ${css.TEXT_GREY};
  text-align: right;
`;

const Chart = styled.div``;

export default ({ data }) => {
  const navigate = useNavigate();

  preloadImage(plusWhiteIcon);

  const newPostClick = () => {
    navigate("/modify-post");
  };

  let lastWeekImpressions;
  let previousWeekImpressions;
  let lastWeekInteractions;
  let previousWeekInteractions;

  let lastWeekImpsChange;
  let lastWeekIntsChange;

  let impressionsChartData = [];
  let interactionsChartData = [];

  if (data) {
    lastWeekImpressions = data.sideStats.lastWeekImpressions;
    previousWeekImpressions = data.sideStats.previousWeekImpressions;
    lastWeekInteractions = data.sideStats.lastWeekInteractions;
    previousWeekInteractions = data.sideStats.previousWeekInteractions;

    lastWeekImpsChange =
      previousWeekImpressions > 0
        ? (100 / previousWeekImpressions) * lastWeekImpressions - 100
        : "-";

    lastWeekIntsChange =
      previousWeekInteractions > 0
        ? (100 / previousWeekInteractions) * lastWeekInteractions - 100
        : "-";

    for (let i = 0; i <= 6; i++) {
      impressionsChartData.push(
        data.sideStats.last7DaysStats[i]?.impressions || 0
      );
      interactionsChartData.push(
        data.sideStats.last7DaysStats[i]?.interactions || 0
      );
    }
  }

  const impressionsSparkData = impressionsChartData.reverse();
  const interactionsSparkData = interactionsChartData.reverse();

  const getSparkColor = (data) => {
    if (data[data.length - 1] >= data[0]) return css.POSITIVE_GREEN;
    return css.NEGATIVE_RED;
  };

  const reduceValue = (value) => {
    if (value < 10000) return value;
    return Math.round(value / 1000, 0) + "k";
  };

  const changeBadge = (change) => {
    if (typeof change !== "number") return <></>;

    if (change > 100) return <ChangeBadge green={1}>&gt;100%</ChangeBadge>;

    const roundCoef = change >= 10 || change <= -10 ? 1 : 10;
    let changeRounded = Math.round(change * roundCoef) / roundCoef;

    if (changeRounded >= 0)
      return <ChangeBadge green={1}>+{changeRounded}%</ChangeBadge>;
    else return <ChangeBadge green={0}>{changeRounded}%</ChangeBadge>;
  };

  return (
    <SideStats>
      {!data && <BoxLoadingSpinner height="200px" padding="0 0 38px 0" />}
      {data && (
        <>
          <StatsBox>
            <LeftPart>
              <Title>Active Posts</Title>
              <Value>{data.sideStats.activePosts}</Value>
            </LeftPart>
            <RightPart>
              <ButtonRoundIcon
                buttonSize="32px"
                iconSize="10px"
                icon={plusIcon}
                hoverIcon={plusWhiteIcon}
                background={css.BRAND_YELLOW}
                hoverBackground={css.BRAND_GREEN}
                onClick={newPostClick}
              />
            </RightPart>
          </StatsBox>
          <StatsBox>
            <LeftPart>
              <Title>Impressions</Title>
              <Value value={lastWeekImpressions}>
                {reduceValue(lastWeekImpressions)}
                {changeBadge(lastWeekImpsChange)}
              </Value>
            </LeftPart>
            <RightPart>
              <ChartNote>7 days</ChartNote>
              <Chart>
                <SparkLineChart
                  data={impressionsSparkData}
                  width={80}
                  height={35}
                  curve="catmullRom"
                  colors={[getSparkColor(impressionsSparkData)]}
                />
              </Chart>
            </RightPart>
          </StatsBox>
          <StatsBox>
            <LeftPart>
              <Title>Interactions</Title>
              <Value value={lastWeekInteractions}>
                {reduceValue(lastWeekInteractions)}
                {changeBadge(lastWeekIntsChange)}
              </Value>
            </LeftPart>
            <RightPart>
              <ChartNote>7 days</ChartNote>
              <Chart>
                <SparkLineChart
                  data={interactionsSparkData}
                  width={80}
                  height={35}
                  curve="catmullRom"
                  colors={[getSparkColor(interactionsSparkData)]}
                />
              </Chart>
            </RightPart>
          </StatsBox>
        </>
      )}
    </SideStats>
  );
};
