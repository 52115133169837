import styled from "styled-components";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { isEmail } from "validator";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { captureException } from "@sentry/react";

import {
  AUTH_ID,
  AUTH_TOKEN,
  AUTH_WS_TOKEN,
  SHARD_NUMBER,
  REF_CODE,
} from "constants";
import globalConstants from "constants/globalConstants";
import Login from "./Login";

// Actions
import { openModal } from "store/actions";

const LoginContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);

  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

export default ({ handleModalClose, openErrorWindow, ipAddress }) => {
  const dispatch = useDispatch();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    // email: "",
    // password: "",
  });
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [loginMessage, setLoginMessage] = useState(null);
  const [showResendLink, setShowResendLink] = useState(false);
  const [verificationResent, setVerificationResent] = useState(false);
  const [emailToBeVerified, setEmailToBeVerified] = useState(null);
  const [loadingResendVerification, setLoadingResendVerification] =
    useState(false);
  const [loadingLoginGoogle, setLoadingLoginGoogle] = useState(false);
  const [loadingLoginApple, setLoadingLoginApple] = useState(false);
  const [loadingLoginFacebook, setLoadingLoginFacebook] = useState(false);

  const handleInputChange = (e) => {
    if (loadingLogin) {
      return;
    }

    const { target } = e;

    let newCredentials = { ...credentials };

    newCredentials[target.name] = target.value;

    setCredentials(newCredentials);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitLoginForm();
    }
  };

  const openSignup = () => {
    dispatch(openModal("signup"));
  };

  const openForgot = () => {
    dispatch(openModal("forgot"));
  };

  // handle Form submit
  const LOGIN = gql`
    mutation EmailLogin(
      $email: String!
      $password: String!
      $ipAddress: String
    ) {
      login(email: $email, password: $password, ipAddress: $ipAddress) {
        resultCode
        id
        token
        wsToken
        shardNumber
      }
    }
  `;

  const [login] = useMutation(LOGIN);

  const submitLoginForm = async () => {
    try {
      if (loadingLogin || loadingLoginGoogle || loadingLoginApple) return;

      setWarnings([]);
      setLoginMessage(null);
      setShowResendLink(false);

      const { email, password } = credentials;

      // validate inputs
      let newWarnings = [];

      if (
        email.trim().length < 5 ||
        email.trim().length > 100 ||
        !isEmail(email)
      ) {
        newWarnings.push("email");
      }

      if (password.trim().length > 100 || password.trim().length === 0) {
        newWarnings.push("password");
      }

      if (newWarnings.length !== 0) {
        setWarnings(newWarnings);
        return;
      }

      setLoadingLogin(true);

      const result = await login({
        variables: {
          email: email.trim(),
          password: password.trim(),
          ipAddress,
        },
      });

      const { resultCode, id, token, wsToken, shardNumber } = result.data.login;

      if (resultCode === 2) {
        // user already logged, delete tokens for fresh login
        localStorage.removeItem(AUTH_ID);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_WS_TOKEN);
        localStorage.removeItem(SHARD_NUMBER);
        window.location = "/";
        return;
      }

      if (resultCode === 4) {
        // email not found
        setLoginMessage("This email is not registered with us.");
        setWarnings(["email"]);
        setLoadingLogin(false);
        return;
      }

      if (resultCode === 5) {
        // too many login attempts
        setLoginMessage("Too many login attempts. Please try again later.");
        setLoadingLogin(false);
        return;
      }

      if (resultCode === 6) {
        // invalid password
        setLoginMessage("The password entered is incorrect.");
        setWarnings(["password"]);
        setLoadingLogin(false);
        return;
      }

      if (resultCode === 7) {
        // account not activated
        setEmailToBeVerified(credentials.email);

        setLoginMessage(
          "Your email is pending verification. Please follow the link in the activation email we sent you."
        );
        setShowResendLink(true);
        setLoadingLogin(false);
        return;
      }

      if (resultCode !== 0 || !token) {
        // login failed
        setLoadingLogin(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // login successfull, redirect to account
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      window.location = "/home";

      setLoadingLogin(false);
    } catch (error) {
      console.log("login error", error);
      captureException(error);
      setLoadingLogin(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // handle Google Login
  const GOOGLE_LOGIN = gql`
    mutation GoogleLogin(
      $accessToken: String!
      $ipAddress: String
      $sponsorCode: String
    ) {
      googleLogin(
        accessToken: $accessToken
        ipAddress: $ipAddress
        sponsorCode: $sponsorCode
      ) {
        resultCode
        token
        wsToken
        id
        shardNumber
      }
    }
  `;

  const [googleLogin] = useMutation(GOOGLE_LOGIN);

  const loginGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        if (
          loadingLogin ||
          loadingLoginGoogle ||
          loadingLoginApple ||
          loadingLoginFacebook
        )
          return;

        setLoadingLoginGoogle(true);

        // prepare Sponsor code
        let sponsorCode = null;

        if (localStorage.getItem(REF_CODE))
          sponsorCode = localStorage.getItem(REF_CODE);

        const result = await googleLogin({
          variables: {
            accessToken: codeResponse.access_token,
            ipAddress,
            sponsorCode,
          },
        });

        const { resultCode, id, token, wsToken, shardNumber } =
          result.data.googleLogin;

        if (resultCode === 2) {
          // user already logged, delete tokens for fresh login
          setLoadingLoginGoogle(false);
          handleModalClose();
          openErrorWindow();

          localStorage.removeItem(AUTH_ID);
          localStorage.removeItem(AUTH_TOKEN);
          localStorage.removeItem(AUTH_WS_TOKEN);
          localStorage.removeItem(SHARD_NUMBER);
          window.location = "/";
          return;
        }

        if (resultCode === 9) {
          setLoadingLoginGoogle(false);
          setLoginMessage("This account is not registered with us.");

          return;
        }

        if (resultCode !== 0 || !token) {
          // login failed
          setLoadingLoginGoogle(false);
          handleModalClose();
          openErrorWindow();

          return;
        }

        // login successfull, redirect to account
        localStorage.setItem(AUTH_ID, id);
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem(AUTH_WS_TOKEN, wsToken);
        localStorage.setItem(SHARD_NUMBER, shardNumber);
        window.location = "/home";
      } catch (error) {
        captureException(error);
        setLoadingLoginGoogle(false);
        handleModalClose("login");
        openErrorWindow();
        return;
      }
    },
    onError: (error) => captureException(error),
  });

  // handle Apple login
  const APPLE_LOGIN = gql`
    mutation AppleLogin(
      $code: String!
      $ipAddress: String
      $sponsorCode: String
    ) {
      appleLogin(
        code: $code
        ipAddress: $ipAddress
        sponsorCode: $sponsorCode
      ) {
        resultCode
        token
        wsToken
        id
        shardNumber
      }
    }
  `;

  const [appleLogin] = useMutation(APPLE_LOGIN);

  const loginApple = async (message) => {
    try {
      if (!message.authorization) return;

      if (
        loadingLogin ||
        loadingLoginGoogle ||
        loadingLoginApple ||
        loadingLoginFacebook
      )
        return;

      setLoadingLoginApple(true);

      // prepare Sponsor code
      let sponsorCode = null;

      if (localStorage.getItem("ref"))
        sponsorCode = localStorage.getItem("ref");

      const result = await appleLogin({
        variables: { code: message.authorization.code, ipAddress, sponsorCode },
      });

      const { resultCode, token, wsToken, id, shardNumber } =
        result.data.appleLogin;

      if (resultCode === 2) {
        // user already logged, delete tokens for fresh login
        setLoadingLoginApple(false);
        handleModalClose();
        openErrorWindow();

        localStorage.removeItem(AUTH_ID);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_WS_TOKEN);
        localStorage.removeItem(SHARD_NUMBER);
        window.location = "/";
        return;
      }

      if (resultCode === 9) {
        setLoadingLoginApple(false);
        setLoginMessage("This account is not registered with us.");

        return;
      }

      if (resultCode !== 0 || !token) {
        // login failed
        setLoadingLoginApple(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // login successfull, redirect to account
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      window.location = "/home";
    } catch (error) {
      captureException(error);
      setLoadingLoginApple(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // handle Facebook login
  const FACEBOOK_LOGIN = gql`
    mutation FacebookLogin(
      $accessToken: String!
      $ipAddress: String
      $sponsorCode: String
    ) {
      facebookLogin(
        accessToken: $accessToken
        ipAddress: $ipAddress
        sponsorCode: $sponsorCode
      ) {
        resultCode
        token
        wsToken
        id
        shardNumber
      }
    }
  `;

  const [facebookLogin] = useMutation(FACEBOOK_LOGIN);

  const loginFacebook = async (response) => {
    try {
      if (!response.accessToken) return;

      if (
        loadingLogin ||
        loadingLoginGoogle ||
        loadingLoginApple ||
        loadingLoginFacebook
      )
        return;

      setLoadingLoginFacebook(true);

      // prepare Sponsor code
      let sponsorCode = null;

      if (localStorage.getItem("ref"))
        sponsorCode = localStorage.getItem("ref");

      const result = await facebookLogin({
        variables: {
          accessToken: response.accessToken,
          ipAddress,
          sponsorCode,
        },
      });

      const { resultCode, token, wsToken, id, shardNumber } =
        result.data.facebookLogin;

      if (resultCode === 2) {
        // user already logged, delete tokens for fresh login
        setLoadingLoginFacebook(false);
        handleModalClose();
        openErrorWindow();

        localStorage.removeItem(AUTH_ID);
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_WS_TOKEN);
        localStorage.removeItem(SHARD_NUMBER);
        window.location = "/";
        return;
      }

      if (resultCode === 10) {
        setLoadingLoginFacebook(false);
        setLoginMessage("This account is not registered with us.");

        return;
      }

      if (resultCode !== 0 || !token) {
        // login failed
        setLoadingLoginFacebook(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      // login successfull, redirect to account
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      window.location = "/home";
    } catch (error) {
      captureException(error);
      setLoadingLoginFacebook(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  // resend Verification email
  const RESEND_ACCOUNT_VERIFICATION = gql`
    mutation ResendAccountVerification($email: String!) {
      resendAccountVerification(email: $email) {
        resultCode
      }
    }
  `;

  const [resendAccountVerification] = useMutation(RESEND_ACCOUNT_VERIFICATION);

  const onResendVerification = async () => {
    try {
      if (loadingResendVerification || !emailToBeVerified) {
        return;
      }

      setLoadingResendVerification(true);

      const result = await resendAccountVerification({
        variables: { email: emailToBeVerified },
      });
      const { resultCode } = result.data.resendAccountVerification;

      if (resultCode !== 0 && resultCode !== 5) {
        // activation resend failed
        setLoadingResendVerification(false);
        handleModalClose();
        openErrorWindow();

        return;
      }

      setVerificationResent(true);
      setLoadingResendVerification(false);

      return;
    } catch (error) {
      captureException(error);
      setLoadingResendVerification(false);
      handleModalClose();
      openErrorWindow();
      return;
    }
  };

  return (
    <LoginContainer>
      <Login
        handleModalClose={handleModalClose}
        handleInputChange={handleInputChange}
        credentials={credentials}
        submitLoginForm={submitLoginForm}
        loadingLogin={loadingLogin}
        warnings={warnings}
        loginMessage={loginMessage}
        showResendLink={showResendLink}
        verificationResent={verificationResent}
        openModal={openModal}
        onResendVerification={onResendVerification}
        emailToBeVerified={emailToBeVerified}
        handleKeyDown={handleKeyDown}
        loginGoogle={loginGoogle}
        loginApple={loginApple}
        openSignup={openSignup}
        openForgot={openForgot}
        loginFacebook={loginFacebook}
      />
    </LoginContainer>
  );
};
