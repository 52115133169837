import {
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  OPEN_HIDING_MENU,
  CLOSE_HIDING_MENU,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_HIDING_MENU_PUBLIC,
  CLOSE_HIDING_MENU_PUBLIC,
  UPDATE_UNREAD_MESSAGES,
  SET_DISABLED_MODAL,
} from "./actionTypes";

const INIT_STATE = {
  messageModal: false,
  messageModalData: {},
  loginModal: false,
  hidingMenu: false,
  hidingMenuPublic: false,
  signupModal: false,
  appMessage: null,
  forgotModal: false,
  unreadMessages: false,
  disabledModal: false,
};
const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: true,
        messageModalData: action.payload,
      };

    case CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: false,
        messageModalData: {},
      };

    case OPEN_HIDING_MENU:
      return {
        ...state,
        hidingMenu: true,
      };

    case CLOSE_HIDING_MENU:
      return {
        ...state,
        hidingMenu: false,
      };

    case OPEN_HIDING_MENU_PUBLIC:
      return {
        ...state,
        hidingMenuPublic: true,
      };

    case CLOSE_HIDING_MENU_PUBLIC:
      return {
        ...state,
        hidingMenuPublic: false,
      };

    case OPEN_MODAL:
      let newModals = {
        loginModal: false,
        signupModal: false,
        messageModal: false,
        forgotModal: false,
      };

      const stateName = action.payload + "Modal";
      newModals[stateName] = true;

      return {
        ...state,
        ...newModals,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        ...{
          loginModal: false,
          signupModal: false,
          messageModal: false,
          forgotModal: false,
        },
      };

    case UPDATE_UNREAD_MESSAGES:
      return {
        ...state,
        unreadMessages: action.payload,
      };

    case SET_DISABLED_MODAL:
      return {
        ...state,
        disabledModal: action.payload,
      };

    default:
      return state;
  }
};

export default Layout;
