import React from "react";
import styled from "styled-components";

const BadgeTitle = styled.div`
  box-sizing: border-box;
  height: 38px;
  padding: 0 30px;
  min-width: ${(props) => (props.minwidth ? props.minwidth : "auto")};

  background: ${(props) => props.background};
  border-radius: 19px;
  border: 0;

  font-size: ${(props) => (props.fontsize ? props.fontsize : "16px")};
  font-weight: 500;
  color: ${(props) => (props.textcolor ? props.textcolor : "#fff")};
  white-space: nowrap;
  line-height: 1;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default (props) => {
  const { background, textColor, fontSize, minWidth, children } = props;
  return (
    <BadgeTitle
      background={background}
      textcolor={textColor}
      fontsize={fontSize}
      minwidth={minWidth}
    >
      {children}
    </BadgeTitle>
  );
};
