import styled from "styled-components";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";

import ButtonPrimaryIcon from "components/buttons/ButtonPrimaryIcon";

import plusIcon from "assets/img/icons/plus-sign.svg";
import plusWhiteIcon from "assets/img/icons/plus-sign-white.svg";

const CreateFirstPostBox = styled.div`
  width: 100%;
  padding: 60px 35px;
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonWrap = styled(Link)`
  text-decoration: none;
`;

const Text = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  max-width: 440px;

  text-align: center;
`;

export default () => {
  return (
    <CreateFirstPostBox>
      <ButtonWrap to="/modify-post">
        <ButtonPrimaryIcon icon={plusIcon} iconHover={plusWhiteIcon}>
          Create Post
        </ButtonPrimaryIcon>
      </ButtonWrap>
      <Text>
        Create your first post, introduce your business to others, and attract
        visitors to your website.
      </Text>
    </CreateFirstPostBox>
  );
};
