import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { numberWithCommas, preloadImage } from "modules/functions";
import { PACKAGES } from "constants";
import { COUNTRY_ICONS } from "constants/countries";
import Layout from "components/Layout";
import PageTitle from "components/text/PageTitle";
import SectionTitle from "components/text/SectionTitle";
import InputWithLabel from "components/forms/InputWithLabel";
import InputText from "components/forms/InputText";
import Dropdown from "components/forms/Dropdown";
import DropdownSelection from "components/forms/DropdownSelection";
import SelectionItem from "components/forms/SelectionItem";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import MessageBox from "components/other/MessageBox";

import bankCardIcon from "assets/img/icons/icons8-bank-card-dollar.svg";
import coinsIcon from "assets/img/icons/coins-icon.svg";
import stripeButtonImage from "assets/img/accepting-cards.svg";
import confirmoButtonImage from "assets/img/confirmo-currencies.svg";

const PurchaseCoins = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const Box = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN_XLARGE};
`;

const IntroPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardIcon = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;
`;

const Text = styled.div`
  line-height: 125%;
`;

const PackagesPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const PackagesBox = styled.div`
  width: 100%;
  padding: 20px 0;
  margin-top: 8px;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
  gap: 0;

  @media (max-width: ${globalConstants.switchMobile}) {
    gap: 12px;
  }
`;

const BuyButton = styled.div`
  box-sizing: border-box;
  height: 28px;
  padding: 0 15px;

  background: ${(props) =>
    props.selected ? css.BRAND_GREEN : css.BRAND_YELLOW};
  border-radius: 14px;
  border: 0;

  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.selected ? "#fff" : css.DARK_BLUE)};
  white-space: nowrap;

  transition: 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    color: #fff;
    background: ${css.BRAND_GREEN};
  }
`;

const Package = styled.div`
  width: 100%;
  padding: 6px 25px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  background-color: ${(props) => (props.selected ? "#314a61" : "inherit")};

  transition: 0.2s;

  &:hover {
    background-color: #314a61;
    cursor: pointer;
  }

  &:hover ${BuyButton} {
    color: #fff;
    background: ${css.BRAND_GREEN};
  }
`;

const PackageMobileCoins = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const PackageCoins = styled.div`
  min-width: 160px;

  display: flex;
  align-items: center;
  gap: 10px;
`;

const PackageCoinsIcon = styled.div`
  width: 22px;
  min-width: 22px;
  height: 22px;
`;

const PackageCoinsText = styled.div`
  font-weight: 500;
  font-size: 18px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 16px;
  }
`;

const PackagePricePerCoin = styled.div`
  font-size: 18px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 16px;
  }
`;

const PackageButtonBox = styled.div`
  width: 100px;
`;

const BillingBoxWrap = styled.div`
  min-height: ${(props) => (props.extend ? "600px" : "auto")};
`;

const BillingForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const DropdownWrap = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const CountrySelectionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const CountrySelectionIcon = styled.div`
  width: 26px;
  min-width: 26px;
  height: 26px;
`;

const CountrySelectionText = styled.div``;

const FormButtonWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  display: flex;
`;

const Warning = styled.div`
  margin-top: 5px;

  font-size: 13px;
  font-weight: 500;
  color: ${css.ERROR_RED};
`;

const PaymentMethods = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  @media (max-width: ${globalConstants.switchMobile}) {
    gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  }
`;

export const PaymentMethodOptions = styled.div`
  width: 100%;
  margin: 10px 0;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  @media (max-width: ${globalConstants.switchMobile}) {
    gap: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  }
`;

const PaymentMethodOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
    gap: 7px;
  }
`;

const PaymentMethodOptionText = styled.div`
  font-weight: 400;
  line-height: 1;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 13px;
  }
`;

const PaymentMethodButton = styled.div`
  box-sizing: border-box;
  padding: 7px 25px;
  width: 100%;
  max-width: 230px;

  font-family: "Roboto", sans-serif;

  background: ${(props) => (props.disabled ? "#cdcdcd" : "#fff")};
  border-radius: 21px;
  border: 0;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;

  ${(props) =>
    !props.disabled &&
    `&:hover {
    cursor: pointer;

    color: #fff;
  }`}
`;

const PaymentMethodButtonText = styled.div`
  color: ${css.DARK_BLUE};
  font-weight: 500;
  font-size: 14px;

  white-space: nowrap;
`;

const MethodImage = styled.div`
  height: 22px;
`;

const SmallInfo = styled.div`
  font-size: 13px;
  color: ${css.TEXT_GREY};
  line-height: 125%;
`;

export default ({
  data,
  selectPackage,
  selectedPackage,
  billing,
  handleInputChange,
  warnings,
  toggleCountrySelection,
  onCountrySelect,
  countrySelected,
  countrySelectionOpened,
  billingFormContinue,
  enteredBilling,
  billingFormEdit,
  stripeDisabled,
  confirmoDisabled,
  stripeCheckout,
  confirmoCheckout,
  coingateCheckout,
}) => {
  const countryDropdownRef = useRef();
  const countrySelectionRef = useRef();
  //const formButtonRef = useRef();

  const mobileScreen = useMediaQuery({
    query: `(max-width: ${globalConstants.switchMobile})`,
  });

  preloadImage(stripeButtonImage);
  preloadImage(confirmoButtonImage);

  const [searchParams] = useSearchParams();

  const [failedBox, setFailedBox] = useState(false);

  useEffect(() => {
    const failed = searchParams.get("canceled") === "true";

    if (failed) setFailedBox(true);
  }, []);

  const closeMessageBox = () => {
    setFailedBox(false);
  };

  function useSelectionOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !countryDropdownRef.current.contains(event.target)
          //!formButtonRef.current.contains(event.target)
        ) {
          toggleCountrySelection();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useSelectionOutsideClick(countrySelectionRef);

  const prices = data ? data.billingData.prices : [];

  let selectedPackagePrice;

  const packageList = prices.map((priceData) => {
    const coins = Number(priceData.product.split("_")[0]);
    const price = priceData.price / 100;

    const pricePerCoin = price / coins;

    const selected = coins === selectedPackage;

    if (selected) selectedPackagePrice = price;

    if (!mobileScreen) {
      return (
        <Package
          onClick={() => selectPackage(coins)}
          selected={selected ? 1 : 0}
          key={coins}
        >
          <PackageCoins>
            <PackageCoinsIcon>
              <img src={coinsIcon} style={{ width: "100%" }} />
            </PackageCoinsIcon>
            <PackageCoinsText>{numberWithCommas(coins)} Coins</PackageCoinsText>
          </PackageCoins>
          <PackagePricePerCoin>${pricePerCoin} USD/Coin</PackagePricePerCoin>
          <PackageButtonBox>
            <BuyButton selected={selected ? 1 : 0}>${price}</BuyButton>
          </PackageButtonBox>
        </Package>
      );
    } else {
      return (
        <Package
          onClick={() => selectPackage(coins)}
          selected={selected ? 1 : 0}
          key={coins}
        >
          <PackageMobileCoins>
            <PackageCoins>
              <PackageCoinsIcon>
                <img src={coinsIcon} style={{ width: "100%" }} />
              </PackageCoinsIcon>
              <PackageCoinsText>
                {numberWithCommas(coins)} Coins
              </PackageCoinsText>
            </PackageCoins>
            <PackagePricePerCoin>${pricePerCoin} USD/Coin</PackagePricePerCoin>
          </PackageMobileCoins>
          <PackageButtonBox>
            <BuyButton selected={selected ? 1 : 0}>${price}</BuyButton>
          </PackageButtonBox>
        </Package>
      );
    }
  });

  let countryOptionsList = [];

  Object.keys(COUNTRY_ICONS).forEach((country) => {
    countryOptionsList.push(
      <SelectionItem onClick={() => onCountrySelect(country)} key={country}>
        <CountrySelectionItem>
          <CountrySelectionIcon>
            <img
              src={COUNTRY_ICONS[country].icon}
              alt={country}
              style={{ width: "100%" }}
            />
          </CountrySelectionIcon>
          <CountrySelectionText>{country}</CountrySelectionText>
        </CountrySelectionItem>
      </SelectionItem>
    );
  });

  let vatInfo = "";

  if (data?.billingData.vatRate) {
    vatInfo = ` (+ ${data.billingData.vatRate}% tax)`;
  }

  return (
    <PurchaseCoins>
      {failedBox && (
        <MessageBox
          title="Payment Failed"
          text="An error occurred during the payment. Please try again."
          closeBox={closeMessageBox}
          margin="0 0 24px 0"
        />
      )}
      <Box>
        <IntroPart>
          <BoxTitle>
            <CardIcon>
              <img src={bankCardIcon} style={{ width: "100%" }} />
            </CardIcon>
            Get Coins Instantly Added to Your Account
          </BoxTitle>
          <Text>
            Purchase a coin package to ensure your post continues to be
            displayed to thousands of potential business partners every day.
          </Text>
        </IntroPart>
        <PackagesPart>
          <BoxTitle>Select Coin Package</BoxTitle>
          <PackagesBox>{packageList}</PackagesBox>
          <SmallInfo>
            * It is not possible to receive a refund for unused coins. Coins
            never expire.
          </SmallInfo>
        </PackagesPart>
      </Box>
      {/* {selectedPackage && (
        <BillingBoxWrap extend={countrySelectionOpened ? 1 : 0}>
          <SectionTitle>Billing Address</SectionTitle>
          <Box>
            <BillingForm>
              <BoxTitle>Enter Billing Details</BoxTitle>
              <InputWithLabel label="Your Name" width="290px" marginTop="5px">
                <InputText
                  type="text"
                  name="name"
                  value={billing.name}
                  placeholder="Enter your name"
                  onChange={handleInputChange}
                  maxLength={100}
                  disabled={enteredBilling}
                />
                {warnings.includes("billingName") && (
                  <Warning>Please enter your full name.</Warning>
                )}
              </InputWithLabel>
              <InputWithLabel label="Country" width="290px">
                <DropdownWrap>
                  <Dropdown
                    onClick={toggleCountrySelection}
                    dropdownRef={countryDropdownRef}
                    warning={warnings.includes("paymentMethod")}
                    disabled={enteredBilling}
                  >
                    {!countrySelected && "Select country"}
                    {countrySelected && (
                      <CountrySelectionItem>
                        <CountrySelectionIcon>
                          <img
                            src={COUNTRY_ICONS[countrySelected].icon}
                            alt={countrySelected}
                            style={{ width: "100%" }}
                          />
                        </CountrySelectionIcon>
                        <CountrySelectionText>
                          {countrySelected}
                        </CountrySelectionText>
                      </CountrySelectionItem>
                    )}
                  </Dropdown>
                  {countrySelectionOpened && (
                    <DropdownSelection
                      width="290px"
                      top="48px"
                      selectionRef={countrySelectionRef}
                    >
                      {countryOptionsList}
                    </DropdownSelection>
                  )}
                </DropdownWrap>
                {warnings.includes("billingCountry") && (
                  <Warning>Please select your country of residence.</Warning>
                )}
              </InputWithLabel>
              <FormButtonWrap>
                <ButtonPrimary
                  onClick={
                    enteredBilling ? billingFormEdit : billingFormContinue
                  }
                >
                  {enteredBilling ? "Edit" : "Continue"}
                </ButtonPrimary>
              </FormButtonWrap>
            </BillingForm>
          </Box>
        </BillingBoxWrap>
      )} */}
      {selectedPackage && (
        <>
          <SectionTitle>Payment Method</SectionTitle>
          <Box>
            <PaymentMethods>
              <BoxTitle>Select Payment Method</BoxTitle>
              <Text>
                You are about to purchase {numberWithCommas(selectedPackage)}{" "}
                Coins for ${selectedPackagePrice} USD{vatInfo}.
              </Text>
              <PaymentMethodOptions>
                <PaymentMethodOption>
                  <PaymentMethodButton
                    onClick={() => {
                      if (stripeDisabled) return;
                      stripeCheckout();
                    }}
                    disabled={stripeDisabled}
                  >
                    <MethodImage>
                      <img
                        src={stripeButtonImage}
                        alt="Pay with Credit Card"
                        style={{ width: "100%" }}
                        height="22"
                      />
                    </MethodImage>
                  </PaymentMethodButton>
                  {/* <PaymentMethodOptionText>
                    Powered by Stripe{" "}
                    {stripeDisabled ? "(Temporarily disabled)" : ""}
                  </PaymentMethodOptionText> */}
                  <PaymentMethodOptionText>
                    {stripeDisabled ? "Temporarily disabled" : ""}
                  </PaymentMethodOptionText>
                </PaymentMethodOption>
                <PaymentMethodOption>
                  <PaymentMethodButton
                    onClick={() => {
                      if (confirmoDisabled) return;
                      //confirmoCheckout();
                      coingateCheckout();
                    }}
                    disabled={confirmoDisabled}
                  >
                    <PaymentMethodButtonText>Pay with</PaymentMethodButtonText>
                    <MethodImage>
                      <img
                        src={confirmoButtonImage}
                        alt="Pay with Cryptocurrency"
                        style={{ width: "100%" }}
                        height="22"
                      />
                    </MethodImage>
                    <PaymentMethodButtonText>+ more</PaymentMethodButtonText>
                  </PaymentMethodButton>
                  {/* <PaymentMethodOptionText>
                    Powered by Confirmo{" "}
                    {confirmoDisabled ? "(Temporarily disabled)" : ""}
                  </PaymentMethodOptionText> */}
                  <PaymentMethodOptionText>
                    {confirmoDisabled ? "Temporarily disabled" : ""}
                  </PaymentMethodOptionText>
                </PaymentMethodOption>
              </PaymentMethodOptions>
              <Text>
                After selecting a payment method, you will be redirected to the
                payment gateway.
              </Text>
            </PaymentMethods>
          </Box>
        </>
      )}
    </PurchaseCoins>
  );
};
