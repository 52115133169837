import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { captureException } from "@sentry/react";

import LoadingPage from "components/other/LoadingPage";

export default () => {
  const [searchParams] = useSearchParams();

  const x = searchParams.get("x");
  const y = searchParams.get("y");

  if (!x || !y) {
    window.location = "/?message=accountEmailVerificationFailed";
    return;
  }

  const VERIFY_ADDED_EMAIL = gql`
    mutation VerifyAddedEmail($id: ID!, $code: String!) {
      verifyAddedEmail(id: $id, code: $code) {
        resultCode
      }
    }
  `;

  const [verifyAddedEmail] = useMutation(VERIFY_ADDED_EMAIL);

  const processVerification = async () => {
    try {
      const result = await verifyAddedEmail({
        variables: { id: x, code: y },
      });

      const { resultCode } = result.data.verifyAddedEmail;

      if (resultCode === 2 || resultCode === 3) {
        window.location = "/?message=accountEmailVerificationExpiredLink";
        return;
      }

      if (resultCode !== 0) {
        window.location = "/?message=accountEmailVerificationFailed";
        return;
      }

      // verification successfull, redirect to account
      window.location = "/?message=emailVerifiedSuccessfully";
    } catch (error) {
      captureException(error);
      console.log("processVerification error", error);
      return;
    }
  };

  useEffect(() => {
    processVerification();
  }, []);

  return <LoadingPage />;
};
