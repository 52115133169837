import { useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { captureException } from "@sentry/react";

import AffiliateRevenues from "./AffiliateRevenues";
import LoadingBox from "components/other/LoadingBox";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import SectionTitle from "components/text/SectionTitle";

export default () => {
  const [page, setPage] = useState(1);

  // Fetch data
  const GET_AFFILIATE_REVENUES_DATA = gql`
    query GetAffiliateRevenuesData($page: Int!) {
      affiliateRevenues(page: $page) {
        revenues {
          id
          created_at
          level
          referral_id
          indirect_referral_id
          revenue
        }
        referralProfiles
        numOfResults
      }
    }
  `;

  const { data, error } = useQuery(GET_AFFILIATE_REVENUES_DATA, {
    variables: {
      page,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching affiliate revenue data error", error);
    captureException(error);
  }

  const limit = 10;

  if (data) {
    if (data.affiliateRevenues.numOfResults > 0)
      if (page > Math.ceil(data.affiliateRevenues.numOfResults / limit))
        setPage(1);
  }

  const paginate = (where) => {
    if (where === "previous") setPage((prevState) => prevState - 1);
    if (where === "next") setPage((prevState) => prevState + 1);
  };

  console.log({ data });

  return (
    <>
      {!data && (
        <>
          <SectionTitle>Affiliate Sales</SectionTitle>
          <LoadingBox>
            <BoxLoadingSpinner height="230px" />
          </LoadingBox>
        </>
      )}
      {data?.affiliateRevenues.revenues.length > 0 && (
        <>
          <SectionTitle>Affiliate Sales</SectionTitle>
          <AffiliateRevenues
            data={data}
            page={page}
            limit={limit}
            paginate={paginate}
          />
        </>
      )}
    </>
  );
};
