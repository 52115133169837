export const OPEN_MESSAGE_MODAL = "OPEN_MESSAGE_MODAL";
export const CLOSE_MESSAGE_MODAL = "CLOSE_MESSAGE_MODAL";
export const OPEN_HIDING_MENU = "OPEN_HIDING_MENU";
export const CLOSE_HIDING_MENU = "CLOSE_HIDING_MENU";
export const OPEN_HIDING_MENU_PUBLIC = "OPEN_HIDING_MENU_PUBLIC";
export const CLOSE_HIDING_MENU_PUBLIC = "CLOSE_HIDING_MENU_PUBLIC";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_UNREAD_MESSAGES = "UPDATE_UNREAD_MESSAGES";
export const SET_DISABLED_MODAL = "SET_DISABLED_MODAL";
