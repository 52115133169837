import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";

import { AUTH_ID } from "constants";
import SideEvents from "./SideEvents";

export default () => {
  // Fetch data
  const GET_SIDE_EVENTS_DATA = gql`
    query GetSideEventsData {
      events {
        rewardsPayouts {
          id
          created_at
          amount
        }
        referralRewards {
          id
          created_at
          amount
          data
        }
        impressions {
          id
          registered_at
          post {
            name
          }
        }
        interactions {
          id
          registered_at
          post {
            name
          }
        }
      }
    }
  `;

  const sn = "s" + localStorage.getItem("sn");

  const EVENTS_SUBSCRIPTION = gql`
    subscription onNewEvent($userId: uuid!, $timeLimit: timestamptz!) {
      ${sn} {
        users(where: { id: { _eq: $userId }, status: { _eq: "active" } }) {
          rewardsPayouts: transactions(
            where: {
              type: { _eq: "rewardsPayout" }
              created_at: { _gte: $timeLimit }
            }
            order_by: { created_at: desc }
            limit: 5
          ) {
            id
            created_at
            amount
          }
          referralRewards: transactions(
            where: {
              type: { _eq: "referralReward" }
              created_at: { _gte: $timeLimit }
            }
            order_by: { created_at: desc }
            limit: 5
          ) {
            id
            created_at
            amount
            data
          }
          impressions(
            where: {
              status: { _eq: "registered" }
              registered_at: { _gt: $timeLimit }
            }
            order_by: { registered_at: desc }
            limit: 5
          ) {
            id
            registered_at
            post {
              id
              name
            }
          }
          interactions: impressions(
            where: {
              status: { _eq: "registered" }
              registered_at: { _gt: $timeLimit }
              interacted: { _eq: true }
            }
            order_by: { registered_at: desc }
            limit: 5
          ) {
            id
            registered_at
            post {
              id
              name
            }
          }
        }
      }
    }
  `;

  const { subscribeToMore, data, error } = useQuery(GET_SIDE_EVENTS_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("error fetching side events", error);
    captureException(error);
  }

  //console.log("data", data);

  const userId = localStorage.getItem(AUTH_ID);
  const timeLimit = new Date();

  return (
    <SideEvents
      data={data}
      subscribeToNewEvents={() =>
        subscribeToMore({
          document: EVENTS_SUBSCRIPTION,
          variables: { userId, timeLimit },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;

            if (Object.keys(prev).length === 0) return prev;

            if (!subscriptionData.data[sn]?.users) return prev;
            if (!subscriptionData.data[sn].users[0]) return prev;

            const {
              impressions,
              interactions,
              rewardsPayouts,
              referralRewards,
            } = subscriptionData.data[sn].users[0];

            let toReturn = {
              events: {
                impressions: [...prev.events.impressions],
                interactions: [...prev.events.interactions],
                rewardsPayouts: [...prev.events.rewardsPayouts],
                referralRewards: [...prev.events.referralRewards],
              },
            };

            if (impressions[0]) {
              const existingImpression = prev.events.impressions.filter(
                (event) => event.id === impressions[0].id
              );

              if (existingImpression.length === 0) {
                toReturn.events.impressions.push(impressions[0]);
              }
            }

            if (interactions[0]) {
              const existingInteraction = prev.events.interactions.filter(
                (event) => event.id === interactions[0].id
              );

              if (existingInteraction.length === 0) {
                toReturn.events.interactions.push(interactions[0]);
              }
            }

            if (rewardsPayouts[0]) {
              const existingRewardsPayout = prev.events.rewardsPayouts.filter(
                (event) => event.id === rewardsPayouts[0].id
              );

              if (existingRewardsPayout.length === 0) {
                toReturn.events.rewardsPayouts.push(rewardsPayouts[0]);
              }
            }

            if (referralRewards[0]) {
              const existingReferralReward = prev.events.referralRewards.filter(
                (event) => event.id === referralRewards[0].id
              );

              if (existingReferralReward.length === 0) {
                toReturn.events.referralRewards.push(referralRewards[0]);
              }
            }

            return toReturn;
          },
        })
      }
    />
  );
};
