import { useEffect } from "react";
import styled from "styled-components";

import globalConstants from "constants/globalConstants";
import Layout from "components/Layout";
import PageTitle from "components/text/PageTitle";
import AccountSettingsContainer from "./AccountSettingsContainer";
import SectionTitle from "components/text/SectionTitle";
import PayoutSettingsContainer from "./PayoutSettingsContainer";
import ProfileSettings from "./ProfileSettings";

const Settings = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Settings | Scalelup";
  }, []);

  return (
    <Layout>
      <Settings>
        <PageTitle>Settings</PageTitle>
        <ProfileSettings />
        <SectionTitle>Account Settings</SectionTitle>
        <AccountSettingsContainer />
        <SectionTitle>Payout Settings</SectionTitle>
        <PayoutSettingsContainer />
      </Settings>
    </Layout>
  );
};
