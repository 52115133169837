import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";

import toolsIcon from "assets/img/icons/icons8-tools.svg";

const MaintenancePage = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 700px;
  padding: 0;
  box-sizing: border-box;

  font-family: "Montserrat", sans-serif;
  color: #fff;
`;

const MaintenanceContent = styled.div`
  padding: 0 10vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const MaintenanceIcon = styled.div`
  width: 80px;
  min-width: 80px;
  height: 80px;
`;

const MaintenanceTitle = styled.div`
  margin-top: 30px;

  font-size: 48px;

  @media (max-width: ${globalConstants.switchMobile}) {
    font-size: 32px;
  }
`;

const MaintenanceText = styled.div`
  margin-top: ${(props) => props.margintop};
  max-width: 700px;

  font-size: 18px;
  line-height: 130%;
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${css.LIGHT_BLUE};
`;

const Timer = styled.div`
  margin-top: 6px;

  font-size: 26px;
`;

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(targetDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 60000); // Aktualizace každou minutu

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
};

export default () => {
  // set page Title
  useEffect(() => {
    document.title = "Maintenance | Scalelup";
  }, []);

  const time = new Date("December 14, 2024 13:00:00");

  return (
    <>
      <MaintenancePage>
        <MaintenanceContent>
          <MaintenanceIcon>
            <img src={toolsIcon} alt="Error" style={{ width: "100%" }} />
          </MaintenanceIcon>
          <MaintenanceTitle>
            Scheduled maintenance is in progress
          </MaintenanceTitle>
          <MaintenanceText margintop="10px">
            We apologize for the inconvenience. We are upgrading our servers to
            improve our services. We'll be back soon.
          </MaintenanceText>
          <MaintenanceText margintop="30px">
            The website will be available in:
          </MaintenanceText>
          <Timer>
            <CountdownTimer targetDate={time} />
          </Timer>
          <MaintenanceText margintop="80px">
            You can reach us on the{" "}
            <TextLink to="https://help.scalelup.com/">support page</TextLink>.
          </MaintenanceText>
        </MaintenanceContent>
      </MaintenancePage>
    </>
  );
};
