import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import EmojiPicker from "emoji-picker-react";
import Linkify from "linkify-react";

import * as css from "styles/CssVariables.js";
import globalConstants from "constants/globalConstants";
import { AWS_USER_UPLOAD_URL, AWS_PROFILE_IMAGES_URL } from "constants";

import Layout from "components/Layout";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import SectionTitle from "components/text/SectionTitle";
import InputWithLabel from "components/forms/InputWithLabel";
import InputText from "components/forms/InputText";
import ButtonIndigoIcon from "components/buttons/ButtonIndigoIcon";
import BoxLoadingSpinner from "components/other/BoxLoadingSpinner";
import ButtonWhite from "components/buttons/ButtonWhite";
import RoundActionIcon from "components/buttons/RoundActionIcon";
import ButtonPrimarySmallIcon from "components/buttons/ButtonPrimarySmallIcon";
import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import ButtonWhiteSmall from "components/buttons/ButtonWhiteSmall";
import Backdrop from "components/other/Backdrop";
import ModalWindow from "components/other/ModalWindow";
import ButtonSecondary from "components/buttons/ButtonSecondary";
import PostContent from "./PostContent";

import aiEditIcon from "assets/img/icons/ai-edit-icon.svg";
import smileyIcon from "assets/img/icons/icons8-smiley.svg";
import binIcon from "assets/img/icons/bin-icon.svg";
import binWhiteIcon from "assets/img/icons/bin-icon-white.svg";

const CreatePostTutorial = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;

  margin: 0 0 80px 0;
  padding: 0 28px;

  display: flex;
  flex-direction: column;

  @media (max-width: ${globalConstants.switchTablet}) {
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackIconWrap = styled.div``;

const Box = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;
  margin-top: ${(props) => (props.margintop ? props.margintop : 0)};

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
  font-size: 24px;
`;

const Instructions = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  max-width: 520px;

  line-height: 130%;
  text-align: center;
`;

const Buttons = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  margin-bottom: 12px;
  width: 100%;

  display: flex;
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
  align-items: flex-end;
  flex: 1;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

const TextInputBox = styled.div`
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  margin-top: 30px;

  background: ${css.DARK_BLUE};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: flex;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  padding: 25px;

  font-family: "Roboto";
  font-weight: 300;
  font-size: 15px;
  color: #fff;
  line-height: 125%;

  background: ${css.DARK_BLUE};
  border: 0px;
  border-radius: ${css.BOX_RADIUS_SECONDARY};
  resize: none;
`;

const TextInputFooter = styled.div`
  height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CharacterCounter = styled.div`
  position: relative;
  margin-left: 25px;
`;

const EmptyCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: rgb(255, 255, 255, 0.07);
`;

const OuterCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 22px;
  height: 22px;
  transform: translate(-50%, -50%);

  border-radius: 50%;
  background: ${(props) => (props.atmax ? css.ERROR_RED : css.BRAND_INDIGO)};
  mask: conic-gradient(
    from 0deg,
    #0000,
    #000 1deg ${(props) => props.angle}deg,
    #0000 calc(${(props) => props.angle}deg + 1deg)
  );
`;

const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  transform: translate(-50%, -50%);

  background: ${css.DARK_BOX_BACKGROUND};
  border-radius: 50%;
`;

const RightControls = styled.div`
  padding: 0 18px 0 25px;

  display: flex;
  align-items: center;
`;

const TextAreaIconCircle = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;

  border-radius: 18px;

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgb(255, 255, 255, 0.07);
    cursor: pointer;
  }
`;

const TextAreaIcon = styled.div`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.width};

  &:hover {
    cursor: pointer;
  }
`;

const FormBottom = styled.div`
  position: relative;
  width: 100%;
`;

const PickerWrap = styled.div`
  position: absolute;
  top: ${css.VERTICAL_BOX_MARGIN};
  right: 0;
  z-index: 1;
`;

const ConfirmTemplateWrap = styled.div`
  margin-top: 60px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmTemplateText = styled.div``;

const ConfirmTemplateButtons = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_XLARGE};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${css.BUTTONS_GAP};
`;

const PromptModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  transform: translateX(-50%) translateY(-50%);

  width: 100%;
  max-width: 442px;
  padding: 0 10px;
  box-sizing: border-box;
  z-index: 200;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const UnderBox = styled.div`
  margin-top: 20px;
  display: flex;
`;

const Warning = styled.div`
  margin-top: 7px;

  font-size: 13px;
  font-weight: 500;
  color: ${css.ERROR_RED};

  align-self: flex-start;
`;

const FileInput = styled.input`
  width: 100%;
  height: 42px;
  box-sizing: border-box;

  border: 0px;
  border-radius: 15px;

  background: ${css.DARK_BOX_BACKGROUND};

  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;

  &::file-selector-button {
    margin: 7px 14px 0 10px;
    height: 28px;
    padding: 0 15px;

    border: 0;
    border-radius: 14px;
    cursor: pointer;
    background: ${css.BRAND_YELLOW};

    transition: 0.2s;

    font-family: "Barlow";
    font-size: 13px;
    font-weight: 500;
  }

  &::file-selector-button:hover {
    color: #fff;

    background: ${css.BRAND_GREEN};
  }
`;

const ImagePreviewBox = styled.div`
  position: relative;
`;

const ImagePreview = styled.div`
  width: 100%;
  margin-top: ${css.VERTICAL_BOX_MARGIN};
  margin-bottom: 6px;

  display: flex;
`;

const ImagePreviewImage = styled.img`
  max-width: 100%;
  max-height: 320px;
  object-fit: contain;

  @media (max-width: ${globalConstants.switchMobile}) {
    width: 100%;
  }
`;

const DeleteImageOverlay = styled.div`
  position: absolute;
  bottom: 16px;
  right: 10px;
`;

const DeleteImageIcon = styled.div`
  width: 16px;
  height: 16px;

  transition: 0.2s;

  background-image: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: cover;
`;

const DeleteImageCircle = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;

  border-radius: 16px;
  background: ${css.BRAND_YELLOW};

  transition: 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    background: ${css.ERROR_RED};
  }

  &:hover ${DeleteImageIcon} {
    background-image: url(${(props) => props.iconhover});
  }
`;

const InputBox = styled.div`
  width: 100%;
  max-width: 480px;

  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const TipButtonLine = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_LARGE};
  margin-bottom: 12px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  @media (max-width: ${globalConstants.switchTablet}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

const MagicTip = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  flex-wrap: wrap;
`;

const MagicTipText = styled.div`
  white-space: nowrap;
  font-size: 14px;
`;

const MagicTipIcon = styled.div`
  width: 18px;
  min-width: 18px;
  height: 18px;
`;

export default ({
  step,
  text,
  onTextInputChange,
  onSmileyClick,
  pickerOpened,
  getPostTemplate,
  onFirstContinueClick,
  loading,
  pickerRef,
  usePickerOutsideClick,
  onEmojiClick,
  confirmTemplate,
  closeConfirmModal,
  addTemplateText,
  warnings,
  onImageInputChange,
  imageWarning,
  image,
  removeImage,
  userProfileData,
  onClickBack,
  imageLink,
  onImageLinkInputChange,
  onSecondContinueClick,
  onThirdContinueClick,
  onPostSubmit,
  blockModal,
  closeBlockModal,
  confirmLeaveForm,
}) => {
  const navigate = useNavigate();
  const textArea = useRef();

  // handle Textarea height
  useEffect(() => {
    if (!loading && textArea?.current) {
      textArea.current.style.height = "105px";

      const requestedHeight = Math.max(textArea.current.scrollHeight, 160);

      textArea.current.style.height = `${Math.min(requestedHeight, 600)}px`;
    }
  }, [text, loading, step]);

  const textMaximum = 500;
  const counterAngle = (360 / textMaximum) * text.length;

  usePickerOutsideClick(pickerRef);

  // prepare Image preview URL address
  const previewLink = AWS_USER_UPLOAD_URL + image;

  // prepare Profile picture
  const profilePictureURL =
    AWS_PROFILE_IMAGES_URL + userProfileData?.profile_image;

  console.log("userProfileData", userProfileData);

  return (
    <Layout noColumns={true}>
      <CreatePostTutorial>
        {step === 1 && (
          <>
            <Header>
              <SectionTitle marginTop="16px">
                Create First Post: Step 1 of 3
              </SectionTitle>
            </Header>
            <Box>
              {!loading && (
                <>
                  <Heading>Create Your First Post</Heading>
                  <Instructions>
                    Compose a post to promote your product, service, or business
                    opportunity. Once published, it will start appearing to
                    thousands of people.
                  </Instructions>
                  <TextInputBox>
                    <TextArea
                      ref={textArea}
                      name="text"
                      value={text}
                      placeholder="Share your business offer or anything you want. You will be able to edit the post later."
                      maxLength={textMaximum}
                      onChange={onTextInputChange}
                    />
                    <TextInputFooter>
                      <CharacterCounter>
                        <EmptyCircle />
                        <OuterCircle
                          angle={counterAngle}
                          atmax={text.length === textMaximum ? 1 : 0}
                        />
                        <InnerCircle />
                      </CharacterCounter>
                      <RightControls>
                        <TextAreaIconCircle onClick={onSmileyClick}>
                          <TextAreaIcon width="20px">
                            <img src={smileyIcon} style={{ width: "100%" }} />
                          </TextAreaIcon>
                        </TextAreaIconCircle>
                        <TextAreaIconCircle
                          onClick={getPostTemplate}
                          data-tooltip-id="templateTip"
                          data-tooltip-content="Click to insert a sample template text that will help you start creating an engaging post."
                          data-tooltip-place="top"
                          data-tooltip-delay-show={500}
                        >
                          <TextAreaIcon width="18px" className="magic-icon">
                            <img src={aiEditIcon} style={{ width: "100%" }} />
                          </TextAreaIcon>
                        </TextAreaIconCircle>
                        <Tooltip id="templateTip" className="tooltip" />
                      </RightControls>
                    </TextInputFooter>
                  </TextInputBox>
                  <FormBottom>
                    <PickerWrap ref={pickerRef}>
                      <EmojiPicker
                        open={pickerOpened}
                        onEmojiClick={onEmojiClick}
                        autoFocusSearch={false}
                        theme="dark"
                        width={280}
                        height={310}
                      />
                    </PickerWrap>
                  </FormBottom>
                  {warnings.includes("text") && (
                    <Warning>
                      The post must be at least 20 characters long.
                    </Warning>
                  )}
                  <TipButtonLine>
                    <MagicTip>
                      <MagicTipText>
                        You can use the magic pencil icon
                      </MagicTipText>
                      <MagicTipIcon>
                        <img src={aiEditIcon} style={{ width: "100%" }} />
                      </MagicTipIcon>
                      <MagicTipText>to select a template post.</MagicTipText>
                    </MagicTip>
                    <ButtonPrimary onClick={onFirstContinueClick}>
                      Continue
                    </ButtonPrimary>
                  </TipButtonLine>
                  {confirmTemplate && (
                    <>
                      <Backdrop
                        onClick={closeConfirmModal}
                        background="rgba(0, 0, 0, 0.7)"
                      />
                      <PromptModal>
                        <ModalWindow
                          title="Add Post Template"
                          handleModalClose={closeConfirmModal}
                          fullScreenHeight={true}
                        >
                          <ConfirmTemplateWrap>
                            <ConfirmTemplateText>
                              Inserting the template text will remove the
                              content you have created so far. Do you want to
                              proceed?
                            </ConfirmTemplateText>
                            <ConfirmTemplateButtons>
                              <ButtonWhite onClick={closeConfirmModal}>
                                Cancel
                              </ButtonWhite>
                              <ButtonPrimary onClick={addTemplateText}>
                                Continue
                              </ButtonPrimary>
                            </ConfirmTemplateButtons>
                          </ConfirmTemplateWrap>
                        </ModalWindow>
                      </PromptModal>
                    </>
                  )}
                </>
              )}
              {loading && <BoxLoadingSpinner height="320px" />}
            </Box>
            <UnderBox>
              <ButtonWhite onClick={() => navigate("/home")}>
                Create Post Later
              </ButtonWhite>
            </UnderBox>
          </>
        )}
        {step === 2 && (
          <>
            <Header>
              <SectionTitle marginTop="16px">
                Create First Post: Step 2 of 3
              </SectionTitle>
            </Header>
            <Box>
              {!loading && (
                <>
                  <PostContent
                    isPreview={true}
                    isTutorialPreview={true}
                    avatar={profilePictureURL}
                    username={userProfileData.username}
                    text={text}
                    image={null}
                    imageLink=""
                  />
                </>
              )}
              {loading && <BoxLoadingSpinner height="320px" />}
            </Box>
            <Box margintop={css.VERTICAL_BOX_MARGIN_MEDIUM}>
              {!loading && (
                <>
                  <Heading>Insert an Image</Heading>
                  <Instructions>
                    Enhance your post with an image. It will help you attract
                    more attention to your offer (optional).
                  </Instructions>
                  <InputBox>
                    <FileInput
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onImageInputChange}
                    />
                    {imageWarning && (
                      <Warning>
                        The selected file is too large. The maximum allowed size
                        is 6000 kB.
                      </Warning>
                    )}
                  </InputBox>
                  {image && (
                    <ImagePreviewBox>
                      <ImagePreview>
                        <ImagePreviewImage src={previewLink} />
                      </ImagePreview>
                      <DeleteImageOverlay>
                        <DeleteImageCircle
                          iconhover={binWhiteIcon}
                          onClick={removeImage}
                        >
                          <DeleteImageIcon icon={binIcon} />
                        </DeleteImageCircle>
                      </DeleteImageOverlay>
                    </ImagePreviewBox>
                  )}
                  <Buttons>
                    <ButtonSecondary onClick={onClickBack}>
                      Back
                    </ButtonSecondary>
                    <ButtonPrimary onClick={onSecondContinueClick}>
                      {image ? "Continue" : "Skip"}
                    </ButtonPrimary>
                  </Buttons>
                </>
              )}
              {loading && <BoxLoadingSpinner height="320px" />}
            </Box>
            <UnderBox>
              <ButtonWhite onClick={() => navigate("/home")}>
                Create Post Later
              </ButtonWhite>
            </UnderBox>
          </>
        )}
        {step === 3 && (
          <>
            <Header>
              <SectionTitle marginTop="16px">
                Create First Post: Step 3 of 3
              </SectionTitle>
            </Header>
            <Box>
              {!loading && (
                <>
                  <PostContent
                    isPreview={true}
                    isTutorialPreview={true}
                    avatar={profilePictureURL}
                    username={userProfileData.username}
                    text={text}
                    image={image}
                    imageLink=""
                  />
                </>
              )}
              {loading && <BoxLoadingSpinner height="320px" />}
            </Box>
            <Box margintop={css.VERTICAL_BOX_MARGIN_MEDIUM}>
              {!loading && (
                <>
                  <Heading>Add a Link to the Image</Heading>
                  <Instructions>
                    Insert the link where the visitor will be redirected when
                    they click on the image (optional).
                  </Instructions>
                  <InputBox>
                    <InputText
                      type="text"
                      name="link"
                      value={imageLink}
                      placeholder="https://..."
                      onChange={onImageLinkInputChange}
                      maxLength={400}
                      warning={warnings.includes("link")}
                    />
                  </InputBox>
                  {warnings.includes("imageLink") && (
                    <Warning>
                      The entered URL is invalid. The address should start with
                      https://
                    </Warning>
                  )}
                  <Buttons>
                    <ButtonSecondary onClick={onClickBack}>
                      Back
                    </ButtonSecondary>
                    <ButtonPrimary onClick={onThirdContinueClick}>
                      {imageLink ? "Continue" : "Skip"}
                    </ButtonPrimary>
                  </Buttons>
                </>
              )}
              {loading && <BoxLoadingSpinner height="320px" />}
            </Box>
            <UnderBox>
              <ButtonWhite onClick={() => navigate("/home")}>
                Create Post Later
              </ButtonWhite>
            </UnderBox>
          </>
        )}
        {step === 4 && (
          <>
            <Header>
              <SectionTitle marginTop="16px">Review and Publish</SectionTitle>
            </Header>
            <Box>
              {!loading && (
                <>
                  <PostContent
                    isPreview={true}
                    isTutorialPreview={true}
                    avatar={profilePictureURL}
                    username={userProfileData.username}
                    text={text}
                    image={image}
                    imageLink={imageLink}
                  />
                  <Buttons>
                    <ButtonSecondary onClick={onClickBack}>
                      Back
                    </ButtonSecondary>
                    <ButtonPrimary onClick={onPostSubmit}>
                      Publish Post
                    </ButtonPrimary>
                  </Buttons>
                </>
              )}
              {loading && <BoxLoadingSpinner height="320px" />}
            </Box>

            <UnderBox>
              <ButtonWhite onClick={() => navigate("/home")}>
                Create Post Later
              </ButtonWhite>
            </UnderBox>
          </>
        )}
      </CreatePostTutorial>
      {blockModal && (
        <>
          <Backdrop onClick={closeBlockModal} background="rgba(0, 0, 0, 0.7)" />
          <PromptModal>
            <ModalWindow
              title="Leave and Discard Changes"
              handleModalClose={closeBlockModal}
              fullScreenHeight={true}
            >
              <ConfirmTemplateWrap>
                <ConfirmTemplateText>
                  You are about to leave the post editing form. Any changes you
                  have made will be lost.
                </ConfirmTemplateText>
                <ConfirmTemplateButtons>
                  <ButtonWhite onClick={closeBlockModal}>Cancel</ButtonWhite>
                  <ButtonPrimary onClick={confirmLeaveForm}>
                    Leave
                  </ButtonPrimary>
                </ConfirmTemplateButtons>
              </ConfirmTemplateWrap>
            </ModalWindow>
          </PromptModal>
        </>
      )}
    </Layout>
  );
};
