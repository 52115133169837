import styled from "styled-components";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

import * as css from "styles/CssVariables.js";
import { numberWithCommas } from "modules/functions";
import globalConstants from "constants/globalConstants";
import { AWS_PROFILE_IMAGES_URL } from "constants";

import ButtonPrimarySmall from "components/buttons/ButtonPrimarySmall";
import ButtonPrimary from "components/buttons/ButtonPrimary";
import Tip from "components/other/Tip";

import dollarAffiliateProgramIcon from "assets/img/icons/dollar-icon-affiliate-program.svg";
import directReferralsIcon from "assets/img/icons/direct-referrals-icon.svg";
import indirectReferralsGroupIcon from "assets/img/icons/indirect-referrals-group-icon.svg";
import indirectReferralsIcon from "assets/img/icons/indirect-referrals-icon.svg";
import dollarIcon from "assets/img/icons/dollar-icon.svg";
import referralsQualifiedIcon from "assets/img/icons/referral-qualified-icon.svg";
import percentIcon from "assets/img/icons/percent-icon.svg";
import revenueIcon from "assets/img/icons/Subscription-Cashflow--Streamline-Core.svg";
import referralsIcon from "assets/img/icons/referrals-icon.svg";
import emptyIcon from "assets/img/icons/empty-icon.svg";

const AffiliateProgramBox = styled.div`
  width: 100%;
  padding: ${css.BOX_PADDING_PRIMARY};
  box-sizing: border-box;

  background: ${css.BLUE_BOX_GRADIENT};
  border-radius: ${css.BOX_RADIUS_PRIMARY};

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};
`;

const BoxTitle = styled.div`
  width: 100%;

  font-size: ${css.BOX_TITLE_FONT_SIZE};
  font-weight: ${css.BOX_TITLE_FONT_WEIGHT};

  display: flex;
  align-items: center;
  gap: 8px;
`;

const DollarIcon = styled.div`
  width: 26px;
  min-width: 26px;
  height: 26px;
`;

const Text = styled.div`
  line-height: 125%;
`;

const Schema = styled.div`
  margin: ${css.VERTICAL_BOX_MARGIN_LARGE} 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

const SchemaProfilePicture = styled.div`
  width: 52px;
  min-width: 52px;
  height: 52px;
`;

const AvatarImage = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const SchemaGraphics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SchemaLevel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0;

  @media (max-width: ${globalConstants.switchMobile}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
  }
`;

const SchemaLevelText = styled.div`
  width: 160px;

  font-size: 18px;
  font-weight: 500;
`;

const SchemaLevelRight = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const SchemaWrap = styled.div`
  min-width: 60px;

  @media (max-width: ${globalConstants.switchMobile}) {
    min-width: 0px;
  }
`;

const SchemaIcon = styled.div`
  height: 24px;
`;

const SchemaRateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SchemaRateCashIcon = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
`;

const SchemaRateText = styled.div`
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
`;

const UnlockBox = styled.div`
  margin: ${css.VERTICAL_BOX_MARGIN_MEDIUM} 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const UnlockTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const UnlockBar = styled.div`
  position: relative;
  height: 28px;
  width: 100%;
  max-width: 300px;

  overflow: hidden;

  border-radius: 14px;
  background: ${css.BRAND_INDIGO};
`;

const UnlockBarProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.progresspercent}%;
  box-sizing: border-box;

  background: ${css.BRAND_GREEN};
`;

const UnlockCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: help;
  }
`;

const UnlockCounterIcon = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
`;

const UnlockCounterText = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const QualifiedSmallInfo = styled.div`
  font-size: 13px;
  color: ${css.TEXT_GREY};
  line-height: 125%;
`;

const UnlockButtonWrap = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN_MEDIUM};
`;

const Warning = styled.div`
  margin-top: 7px;

  font-size: 13px;
  font-weight: 500;
  color: ${css.ERROR_RED};
`;

const OverviewData = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DataTitle = styled.div`
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 6px;
`;

const DataRow = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const DataIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DataIcon = styled.div`
  width: ${(props) => (props.width ? props.width : "20px")};
  min-width: ${(props) => (props.width ? props.width : "20px")};
  height: ${(props) => (props.width ? props.width : "20px")};
`;

const DataText = styled.div`
  white-space: nowrap;
`;

const StatsBoxes = styled.div`
  margin-top: ${css.VERTICAL_BOX_MARGIN};

  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const StatsBoxTitle = styled.div`
  width: 100%;

  font-size: 16px;
  font-weight: 500;
`;

const AffiliateStats = styled.div`
  max-width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 1;
`;

const StatsBox = styled.div`
  padding: 15px 20px;
  width: 100%;

  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};
`;

const TableWrap = styled.div`
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: -5px;

  overflow-x: auto;
`;

const StatsBoxGrid = styled.div`
  min-width: 320px;

  display: grid;
  grid-template-columns: 1fr 73px 73px;
  grid-template-rows: 25px 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px 15px;
`;

const StatsLabel = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

const LabelIconCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LabelIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const LabelText = styled.div`
  font-size: 15px;
  font-weight: 300;
`;

const ValueCell = styled.div`
  height: 24px;

  font-size: 15px;

  display: flex;
  align-items: center;
`;

const ValueIconCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ValueIcon = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
`;

const EarningsHistory = styled.div`
  min-width: 215px;

  display: flex;
  flex-direction: column;
  gap: ${css.VERTICAL_BOX_MARGIN};

  flex: 0.65;
`;

const EarningsBox = styled.div`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  background: ${css.FLAT_BOX_BACKGROUND};
  border-radius: ${css.BOX_RADIUS_SECONDARY};

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px 15px;
`;

const Empty = styled.div`
  padding: 30px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 7;
`;

const EmptyIcon = styled.div`
  width: 26px;
  min-width: 26px;
  height: 26px;
`;

const EmptyText = styled.div`
  text-align: center;
`;

export default ({ data, unlockAffiliate, loadingUnlock, unlockError }) => {
  let isAffiliate = false;
  let qualifiedReferrals = 0;
  let directReferrals = 0;
  let indirectReferrals = 0;
  let totalCommissions = 0;
  let thisMonthDirectRevenue = 0;
  let directRate = 30;
  let thisMonthIndirectRevenue = 0;
  let indirectRate = 5;
  let thisMonthCommissions = 0;
  let lastMonthDirectRevenue = 0;
  let lastMonthDirectRate = 30;
  let lastMonthIndirectRevenue = 0;
  let lastMonthIndirectRate = 5;
  let lastMonthTotalCommissions = 0;

  if (data) {
    isAffiliate = data.affiliateProgramData.affiliate;

    qualifiedReferrals = data.affiliateProgramData.qualifiedReferrals;
    directReferrals = data.affiliateProgramData.directReferrals;
    indirectReferrals = data.affiliateProgramData.indirectReferrals;
    thisMonthDirectRevenue = data.affiliateProgramData.thisMonthDirectRevenue;
    directRate = data.affiliateProgramData.directRate;
    thisMonthIndirectRevenue =
      data.affiliateProgramData.thisMonthIndirectRevenue;
    indirectRate = data.affiliateProgramData.indirectRate;
    thisMonthCommissions = data.affiliateProgramData.thisMonthCommissions;
    if (data.affiliateProgramData.lastMonthEarnings) {
      lastMonthDirectRevenue =
        data.affiliateProgramData.lastMonthEarnings.direct_revenue;
      lastMonthDirectRate =
        data.affiliateProgramData.lastMonthEarnings.direct_rate;
      lastMonthIndirectRevenue =
        data.affiliateProgramData.lastMonthEarnings.indirect_revenue;
      lastMonthIndirectRate =
        data.affiliateProgramData.lastMonthEarnings.indirect_rate;
      lastMonthTotalCommissions =
        data.affiliateProgramData.lastMonthEarnings.total_commissions;
    }
    totalCommissions = data.affiliateProgramData.totalCommissions;
  }

  const UserProperties = createSelector(
    (state) => state.User,
    (user) => ({
      profileData: user.profileData,
    })
  );

  const { profileData } = useSelector(UserProperties);

  const unlockProgress = (100 / 5) * qualifiedReferrals;

  const canUnlock = !isAffiliate && qualifiedReferrals >= 5;

  // prepate Earnings History list
  const list =
    data?.affiliateProgramData.earningsHistory ||
    [].map((earning) => {
      return (
        <>
          <ValueCell>January 25</ValueCell>
          <ValueIconCell>
            <ValueIcon>
              <img src={dollarIcon} style={{ width: "100%" }} />
            </ValueIcon>
            <LabelText>$0</LabelText>
          </ValueIconCell>
        </>
      );
    });

  return (
    <AffiliateProgramBox>
      <BoxTitle>
        <DollarIcon>
          <img src={dollarAffiliateProgramIcon} style={{ width: "100%" }} />
        </DollarIcon>
        Create a Steady Residual Income
      </BoxTitle>
      <Text>
        Earn lifetime commissions from your referrals across two levels. Earn up
        to 40% from the first level and 5% from the second level on all
        purchases made by your network.
      </Text>
      <Schema>
        <SchemaProfilePicture>
          <AvatarImage
            src={AWS_PROFILE_IMAGES_URL + profileData.profileImage}
            style={{ width: "100%" }}
          />
        </SchemaProfilePicture>
        <SchemaGraphics>
          <SchemaLevel>
            <SchemaLevelText>Direct Referrals</SchemaLevelText>
            <SchemaLevelRight>
              <SchemaWrap>
                <SchemaIcon>
                  <img src={directReferralsIcon} style={{ height: "100%" }} />
                </SchemaIcon>
              </SchemaWrap>
              <SchemaRateBox>
                <SchemaRateCashIcon>
                  <img src={dollarIcon} style={{ height: "100%" }} />
                </SchemaRateCashIcon>
                <SchemaRateText>30% - 40%</SchemaRateText>
                <Tip
                  tip="The commission rate depends on the total purchase volume (revenue) of your direct referrals during a calendar month.
                <br><br>Revenue $0 - $999: 30%
                <br>Revenue $1000 - $4999: 35%
                <br>Revenue $5000+: 40%"
                />
              </SchemaRateBox>
            </SchemaLevelRight>
          </SchemaLevel>
          <SchemaLevel>
            <SchemaLevelText>Indirect Referrals</SchemaLevelText>
            <SchemaLevelRight>
              <SchemaWrap>
                <SchemaIcon>
                  <img
                    src={indirectReferralsGroupIcon}
                    style={{ height: "100%" }}
                  />
                </SchemaIcon>
              </SchemaWrap>
              <SchemaRateBox>
                <SchemaRateCashIcon>
                  <img src={dollarIcon} style={{ height: "100%" }} />
                </SchemaRateCashIcon>
                <SchemaRateText>5%</SchemaRateText>
                <Tip tip="Indirect referrals are users referred by your direct referrals." />
              </SchemaRateBox>
            </SchemaLevelRight>
          </SchemaLevel>
        </SchemaGraphics>
      </Schema>
      {!isAffiliate && (
        <>
          <Text>
            Get a total of 5 qualified referrals and start earning affiliate
            commissions.
          </Text>
          <UnlockBox>
            <UnlockTitle>Unlock Affiliate Earnings</UnlockTitle>
            <UnlockBar>
              <UnlockBarProgress progresspercent={unlockProgress} />
            </UnlockBar>
            <UnlockCounter
              data-tooltip-id="qualifiedReferralsTip"
              data-tooltip-html="Qualified Referrals"
              data-tooltip-place="top"
              data-tooltip-delay-show={500}
              onClick={(e) => e.stopPropagation()}
            >
              <UnlockCounterIcon>
                <img src={referralsQualifiedIcon} style={{ height: "100%" }} />
              </UnlockCounterIcon>

              <UnlockCounterText>{qualifiedReferrals}/5</UnlockCounterText>
            </UnlockCounter>
            <Tooltip id="qualifiedReferralsTip" className="tooltip" />
            {canUnlock && (
              <UnlockButtonWrap>
                <ButtonPrimary
                  onClick={unlockAffiliate}
                  disabled={loadingUnlock}
                >
                  Unlock
                </ButtonPrimary>
              </UnlockButtonWrap>
            )}
            {unlockError && <Warning>{unlockError}</Warning>}
          </UnlockBox>
          {!canUnlock && (
            <QualifiedSmallInfo>
              * The referred user must view at least 40 posts to become
              qualified.
            </QualifiedSmallInfo>
          )}
        </>
      )}
      {isAffiliate && (
        <>
          <OverviewData>
            <Data>
              <DataTitle>Direct Referrals</DataTitle>
              <DataRow>
                <DataIconBox>
                  <DataIcon>
                    <img src={referralsIcon} style={{ width: "100%" }} />
                  </DataIcon>
                </DataIconBox>
                <DataText>{numberWithCommas(directReferrals)}</DataText>
              </DataRow>
            </Data>
            <Data>
              <DataTitle>
                Indirect Referrals{" "}
                <Tip tip="Indirect referrals are users referred by your direct referrals. Data is updated once a day." />
              </DataTitle>
              <DataRow>
                <DataIconBox>
                  <DataIcon>
                    <img
                      src={indirectReferralsIcon}
                      style={{ width: "100%" }}
                    />
                  </DataIcon>
                </DataIconBox>
                <DataText>{numberWithCommas(indirectReferrals)}</DataText>
              </DataRow>
            </Data>{" "}
            <Data>
              <DataTitle>Total Commissions Earned</DataTitle>
              <DataRow>
                <DataIconBox>
                  <DataIcon>
                    <img src={dollarIcon} style={{ width: "100%" }} />
                  </DataIcon>
                </DataIconBox>
                <DataText>${numberWithCommas(totalCommissions)} USD</DataText>
              </DataRow>
            </Data>
          </OverviewData>
          <StatsBoxes>
            <AffiliateStats>
              <StatsBoxTitle>Referrals and Rewards</StatsBoxTitle>

              <StatsBox>
                <TableWrap className="table-wrap">
                  <StatsBoxGrid>
                    <StatsLabel>Data</StatsLabel>
                    <StatsLabel>This Month</StatsLabel>
                    <StatsLabel>Last Month</StatsLabel>
                    <LabelIconCell>
                      <LabelIcon>
                        <img src={revenueIcon} style={{ width: "100%" }} />
                      </LabelIcon>
                      <LabelText>Direct Revenue</LabelText>
                    </LabelIconCell>
                    <ValueCell>
                      ${numberWithCommas(thisMonthDirectRevenue)}
                    </ValueCell>
                    <ValueCell>
                      ${numberWithCommas(lastMonthDirectRevenue)}
                    </ValueCell>
                    <LabelIconCell>
                      <LabelIcon>
                        <img src={percentIcon} style={{ width: "100%" }} />
                      </LabelIcon>
                      <LabelText>Direct Rate</LabelText>
                    </LabelIconCell>
                    <ValueCell>{directRate}%</ValueCell>
                    <ValueCell>{lastMonthDirectRate}%</ValueCell>
                    <LabelIconCell>
                      <LabelIcon>
                        <img src={revenueIcon} style={{ width: "100%" }} />
                      </LabelIcon>
                      <LabelText>Indirect Revenue</LabelText>
                    </LabelIconCell>
                    <ValueCell>
                      ${numberWithCommas(thisMonthIndirectRevenue)}
                    </ValueCell>
                    <ValueCell>
                      ${numberWithCommas(lastMonthIndirectRevenue)}
                    </ValueCell>
                    <LabelIconCell>
                      <LabelIcon>
                        <img src={percentIcon} style={{ width: "100%" }} />
                      </LabelIcon>
                      <LabelText>Indirect Rate</LabelText>
                    </LabelIconCell>
                    <ValueCell>{indirectRate}%</ValueCell>
                    <ValueCell>{lastMonthIndirectRate}%</ValueCell>
                    <LabelIconCell>
                      <LabelIcon>
                        <img src={dollarIcon} style={{ width: "100%" }} />
                      </LabelIcon>
                      <LabelText>Commissions</LabelText>
                    </LabelIconCell>
                    <ValueCell>
                      ${numberWithCommas(thisMonthCommissions)}
                    </ValueCell>
                    <ValueCell>
                      ${numberWithCommas(lastMonthTotalCommissions)}
                    </ValueCell>
                  </StatsBoxGrid>
                </TableWrap>
              </StatsBox>
            </AffiliateStats>
            <EarningsHistory>
              <StatsBoxTitle>Earnings History</StatsBoxTitle>
              <EarningsBox>
                <StatsLabel>Month</StatsLabel>
                <StatsLabel>Earned</StatsLabel>
                {list}
                {list.length === 0 && (
                  <Empty>
                    <EmptyIcon>
                      <img src={emptyIcon} style={{ width: "100%" }} />
                    </EmptyIcon>
                    <EmptyText>No earnings yet</EmptyText>
                  </Empty>
                )}
              </EarningsBox>
            </EarningsHistory>
          </StatsBoxes>
        </>
      )}
    </AffiliateProgramBox>
  );
};
