import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { captureException } from "@sentry/react";

import { AUTH_ID, AUTH_TOKEN, AUTH_WS_TOKEN, SHARD_NUMBER } from "constants";
import LoadingPage from "components/other/LoadingPage";

export default () => {
  const [searchParams] = useSearchParams();

  const x = searchParams.get("x");
  const y = searchParams.get("y");

  if (!x || !y) {
    window.location = "/?message=accountEmailVerificationFailed";
    return;
  }

  const VERIFY_ACCOUNT_EMAIL = gql`
    mutation VerifyAccountEmail($id: ID!, $code: String!) {
      verifyAccountEmail(id: $id, code: $code) {
        resultCode
        id
        token
        wsToken
        shardNumber
      }
    }
  `;

  const [verifyAccountEmail] = useMutation(VERIFY_ACCOUNT_EMAIL);

  const processVerification = async () => {
    try {
      const result = await verifyAccountEmail({
        variables: { id: x, code: y },
      });

      const { resultCode, id, token, wsToken, shardNumber } =
        result.data.verifyAccountEmail;

      if (resultCode === 4) {
        window.location = "/?message=accountEmailVerificationExpiredLink";
        return;
      }

      if (resultCode !== 0 || !token) {
        window.location = "/?message=accountEmailVerificationFailed";
        return;
      }

      // verification successfull, redirect to account
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      window.location = "/onboarding?signup-success";
    } catch (error) {
      captureException(error);
      console.log("processVerification error", error);
      return;
    }
  };

  useEffect(() => {
    processVerification();
  }, []);

  return <LoadingPage />;
};
