import styled from "styled-components";
import { useEffect, useState } from "react";

import * as css from "styles/CssVariables.js";

import sadIcon from "assets/img/icons/icons8-sad.svg";
import ButtonPrimary from "components/buttons/ButtonPrimary";

const TryAgain = styled.div`
  width: 100%;
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${css.VERTICAL_BOX_MARGIN_LARGE};
`;

const TryAgainBox = styled.div`
  width: 100%;
  max-width: 370px;
  padding: 40px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 500;
  text-align: center;
`;

const SubTitle = styled.div`
  text-align: center;
`;

const IconTextWrap = styled.span`
  background-image: url(${sadIcon});
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 34px 34px;
  padding-right: 35px;
`;

const ButtonWrap = styled.div``;

export default ({ onTryAgainClick }) => {
  const [counter, setCounter] = useState(9);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        const newCounter = prevCounter - 1;
        if (newCounter === 0) clearInterval(interval);
        return newCounter;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  let label = "Try Again";

  if (counter > 0) label += ` (${counter}s)`;

  return (
    <TryAgain>
      <TryAgainBox>
        <Title>
          We didn't find any more posts for{" "}
          <IconTextWrap icon={sadIcon}>you</IconTextWrap>
        </Title>
        <SubTitle>Please try again or come back later.</SubTitle>
        <ButtonWrap>
          <ButtonPrimary onClick={onTryAgainClick} disabled={counter > 0}>
            {label}
          </ButtonPrimary>
        </ButtonWrap>
      </TryAgainBox>
    </TryAgain>
  );
};
