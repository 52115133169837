import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { captureException } from "@sentry/react";

import { AUTH_ID, AUTH_TOKEN, AUTH_WS_TOKEN, SHARD_NUMBER } from "constants";
import LoadingPage from "components/other/LoadingPage";

export default () => {
  const [searchParams] = useSearchParams();

  // const LayoutProperties = createSelector(
  //   (state) => state.Layout,
  //   (layout) => ({
  //     ipAddress: layout.ipAddress,
  //   })
  // );

  // const { ipAddress } = useSelector(LayoutProperties);

  const ipAddress = "";

  const x = searchParams.get("x");
  const y = searchParams.get("y");

  if (!x || !y) {
    window.location = "/?message=resetPasswordLoginFailed";
    return;
  }

  const RESET_PASSWORD_LOGIN = gql`
    mutation ResetPasswordLogin($id: ID!, $code: String!, $ipAddress: String) {
      resetPasswordLogin(id: $id, code: $code, ipAddress: $ipAddress) {
        resultCode
        id
        token
        wsToken
        shardNumber
      }
    }
  `;

  const [resetPasswordLogin] = useMutation(RESET_PASSWORD_LOGIN);

  const processLogin = async () => {
    try {
      const result = await resetPasswordLogin({
        variables: { id: x, code: y, ipAddress },
      });

      const { resultCode, id, token, wsToken, shardNumber } =
        result.data.resetPasswordLogin;

      if (resultCode === 4) {
        window.location = "/?message=resetPasswordExpiredLink";
        return;
      }

      if (resultCode !== 0 || !token) {
        window.location = "/?message=resetPasswordLoginFailed";
        return;
      }

      // login successfull, redirect to account's settings
      localStorage.setItem(AUTH_ID, id);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(AUTH_WS_TOKEN, wsToken);
      localStorage.setItem(SHARD_NUMBER, shardNumber);
      window.location = "/settings";
    } catch (error) {
      captureException(error);
      console.log("processLogin error", error);
      return;
    }
  };

  useEffect(() => {
    processLogin();
  }, []);

  return <LoadingPage />;
};
