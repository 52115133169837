import { gql, useQuery } from "@apollo/client";
import { captureException } from "@sentry/react";

import DashboardPosts from "./DashboardPosts";

export default () => {
  // Fetch data
  const GET_POSTS_DATA = gql`
    query GetPostsData {
      posts(limit: 2) {
        id
        created_at
        name
        status
        text
        image
        switched_on
        rejection_reason
        likes
        posts_stats {
          id
          to
          total_impressions
          total_interactions
        }
        impressions {
          id
          registered_at
          interacted
          cpi
        }
      }
    }
  `;

  const { loading, data, error } = useQuery(GET_POSTS_DATA, {
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log("fetching dashboard posts error", error);
    captureException(error);
  }

  //console.log("data", data);

  return <DashboardPosts data={data} loading={loading} />;
};
